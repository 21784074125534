import styles from './CompanyDetailsPage.module.css';
import CompanyDetails from '../components/CompanyDetails';
import { Grid } from '@mui/material';
import Logo from '../components/Logo';

export default function CompanyDetailsPage() {
  return (
    <div className={styles.CompanyDetailsDiv}>
      <Grid container padding={2} justifyContent='center' alignItems='center'>
        <Grid item mt={2} sm={12}>
          <Logo />
        </Grid>

        <br />

        <Grid item mt={2} sm={12}>
          <CompanyDetails />
        </Grid>
      </Grid>
    </div>
  );
}
