import { Stack } from '@mui/material';
import NoData from '../assets/noData.svg';

export default function NoDataMessage({children}) {
  return (

      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{ opacity: 0.5}}
      >
        <img src={NoData} alt="" style={{width:'50px' , height:'40px'}}/>
        <p style={{ fontFamily:'Rubik' ,fontStyle:'normal' , fontWeight:600, fontSize:'12px' , lineHeight:'14px' , color:'#333333' , textTransform:'none'}}>{children}</p>
      </Stack>

  );
}
