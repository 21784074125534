
import Cookies from 'js-cookie';

function getCurrentUser() {
  return Cookies.get('user_token');
}

function getCurrentUserEmail() {
  return Cookies.get('email');
}


const authServices = {
  getCurrentUser,
  getCurrentUserEmail
};

export default authServices;