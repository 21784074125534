import { useState } from 'react';
import { Button, Stack } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import api from '../api/api';
import * as Popover from '@radix-ui/react-popover';
import { styled, keyframes } from '@stitches/react';
import TableShowMore from '../assets/TableShowMore.svg';

//Component imports
import ProductCard from './ProductCard';

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          '&:hover': {
            opacity: 0.85,
          },
          justifyContent: 'left',
          fontFamily: 'Rubik',
          fontSize: '14px',
          lineHeight: '14px',
          fontWeight: 400,
          color: '#FFFFFF',
          textTransform: 'none',
        }),
      },
    },
  },
});

export default function CustomPopover(props) {
  const prodId = props.productInfo.pid;
  const [notifyStat, setNotifyStat] = useState(props.notification);
  const [showPricesModal, setShowPricesModal]= useState(false);
  const [open, setOpen] = useState(false);

  const closePopover = () => {
    setOpen(false);
  };


  const slideDown = keyframes({
    '0%': { opacity: 0, transform: 'translateY(-10px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
  });

  const slideUp = keyframes({
    '0%': { opacity: 0, transform: 'translateY(10px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
  });

  // const StyledAnchor = styled(Popover.Anchor, {
  //   background: "gainsboro",
  //   padding: 20,
  // });

  const StyledContent = styled(Popover.Content, {
    borderRadius: 10,
    padding: '20px',
    fontSize: 14,
    backgroundColor: 'black',
    color: 'white',

    animationDuration: '0.6s',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    '&[data-side="top"]': { animationName: slideUp },
    '&[data-side="bottom"]': { animationName: slideDown },
  });

  const handleViewAllPrices = () => {
    setShowPricesModal(true);
    closePopover();
  };

  const handleManageDiscount = () => {
    console.log('handle Manage Discount');
  };

  const handleNotification = async () => {
 
    // const { data } = await api.get(`/alert/${prodId}`, {
    //   params: { status: `${notifyStat === 'ON' ? 'OFF' : 'ON'}` },
    // });

    // if (data !== 'UpdateItem succeeded:') {
    //   setNotifyStat(prevStat);
    // }

    try{
      const {data} = await api.put('/users/updateNotifyEnabled',{
        productId:prodId,
        updateStatus:notifyStat === 'ON' ? 'OFF' : 'ON'
      });
      setNotifyStat(notifyStat === 'ON' ? 'OFF' : 'ON');
    }catch(err){
      // console.log(err);
    }
    
  };

  return (
    <div>
    {showPricesModal && <ProductCard handleClose={setShowPricesModal}  productInfo={props.productInfo}/>}
    <div style={{ width: '17px', height: '17px' }}>
      <Popover.Root open={open} onOpenChange={setOpen}>
        <Popover.Trigger>
          <img width='50' height='50' src={TableShowMore} alt='' />
        </Popover.Trigger>
        <Popover.Portal >
          <StyledContent>
            <Popover.Arrow />
            <Stack direction='column' spacing={1}>
              <ThemeProvider theme={theme}>
              
                <Button
                  sx={{ justifyContent: 'left' }}
                  onClick={handleViewAllPrices}
                >
                  View All Prices
                </Button>
                <Button
                  sx={{ justifyContent: 'left' }}
                  onClick={handleManageDiscount}
                >
                  Manage Discount
                </Button>
                <Button
                  sx={{ justifyContent: 'left' }}
                  onClick={handleNotification}
                >
                  Turn {notifyStat === 'ON' ? 'Off' : 'On'} Notifications
                </Button>
                
              </ThemeProvider>
            </Stack>
          </StyledContent>
        </Popover.Portal>
      </Popover.Root>
      {/* <Popover
        id={props.id}
        ref={props.ref}
        open={open}
        anchorEl={anchorEl}
        onClose={handleShowMoreClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <p>Hello world. This is just a test to see if this works {props.cardnumber}</p>
      </Popover> */}
    </div>
    </div>
  );
}
