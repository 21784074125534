import { Card, AreaChart } from '@tremor/react';
import { CircularProgress, Grid } from '@mui/material';
import styles from './PricePointCard.module.css';
import ViewAll from '../assets/ViewAll.svg';
import DropBox from './DropBox';
import TimeButtonGroup from './Buttons/TimeButtonGroup';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setIsloadingPricePoint, setSelectedCategoryPricePoint, setSelectedProductPricePoint, setSelectedTimePricePoint ,setPricePointChartData } from '../redux/slices/pricePointSlice';
import NoDataMessage from './NoDataMessage';
import { useEffect } from 'react';
import API from '../api/api';


const chartdata = [
  {
    placeholder: 'Jan',
    'Your Pricing': 4.5,
    'Market Average': 15.0,
  },
  {
    placeholder: 'Feb',
    'Your Pricing': 8.9,
    'Market Average': 14.65,
  },
  {
    placeholder: 'Mar',
    'Your Pricing': 28.1,
    'Market Average': 35.0,
  },
  {
    placeholder: 'Apr',
    'Your Pricing': 24.3,
    'Market Average': 30.4,
  },
  {
    placeholder: 'May',
    'Your Pricing': 50.7,
    'Market Average': 28.2,
  },
  {
    placeholder: 'Jun',
    'Your Pricing': 14.0,
    'Market Average': 19.3,
  },
  {
    placeholder: 'Jul',
    'Your Pricing': 22.1,
    'Market Average': 38.8,
  },
  {
    placeholder: 'Aug',
    'Your Pricing': 29.2,
    'Market Average': 29.2,
  },
  {
    placeholder: 'Sept',
    'Your Pricing': 12.4,
    'Market Average': 15.6,
  },
  {
    placeholder: 'Oct',
    'Your Pricing': 17.9,
    'Market Average': 22.1,
  },
  {
    placeholder: 'Nov',
    'Your Pricing': 28.3,
    'Market Average': 29.2,
  },
  {
    placeholder: 'Dec',
    'Your Pricing': 31.6,
    'Market Average': 30.2,
  },
];

const dataFormatter = (number) =>
  `${Intl.NumberFormat('us').format(number).toString()}`;

function PricePointCard() {
  
  const dispatch = useDispatch();
  const {  selectedProductPricePoint, selectedCategoryPricePoint, selectedTimePricePoint,pricePointChartData, isLoadingPricePoint } = useSelector((state) => state.pricePoint);
  const {  categoryList, productList } = useSelector((state) => state.home);
  const { isEdit } = useSelector((state) => state.app);


  function handleCategoryChange(event) {
    if (event.target.value !== selectedCategoryPricePoint && event.target.value !== '') {
      dispatch(setSelectedProductPricePoint({}));
    }
    dispatch(setSelectedCategoryPricePoint(event.target.value));

  }

  function handleProductChange(event) {
    dispatch(setSelectedProductPricePoint(productList.filter(product => product._id === event)[0]));
  }

  function timeframeChange(event) {
    if (event.target.value !== selectedTimePricePoint) {
      dispatch(setSelectedTimePricePoint(event.target.value));
    }
  }

  useEffect(() => {
    async function getPricePointDetails() {
      dispatch(setIsloadingPricePoint(true));
      try {
        if (selectedProductPricePoint._id) {
          const pricePoint = await API.get(`producthistory/pricePoint/v2/${selectedProductPricePoint._id}/${selectedTimePricePoint}`);
          dispatch(setPricePointChartData(pricePoint.data.data));
        }
      } catch (err) {
        //When the user don't have a competitor account, this error response occurrs.
        //This part should change later
        if (err?.response?.data?.message) {
          alert(err.response.data.message);
        } else {
          alert(err.message);
        }

      }

      dispatch(setIsloadingPricePoint(false));
    }

   if (!isEdit) getPricePointDetails();
  }, [selectedTimePricePoint, selectedProductPricePoint]);

  return (
    <Card>
      <Grid container direction='column'>
        <Grid item container mb='20px'>
          <Grid item xs={8} textAlign='left'>
            <div>
              <p className={styles.tableTopic}>Price Points</p>
            </div>
          </Grid>
          <Grid item xs={4} textAlign='right'>
            <Link to='/pricePoint'>
              <div style={{ float: 'right' }}>
                <motion.img src={ViewAll} alt='' whileHover={{ scale: 1.2 }} />
              </div>
            </Link>
          </Grid>
        </Grid>
        <Grid item container columnSpacing={2} rowSpacing={2}>
          <Grid
            item
            container
            xs={12}
            md={4}
            textAlign='left'
            columnSpacing={2}
          >
            <Grid item xs={6}>
              <DropBox
                title='Sort By Category: '
                categoryList={categoryList}
                onChange={handleCategoryChange}
                value={selectedCategoryPricePoint} />
            </Grid>
            <Grid item xs={6}>
              <DropBox
                title='Sort By Product: '
                categoryList={productList.filter(product => {
                  return product.category === selectedCategoryPricePoint || selectedCategoryPricePoint === '';
                }).map(product => product.productName + (product.productDetail ? ` | ${product.productDetail}` : ''))}
                getIndex={handleProductChange}
                indexList={productList.filter(product => {
                  return product.category === selectedCategoryPricePoint || selectedCategoryPricePoint === '';
                }).map(product => product._id)}
                value={selectedProductPricePoint?.productName
                  ? selectedProductPricePoint.productName +
                    (selectedProductPricePoint.productDetail
                      ? ` | ${selectedProductPricePoint.productDetail}`
                      : '')
                  : ''}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            sx={{ display: 'flex', alignItems: 'end', justifyContent: { xs: 'left', md: 'right' } }}

          >
            <TimeButtonGroup
              onClick={timeframeChange}
              selectedTFrame={selectedTimePricePoint}
            />

          </Grid>
        </Grid>
      </Grid>

      {isLoadingPricePoint ? (
        <Grid item container mt={2} sx={{ display: 'flex', backgroundColor: '#F9FBFA', position: 'relative', minHeight: '300px', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress
            size='6rem'
            style={{ color: 'grey', position: 'absolute' }}
          />
        </Grid>
      ) : (
        <div>
          {selectedProductPricePoint._id ? (
            <div style={{ backgroundColor: '#F9FBFA', marginTop: '20px' }}>
              <p className={styles.areaChartHeader}>
                {selectedProductPricePoint.productName}
              </p>
              <AreaChart
                data={pricePointChartData}
                categories={['Your Pricing', 'Market Average']}
                dataKey='placeholder'
                height='h-72'
                colors={['cyan', 'gray']}
                valueFormatter={dataFormatter}
                marginTop='mt-4'
                showTooltip
              />
            </div>
          ) : (
            <Grid item container mt={2} sx={{ display: 'flex', backgroundColor: '#F9FBFA', position: 'relative', minHeight: '250px', justifyContent: 'center', alignItems: 'center' }}>
              <NoDataMessage>No data to show</NoDataMessage>
            </Grid>
          )}
        </div>
      )}

    </Card>
  );
}


export default PricePointCard;