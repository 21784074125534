import { Select, MenuItem, Stack, FormControl, Typography, ThemeProvider, createTheme } from '@mui/material';
import styles from './DropBox.module.css';


const customTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      xxsFont: 396,
      xsFont: 412,
      smFont: 447,
      sm: 600,
      md: 900,
      mdFont: 965,
      lgFont: 1050,
      lg: 1530,
      xl: 1536,
    },
  },
  typography: {
    subtitle1: {
      fontFamily: 'Rubik',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '10px',
      lineHeight: '14px',
      textTransform: 'uppercase',
      color: '#333333',

    }
  }
});
function capitalizeWords(str) {
  // split the string into an array of words
  let words = str.split(' ');

  // iterate over each word and capitalize the first letter
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  // join the capitalized words back into a string
  return words.join(' ');
}


export default function DropBox(props) {
  const { categoryList } = props;
  return (
    <Stack>
      <FormControl variant='standard' sx={{ minWidth: 100 }}>
        <ThemeProvider theme={customTheme}>
          <Typography sx={{ fontSize: { xs: '11px', xxsFont: '12px', xsFont: '9px', smFont: '10px', md: '8px', mdFont: '9px', lgFont: '10px' } }} variant='subtitle1'>{props.title}</Typography>
        </ThemeProvider>

        <Select
          labelId='demo-select-small'
          id='demo-select-small'
          defaultValue=''
          value={props.value}
          label='Age'
          onChange={props.onChange}
        >
          {categoryList?.length > 0 ? (
            categoryList.map((cat, index) => (
              <MenuItem
                key={`${cat}+${index}`}
                value={cat === 'All' ? '' : cat}
                onClick={(e) => { props.indexList ? props.getIndex(props.indexList[index]) : null; }}
                sx={{ fontFamily: 'Rubik' }}
              >
                {capitalizeWords(cat)}
              </MenuItem>
            ))
          ) : (
            <MenuItem value='' sx={{ fontFamily: 'Rubik' }}>
              <em>None</em>
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </Stack>
  );
}
