import { useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SkeletonDisplay from '../components/SkeletonDisplay';
import styles from './PriceChangePage.module.css';
import CustomPopover from '../components/CustomPopover';
import NoDataMessage from '../components/NoDataMessage';
import api from '../api/api';

export default function PriceChangePage() {
  const [initialLoad, setInitialLoad] = useState(false);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setInitialLoad(true);
    async function getRecentChangeTableData() {
      const { data } = await api.get('/recent_price_changes', {
        params: { timeframe: 'month' },
      });

      const neededData = data.filter((prod) => {
        if (prod.change_type === 'UP' || prod.change_type === 'DOWN') {
          return true;
        }
      });

      setTableData(neededData);
     
    }

    const timeoutId = setTimeout(() => {
      getRecentChangeTableData();
      setInitialLoad(false);
    }, 4000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      <div className={styles.mainDiv}>
        {initialLoad ? (
          <>
            <SkeletonDisplay />
          </>
        ) : (
          <>
            <Grid
              container
              direction='column'
              sx={{ height: '100%', py: '24px' }}
            >
              <Grid item xs={6} textAlign='left'>
                <div>
                  <p className={styles.tableTopic}>Recent Price Changes</p>
                </div>
              </Grid>
              <Grid item container>
                <TableContainer
                  component={Paper}
                  sx={{ mt: { xs: '10px', md: '30px' } }}
                >
                  <Table
                    sx={{
                      minWidth: 650,
                      // height: '650px',
                    }}
                    aria-label='simple table'
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align='center'>
                          <p className={styles.tableheader}>Store</p>
                        </TableCell>
                        <TableCell>
                          <p className={styles.tableheader}>Product</p>
                        </TableCell>
                        <TableCell align='center'>
                          <p className={styles.tableheader}>Store Discount</p>
                        </TableCell>
                        <TableCell align='center'>
                          <p className={styles.tableheader}>New Price</p>
                        </TableCell>
                        <TableCell align='center'>
                          <p className={styles.tableheader}>Market Average</p>
                        </TableCell>
                        <TableCell align='center'>
                          <p className={styles.tableheader}>Your Discount</p>
                        </TableCell>
                        <TableCell align='center'>
                          <p className={styles.tableheader}>Your Price</p>
                        </TableCell>
                        <TableCell align='center'>
                          <p className={styles.tableheader}>Difference</p>
                        </TableCell>
                        <TableCell align='center' />
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ backgroundColor: '#F9FBFA' }}>
                      {tableData?.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={9} sx={{ textAlign: 'center' }}>
                            <NoDataMessage>No data to show</NoDataMessage>
                          </TableCell>
                        </TableRow>
                      )}

                      {tableData.map((row, index) => (
                        <TableRow
                          key={`${row.product_id}+${row.name}+${row.change_type}+${index}`}
                          sx={{
                            height: '85px',
                          }}
                        >
                          <TableCell align='center'>
                            <p className={`${styles.prodTableContent}`}>
                              {row.change_details.store}
                            </p>
                          </TableCell>
                          <TableCell align='left'>
                            <Stack direction='row' spacing={1}>
                              <img
                                src={row.image}
                                alt=''
                                style={{ width: '50px', height: '50px' }}
                              />

                              <Stack direction='column'>
                                <p className={styles.itemName}>{row.name}</p>
                                <span className={styles.itemSubName}>145g</span>
                              </Stack>
                            </Stack>
                          </TableCell>
                          <TableCell align='center'>
                            {row.storeDiscount === 0 ? (
                              <p
                                className={`${styles.prodTableContent} ${styles.prodTableContentOpacity}`}
                              >
                                _
                              </p>
                            ) : (
                              <p
                                className={`${styles.prodTableContent} ${styles.prodTableContentOpacity}`}
                              >
                                {row.storeDiscount === null ||
                                  !row.hasOwnProperty('storeDiscount')
                                  ? '_'
                                  : `${row.storeDiscount}%`}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align='center'>
                            <Stack
                              spacing={1}
                              direction='row'
                              alignItems='center'
                              justifyContent='space-around'
                            >
                              <p
                                className={`${styles.prodTableContent} ${styles.prodTableContentOpacity}`}
                              >
                                {row.change_details.price}
                              </p>

                              <>
                                {row.change_details.price >
                                  row.change_details.previous_price ? (
                                  <p
                                    className={`${styles.pDifference} ${styles.pDifferenceRed}`}
                                  >
                                    {Math.abs(
                                      parseFloat(
                                        row.change_details.price.replace(
                                          '$',
                                          ''
                                        )
                                      ) -
                                      parseFloat(
                                        row.change_details.previous_price.replace(
                                          '$',
                                          ''
                                        )
                                      )
                                    ).toFixed(2)}
                                    &#8593;
                                  </p>
                                ) : (
                                  <p
                                    className={`${styles.pDifference} ${styles.pDifferenceGreen}`}
                                  >
                                    {Math.abs(
                                      parseFloat(
                                        row.change_details.previous_price.replace(
                                          '$',
                                          ''
                                        )
                                      ) -
                                      parseFloat(
                                        row.change_details.price.replace(
                                          '$',
                                          ''
                                        )
                                      )
                                    ).toFixed(2)}
                                    &#8595;
                                  </p>
                                )}
                              </>
                            </Stack>
                          </TableCell>
                          <TableCell align='center'>
                            <p
                              className={`${styles.prodTableContent} ${styles.prodTableContentOpacity}`}
                            >
                              $
                              {(
                                (parseFloat(row.lowest.price.replace('$', '')) +
                                  parseFloat(
                                    row.highest.price.replace('$', '')
                                  )) /
                                2
                              ).toFixed(2)}
                            </p>
                          </TableCell>
                          <TableCell align='center'>
                            {row.yourDiscount === 0 ? (
                              <p
                                className={`${styles.prodTableContent} ${styles.prodTableContentOpacity}`}
                              >
                                _
                              </p>
                            ) : (
                              <p
                                className={`${styles.prodTableContent} ${styles.prodTableContentOpacity}`}
                              >
                                {row.yourDiscount === null ||
                                  !row.hasOwnProperty('yourDiscount')
                                  ? '_'
                                  : `${row.yourDiscount}%`}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align='center'>
                            <p
                              className={`${styles.prodTableContent} ${styles.prodTableContentOpacity}`}
                            >
                              {row.your_current_price}
                            </p>
                          </TableCell>
                          <TableCell align='center'>
                            <p
                              className={`${styles.prodTableContent} ${styles.prodTableContentOpacity}`}
                            >
                              ±$
                              {Math.abs(
                                parseFloat(
                                  row.your_current_price.replace('$', '')
                                ) -
                                parseFloat(
                                  row.change_details.price.replace('$', '')
                                )
                              ).toFixed(2)}
                            </p>
                          </TableCell>
                          <TableCell align='center'>
                            <Stack>
                              <CustomPopover
                                id={index}
                                productId={row.product_id}
                                notification={row.notification_status}
                              />
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </>
  );
}
