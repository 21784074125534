import { createSlice } from '@reduxjs/toolkit';
import { revertAll } from '../../util/reverAll';

const initialState = {
  notifyChange: true,
};

const itemCard = createSlice({
  name: 'itemCard',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setNotifyChange: (state, action) => {
      state.notifyChange = action.payload;
    },
  },
});

export default itemCard.reducer;
export const { setNotifyChange } = itemCard.actions;
