import { useEffect, useState } from 'react';
import { Button, Stack } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import api from '../api/api';
import * as Popover from '@radix-ui/react-popover';
import { styled, keyframes } from '@stitches/react';
import NotifyDropDown from '../assets/notifyDropDown.svg';



const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          '&:hover': {
            opacity: 0.85,
          },
          justifyContent: 'left',
          fontFamily: 'Rubik',
          fontSize: '14px',
          lineHeight: '14px',
          fontWeight: 400,
          color: 'black',
          textTransform: 'none',
        }),
      },
    },
  },
});

export default function NotificationPopover(props) {
  const {uuid, index, notifications, setNotifications, setPopupOpenId , read} = props;
  
  const [open, setOpen] = useState(false);

  useEffect(()=>{
    if(open){
      setPopupOpenId(`${uuid}-${index}`);
    }else{
      setPopupOpenId(null);
    }
  },[open]);

  const slideDown = keyframes({
    '0%': { opacity: 0, transform: 'translateY(-10px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
  });

  const slideUp = keyframes({
    '0%': { opacity: 0, transform: 'translateY(10px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
  });

  // const StyledAnchor = styled(Popover.Anchor, {
  //   background: "gainsboro",
  //   padding: 20,
  // });

  const StyledContent = styled(Popover.Content, {
    borderRadius: 12,
    padding: '15px',
    fontSize: 14,
    backgroundColor: 'white',
    color: 'white',
    zIndex:1300,
    animationDuration: '0.6s',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    // '&[data-side="top"]': { animationName: slideUp },
    // '&[data-side="bottom"]': { animationName: slideDown },
  });


  const handleDelete = async() => {
    try{
      await api.patch('/users/deleteNotification',{uuid:uuid});
      const updatedList= notifications.filter((item)=>item.uuid !== uuid);
      setNotifications(updatedList);

    }catch(err){
      console.log(err);
    }
  };

  const handleMarkRead = async() => {
    try{
      await api.patch('/users/markAsRead',{uuid:uuid});
      const updatedList= notifications.map((item)=>item.uuid===uuid ? {...item, read:true}: item);
      setNotifications(updatedList);

    }catch(err){
      console.log(err);
    }
  };


  return (

    <div style={{ width: '17px', height: '17px' }}>
      <Popover.Root open={open} onOpenChange={setOpen}>
        <Popover.Trigger>
          <img width='50' height='50' src={NotifyDropDown} alt='' />
        </Popover.Trigger>
        <Popover.Portal style={{marginRight:'10px'}}>
          <StyledContent>
            <Popover.Arrow style={{fill:'white'}}/>
            <Stack direction='column' spacing={1}>
              <ThemeProvider theme={theme}>
              
                {!read &&
                (<Button
                  sx={{ justifyContent: 'left' }}
                  onClick={handleMarkRead}
                >
                  Mark as Read
                </Button>)}
                <Button
                  sx={{ justifyContent: 'left' }}
                  onClick={handleDelete}
                >
                  Delete
                </Button>
          
                
              </ThemeProvider>
            </Stack>
          </StyledContent>
        </Popover.Portal>
      </Popover.Root>

    </div>
  );
}
