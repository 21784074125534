import { Grid, Skeleton, Stack } from '@mui/material';

export default function SkeletonDisplay() {
  return (
    <>
      <Stack spacing={4} sx={{ py: '24px' }}>
        <Skeleton variant="rounded" width="25%" height={30} />

        <Skeleton variant="rounded" height={550} />
      </Stack>
    </>
  );
}
