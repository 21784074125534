import styles from './Logo.module.css';
import logo from '../assets/Logo.svg';
import { Grid } from '@mui/material';

export default function Logo(){

    return(
        <Grid container justifyContent='center' >
            <Grid item>
                <img src={logo} alt="logo" />
            </Grid>
        </Grid>
    );
}