import { useDraggable } from '@dnd-kit/core';
import { Avatar, Card, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import moveImg from '../assets/MoveIcon.svg';
import binImg from '../assets/BinIcon.svg';

export default function Draggable(props) {
  const { isEdit } = useSelector((state) => state.app);
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.id,
    data: {
      type: props.type,
    },
  });
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        zIndex: 1,
        cursor: 'grabbing',
        opacity: 0.5,
      }
    : { cursor: isEdit ? '' : 'default' };

  const moveIconstyle = transform
    ? {
        cursor: 'grabbing',
      }
    : { cursor: 'grab' };

  const deleteIconstyle = transform
    ? {
        cursor: 'grabbing',
      }
    : { cursor: 'pointer' };

  return (
    <Grid
      container
      ref={setNodeRef}
      style={style}
      sx={{ position: 'relative' }}
    >
      {isEdit ? (
        <Card
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 1,
          }}
        >
          <Grid item xs={12} justifyContent='right' container>
            <Avatar
              {...listeners}
              {...attributes}
              style={moveIconstyle}
              sx={{
                backgroundColor: '#399700',
                marginTop: '20px',
                marginRight: '10px',
              }}
              src={moveImg}
            ></Avatar>
            <Avatar
              onClick={() => props.handleClose(props.id)}
              style={deleteIconstyle}
              sx={{
                backgroundColor: '#399700',
                marginTop: '20px',
                marginRight: '20px',
              }}
              src={binImg}
            ></Avatar>
          </Grid>
        </Card>
      ) : null}

      <Grid item container>
        {props.children}
      </Grid>
    </Grid>
  );
}
