import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { Switch } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useState } from 'react';
import styles from '../pages/LoginPage.module.css';
import Logo from '../assets/Logo.svg';
import ChangePassword from './ChangePassword';
import { useDispatch } from 'react-redux';
import { setAuthUser } from '../redux/slices/authSlice';
import axios from 'axios';
import Cookies from 'js-cookie';
const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$*.\[\]{}\(\)?\-"!@#%&\/\\,><':;|_~+=^`]).{8,}$/,
      'Must Contain 8 Characters and Least One Uppercase, One Lowercase, One Number and One Special Case Character'
    )
    .required('Password is required'),
});

export default function Login() {
  const navigate = useNavigate();

  const [invalidUser, setInvalidUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [requireNewPwd, setRequireNewPwd] = useState(false);
  const [isDemo, setIsDemo]= useState(false);

  const dispatch = useDispatch();

  const theme = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          root: {
            '& .MuiSwitch-thumb': {
              // Controls default (unchecked) color for the thumb
              color: '#399700'
            },
            '& .Mui-checked .MuiSwitch-thumb': {
              // Controls checked color for the thumb
              color: '#399700'
            },
            '& .MuiSwitch-track': {
              // Controls default (unchecked) color for the track
              opacity: 0.2,
              backgroundColor: '#a3cc00 !important'
            },
            '& .Mui-checked .MuiSwitch-track': {
              // Controls checked color for the track
              opacity: 0.7,
              backgroundColor: '#a3cc00'
            },
          }
        }
      }
    }
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: false,
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const user = await Auth.signIn({
          username: values.email,
          password: values.password,
        });
        dispatch(setAuthUser(user));
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setRequireNewPwd(true);
        } else {
          if (user.signInUserSession.accessToken.jwtToken !== null) {
            Cookies.set(
              'user_token',
              user.signInUserSession.accessToken.jwtToken
            );
            Cookies.set('email', user.attributes.email);
            Cookies.set('isDemo',isDemo);

            try {
              const res = await axios.get(
                `${process.env.REACT_APP_DEV_API_URL}/users/getUser`,
                {
                  headers: {
                    'x-auth-token': user.signInUserSession.accessToken.jwtToken,
                  },
                }
              );

              //todo: store isOnboarding in cockies instead of the session storage
              res.status === 204
                ? Cookies.set('isOnboarding', false)
                : Cookies.set('isOnboarding', res.data.isOnboard);

              if (res.status === 200 && res.data.isOnboard) {
                navigate('/home');
              } else if (res.status === 204 || !res.data.isOnboard) {
                navigate('/companyDetails');
              }
            } catch (e) {
              alert(e.message);
            }
          } else {
            navigate('/');
          }
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setInvalidUser(true);
      }
    },
  });

  const rememberMe = <p className={styles.rememberMe}>Remember Me</p>;

  const inValidUserComp = (
    <p className={styles.invalidUser}>
      Invalid Email or Password. Please Try Again
    </p>
  );

  const changePwdStatus = (e) => {
    setRequireNewPwd(e);
  };

  const handleSetDemo=(e) =>{
    setIsDemo( e.target.checked);
  };

  return (
    <Box
      minWidth="300px"
      maxWidth="400px"
      sx={{ background: 'transparent', borderRadius: '5px' }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
      >
        <Box style={{ textAlign: 'center' }}>
          <img src={Logo} alt="logo" />
        </Box>

        {requireNewPwd ? (
          <ChangePassword
            changePwdStatus={(e) => {
              changePwdStatus(e);
            }}
          />
        ) : (
          <Box
            className={styles.whiteBox}
            component="form"
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <Grid container justifyContent="center">
              {isLoading && (
                <Box className={styles.fadedBox}>
                  <CircularProgress
                    size={80}
                    color="inherit"
                    sx={{ top: '50%', color: '#81BB00' }}
                  />
                </Box>
              )}
              <Grid item>
                <p className={styles.welcome}>Welcome Back</p>
                <p className={styles.enterCred}>
                  Enter your credentials to sign in.
                </p>
              </Grid>

              <Grid item sx={{ mt: '15px'}}>
              <ThemeProvider theme={theme}>
                <FormControlLabel
                  control={
                    
                    <Switch
                      size="small"
                      className='demo-switch'
                      onChange={handleSetDemo}
                      checked={isDemo}
                      
                    />
                    
                  }
                  label={<p className={styles.demo}>Demo</p>}
                  labelPlacement="start"
                  sx={{ml:'0px',float:'right',mr:'-5px'}}
                />
              </ThemeProvider>
                <TextField
                  variant="standard"
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  sx={{
                    input: { color: 'black' },
                    '& .MuiInputLabel-root': { color: 'black' },
                    '& .MuiOutlinedInput-root': {
                      '& > fieldset': { borderColor: 'black' },
                    },

                    fontFamily: 'Rubik',
                  }}
                />
                <TextField
                  variant="standard"
                  fullWidth
                  id="password"
                  label="Password"
                  type="password"
                  name="password"
                  disabled={requireNewPwd}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  sx={{
                    input: { color: 'black' },
                    '& .MuiInputLabel-root': { color: 'black' },
                    '& .MuiOutlinedInput-root': {
                      '& > fieldset': { borderColor: 'black' },
                    },

                    fontFamily: 'Rubik',
                  }}
                />

                <Grid
                  container
                  alignItems="baseline"
                  justifyContent="space-between"
                  sx={{ mt: '20px' }}
                >
                  <Grid item>
                    <FormControlLabel
                      id="remember"
                      name="remember"
                      onChange={formik.handleChange}
                      control={
                        <Checkbox
                          value={formik.values.remember}
                          sx={{
                            color: '#399700',
                            '&.Mui-checked': {
                              color: '#399700',
                            },
                          }}
                        />
                      }
                      label={rememberMe}
                    />
                  </Grid>
                  <Grid item xs textAlign="right">
                    <Link
                      href="/forgotPassword"
                      variant="body2"
                      className={styles.forgotPass}
                      sx={{ color: 'black' }}
                    >
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>

                {invalidUser && <>{inValidUserComp}</>}

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    backgroundColor: '#399700',
                    borderRadius: '5px',
                    fontFamily: 'Rubik',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '16px',
                    lineHeight: '14px',
                    paddingTop: '16px',
                    paddingBottom: '16px',
                    textTransform: 'capitalize',
                    '&:hover': {
                      backgroundColor: '#399700',
                      opacity: 0.85,
                    },
                  }}
                >
                  Sign In
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Grid>
    </Box>
  );
}
