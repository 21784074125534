import './App.css';
import Header from './components/Header';
import '@tremor/react/dist/esm/tremor.css';
import HomePage from './pages/HomePage';
import { Routes, Route } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import awsExports from './aws-configure';
import { Amplify } from 'aws-amplify';
import ProtectedRoute from './components/ProtectedRoute';
import MarketRankingPage from './pages/MarketRankingPage';

import PriceDropPage from './pages/PriceDropPage';
import PricePointPage from './pages/PricePointPage';
import PriceChangePage from './pages/PriceChangePage';
import IndustrySelection from './pages/IndustrySelection';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import CompetitorSelection from './pages/CompetitorSelection';
import AddCompetitor from './pages/AddCompetitor';
import CompanyDetailsPage from './pages/CompanyDetailsPage';
import AdminPage from './pages/AdminPage';

Amplify.configure(awsExports);

// console.log(process.env);
// console.log('test notify');

function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <LoginPage />
            </ProtectedRoute>
          }
        />


        <Route
          path="/forgotPassword"
          element={
            <ProtectedRoute>
              <ForgotPasswordPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/industry-selection"
          element={
            <ProtectedRoute>
              <IndustrySelection />
            </ProtectedRoute>
          }
        />
        <Route
          path="/competitor-selection"
          element={
            <ProtectedRoute>
              <CompetitorSelection />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-new-competitor"
          element={
            <ProtectedRoute>
              <AddCompetitor />
            </ProtectedRoute>
          }
        />

        <Route
          path="/companyDetails"
          element={
            <ProtectedRoute>
              <CompanyDetailsPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <Header />
              <HomePage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin"
          element={
            <ProtectedRoute>
              <Header />
              <AdminPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/marketRanking"
          element={
            <ProtectedRoute>
              <Header />
              <MarketRankingPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/priceDrop"
          element={
            <ProtectedRoute>
              <Header />
              <PriceDropPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/pricePoint"
          element={
            <ProtectedRoute>
              <Header />
              <PricePointPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/priceChange"
          element={
            <ProtectedRoute>
              <Header />
              <PriceChangePage />
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
