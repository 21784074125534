import { createSlice, current } from '@reduxjs/toolkit';
import { revertAll } from '../../util/reverAll';

const initialState = {
    selectedCategoryPriceDrop: 'All',
    selectedTimePriceDrop: 'today',
    priceDropTableData: [],
    isLoadingPriceDrop: true,
};

const priceDropSlice = createSlice({
  name: 'priceDrop',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    
    setPriceDropTableData: (state, action) => {
        state.priceDropTableData = action.payload;
      },
      setIsloadingPriceDrop: (state, action) => {
        state.isLoadingPriceDrop = action.payload;
      },
      setSelectedCategoryPriceDrop: (state, action) => {
        state.selectedCategoryPriceDrop = action.payload;
      },
  
      setSelectedTimePriceDrop: (state, action) => {
        state.selectedTimePriceDrop = action.payload;
      },
  },
});

export default priceDropSlice.reducer;
export const {
    setPriceDropTableData,
    setIsloadingPriceDrop,
    setSelectedCategoryPriceDrop,
    setSelectedTimePriceDrop

} = priceDropSlice.actions;
