import { Chip, styled, TextField } from '@mui/material';

export const CustomTextFieldOne = styled(TextField)({
  fontFamily: 'Rubik',
  fontSize: '16px',
  fontWeight: 400,
  borderRadius: 0,
  '& label.Mui-focused': {
    color: 'black',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'black',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'black',
    },
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
    },
  },
});

export const CustomChip = styled(Chip)({
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: 400,
  padding: '14px 5px 12px 5px',
  background: 'black',
  color: 'white',
  '& .MuiChip-root': {
    borderRadius: 0,
  },
  '& .MuiChip-deleteIcon': {
    color: 'black',
  },
});
