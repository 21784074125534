import Login from '../components/Login';
import styles from './LoginPage.module.css';

export default function LoginPage() {
  return (
    <div className={styles.loginBody}>

      <div className={styles.loginCard}>
        <Login />

      </div>
    </div>
  );
}
