import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { revertAll } from '../../util/reverAll';
import Cookies from 'js-cookie';
//slices for authentication proces

const initialState = {
  authUser: null,
  loginUser: 'UNAUTHENTICATED'
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setAuthUser: (state, action) => {
      Cookies.set('user_data', action.payload);
      state.authUser = action.payload;
    },
    setLoginStatus: (state, action) => {
      state.loginUser = action.payload;
    }
  },
});

export default authSlice.reducer;
export const { setAuthUser, setLoginStatus } = authSlice.actions;
