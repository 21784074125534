import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

import CloseIcon from '@mui/icons-material/Close';
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
import { IconButton, Stack, Grid, Badge } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { setNewNotifyCount } from '../redux/slices/homeSlice';
import NoNotification from '../assets/no_notification.png';
import NotificationError from '../assets/notification_error.png';
import styles from './SideBar.module.css';


import SideBarContent from './SideBarContent';
import ViewAllButton from './Buttons/ViewAllButton';
import API from '../api/api';
import { useSelector, useDispatch } from 'react-redux';

export default function SideBar() {
  const { newNotifyCount } = useSelector((state) => state.home);
  const dispatch = useDispatch();
  const [openRightSideBar, setOpenRightSideBar] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [notifyError, setNotifyError] = useState(false);
  const [notificationType, setNotificationType]= useState('all');

  const getNotifications = async (event) => {
    setNotifyError(false);
    setIsLoading(true);
    try {
      const response = await API.get('/users/getNotification',{
        params:{
          notificationType : notificationType
        }
      });
      setIsLoading(false);
      dispatch(setNewNotifyCount(0));
      return response.data[0]?.notifyList || [];
    } catch (error) {
      setNotifyError(true);
      setIsLoading(false);
      console.log('Notify Retreival error:- ', error.message);

    }

  };


  const toggleDrawer = (open) => async (event) => {

    if (
      event.type === 'keydown'
      && (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpenRightSideBar(open);
    if (open) {
      const notifications = await getNotifications();
      setNotificationList(notifications);
    }
  };

  useEffect(
    ()=>{
      (async ()=>{
        const notifications = await getNotifications();
        setNotificationList(notifications);
      })();
      
    },[notificationType]

  );
  

  return (
    <div>
      <React.Fragment key="right">

        <IconButton
          onClick={toggleDrawer(true)}
          size="large"
          aria-label="Justify"
        >
          {newNotifyCount > 0 ? (
            <>
              <Badge badgeContent={newNotifyCount}
                sx={{
                  '& .MuiBadge-badge': {
                    color: 'white',
                    backgroundColor: 'black'
                  }
                }}>
                <NotificationsNoneRoundedIcon sx={{ color: 'white' }} />
              </Badge>
            </>
          ) : (
            <>
              <NotificationsNoneRoundedIcon sx={{ color: 'white' }} />
            </>
          )}

        </IconButton>


        <Drawer
          role="presentation"
          anchor="right"
          open={openRightSideBar}
          onClose={toggleDrawer(false)}
          PaperProps={{
            sx: {
              maxWidth: { xs: '100%', sm: '70%', md: '475px' },
              minWidth: { xs: '100%', sm: '70%', md: '400px' },
            },
          }}
        >
          <Box
            role="presentation"
            onKeyDown={toggleDrawer(false)}
            className={styles.SidebarBox}
          >
            <Grid container>
              <Grid item xs={12} sm={12} align="right">
                <IconButton
                  onClick={toggleDrawer(false)}
                  style={{
                    color: 'black',
                    marginTop: '15px',
                    marginRight: '15px',
                  }}
                  size="small"
                >
                  <CloseIcon sx={{ fontSize: '20px' }} />
                </IconButton>
              </Grid>
            </Grid>
            {/* This is default notification display */}
            {/* <Stack sx={{ paddingLeft: '40px', paddingRight: '40px' }}>
              <p className={styles.notification}>Notifications</p>
              <Stack direction="row" spacing={3} sx={{ marginTop: '10px', marginBottom: '10px' }}>
                <button className={styles.notifySelect}>All</button>
                <button className={styles.notifyNotSelect}>Unread</button>
              </Stack>
              <SideBarContent />
              <div style={{ textAlign: 'center' }}>
                <ViewAllButton textV="View All Notifications" />
              </div>
            </Stack> */}
            <Stack sx={{ paddingLeft: '30px', paddingRight: '30px' }}>
              <p className={styles.notification}>Notifications</p>

              <Stack direction="row" spacing={3} sx={{ marginTop: '10px', marginBottom: '10px', paddingLeft: '10px' }}>
                <button className={notificationType === 'all' ? styles.notifySelect : styles.notifyNotSelect} onClick={()=>setNotificationType('all')}>All</button>
                <button className={notificationType === 'unread' ? styles.notifySelect : styles.notifyNotSelect} onClick={()=>setNotificationType('unread')} >Unread</button>
              </Stack>

              {(isLoading) ?
                <Box className={styles.fadedBox}>

                  <CircularProgress
                    size={50}
                    color='inherit'
                    sx={{ top: '50%', color: '#81BB00' }}
                  />

                </Box>
                : (<>
                  {notifyError ? (
                    <>
                     <div style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}>

                          <img src={NotificationError} alt="" />

                        </div>
                      <div style={{
                        color:'red',
                        justifyContent: 'center',
                        textAlign: 'center'
                      }}>
                        <p> Issue in retriving notification at the moment.</p>
                        <p> Try again later.</p>

                      </div>

                    </>
                  ) : (
                    <>
                      {(!notificationList || notificationList.length === 0) ? (<>
                        <div style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}>

                          <img src={NoNotification} alt="" />

                        </div>
                      </>) : (<>
                        <SideBarContent notifications={notificationList} setNotifications={setNotificationList} notifyError={notifyError} />
                        <div style={{ textAlign: 'center' }}>
                          <ViewAllButton textV="View All Notifications" />
                        </div>
                      </>)}
                    </>

                  )}


                </>)}


            </Stack>
          </Box>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
