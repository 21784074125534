import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  DialogActions,
} from '@mui/material';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import styles from './FileUploader.module.css';
import Other from '../assets/Other.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  setFileError,
  setFileTypeError,
  setFile,
  setIsUpload,
  setOpenDialog,
  setTempFile,
} from '../redux/slices/companyDetailsSlice';

export default function FileUploader(props) {
  const { file, fileTypeError, fileError, isUpload, openDialog, tempFile } =
    useSelector((state) => state.companyDetails);
  const dispatch = useDispatch();

  const onDrop = useCallback(
    (acceptedFiles) => {
      dispatch(setFileError(false));
      dispatch(setFileTypeError(false));
      if (acceptedFiles.length >= 1 && !isUpload) {
        dispatch(setFile(acceptedFiles[0]));
        dispatch(setIsUpload(true));
      } else if (acceptedFiles.length >= 1 && isUpload) {
        dispatch(setTempFile(acceptedFiles[0]));
        dispatch(setOpenDialog(true));
      } else {
        dispatch(setFileTypeError(true));
      }
    },
    [isUpload, file]
  );

  const handleSubmit = () => {
    dispatch(setFile(tempFile));
    dispatch(setOpenDialog(false));
  };

  const settings = {
    onDrop,
    accept: { 'text/csv': [], 'application/json': [] },
    maxFiles: props.maxFiles,
    maxFileSize: props.maxFileSize,
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone(settings);

  return (
    <Grid container>
      <Grid item xs={12} {...getRootProps()}>
        <input {...getInputProps()} />

        <Box
          width='100%'
          backgroundColor='#f9f9f9'
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='100px'
          justifySelf='center'
          component='span'
          sx={{
            borderRadius: '5px',
            p: 2,
            border:
              fileTypeError || fileError
                ? '2px dashed #d32f2f'
                : '2px dashed #399700',
            justifyContent: 'center',
          }}
        >
          {isDragActive ? (
            <Grid container justifyContent='center'>
              <img src={Other} />
              <Typography width='100%' align='center'>
                {' '}
                Drop the file here...
              </Typography>
              <Typography width='100%' align='center'>
                {' '}
                {file.name}
              </Typography>
            </Grid>
          ) : (
            <Grid container justifyContent='center'>
              <img src={Other} />

              <Grid item xs={12} hidden={isUpload}>
                <Typography width='100%' align='center'>
                  {' '}
                  Upload your product details
                </Typography>
                <Typography
                  width='100%'
                  align='center'
                  className={styles.bluredText}
                >
                  {' '}
                  or drag and drop here
                </Typography>
              </Grid>

              <Grid item xs={12} hidden={!isUpload}>
                <Typography width='100%' align='center'>
                  {' '}
                  {file.name}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Box>
      </Grid>

      <Grid item hidden={!fileTypeError} mt={1}>
        <Typography color='#d32f2f' className={styles.errorText}>
          Please upload a csv or json
        </Typography>
      </Grid>
      <Grid item hidden={!fileError} mt={1}>
        <Typography color='#d32f2f' className={styles.errorText}>
          file is required
        </Typography>
      </Grid>

      <Dialog
        open={openDialog}
        onClose={() => dispatch(setOpenDialog(false))}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Replace File'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Please note that this will replace proviously uploaded file.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => dispatch(setOpenDialog(false))}
            variant='contained'
            sx={{
              mt: 3,
              backgroundColor: '#399700',
              borderRadius: '5px',
              fontFamily: 'Rubik',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '16px',
              lineHeight: '14px',
              paddingTop: '16px',
              paddingBottom: '16px',
              textTransform: 'capitalize',
              '&:hover': {
                backgroundColor: '#399700',
                opacity: 0.85,
              },
            }}
          >
            close
          </Button>

          <Button
            onClick={handleSubmit}
            autoFocus
            variant='contained'
            sx={{
              mt: 3,
              backgroundColor: '#399700',
              borderRadius: '5px',
              fontFamily: 'Rubik',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '16px',
              lineHeight: '14px',
              paddingTop: '16px',
              paddingBottom: '16px',
              textTransform: 'capitalize',
              '&:hover': {
                backgroundColor: '#399700',
                opacity: 0.85,
              },
            }}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
