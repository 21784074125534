import styles from './ForgotPasswordPage.module.css';
import ForgotPasswordCard from '../components/ForgotPasswordCard';
import { Grid } from '@mui/material';
import Logo from '../components/Logo';
import { useNavigate } from 'react-router-dom';

export default function ForgotPasswordPage() {
  const navigate = useNavigate();

  function handleOnClick() {
    navigate('/');
  }

  return (
    <div className={styles.CompanyDetailsDiv}>
      <Grid container padding={2} justifyContent='center' alignItems='center'>
        <Grid onClick={handleOnClick} item mt={2} mb={2} xs={12}>
          <Logo />
        </Grid>
        <ForgotPasswordCard />
      </Grid>
    </div>
  );
}
