import axios from 'axios';
import authServices from '../services/authServices';
import { Auth } from 'aws-amplify';
export const api = axios.create({
  baseURL: 'https://74r07v2w3j.execute-api.us-east-1.amazonaws.com'
});



async function getAccessToken() {
  const session = await Auth.currentSession();
  return session.getAccessToken().getJwtToken();
}


const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_DEV_API_URL,
});

axiosInstance.interceptors.request.use(async (config) => {
  const accessToken = await getAccessToken();
  config.headers['x-auth-token'] = `${accessToken}`;
  return config;
});

export default axiosInstance;
