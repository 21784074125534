import React, { useEffect, useState } from 'react';
import styles from './CompetitorSelection.module.css';

import { Alert, Box, Button, CircularProgress, Grid } from '@mui/material';
import { Field, Formik, Form } from 'formik';
import Logo from '../assets/Logo.svg';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { industrySelected, setNewCompetitors, setSavedCompetitors } from '../redux/slices/competitorSelectionSlice';
import API from '../api/api';
export default function IndustrySelection() {
  const [_hasError, setNotSelectedError] = useState(false);
  const dispatch = useDispatch();
  const { selectedIndustry } = useSelector(
    (state) => state.competitorSelection
  );
  const navigate = useNavigate();
  const [industriesA, setIndustriesA] = useState([]);

  const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || '';

  const changeCase = s => {
    const text = s.split(' ');
    let newText = '';
    for (let i = 0; i < text.length; i++) {
      if (i === text.length - 1) {
        newText += capitalize(text[i]);
      } else {
        newText += capitalize(text[i]) + ' ';
      }
    }
    return newText;
  };

  useEffect(() => {
    async function getIndustries() {
      const res = await API.get('competitors/industry');
      const dataSet = res.data.data.sort();
      setIndustriesA(dataSet.map((industry) => { return { id: industry, name: industry }; }));
    }

    getIndustries();
  }, []);



  const retrieveIndustry = (id) => {
    for (let i = 0; i < industriesA.length; i++) {
      if (industriesA[i]?.id === id) {
        return industriesA[i];
      }
    }
  };
  return (
    <div className={styles.outer__container}>
      <img
        src={Logo}
        width='200px'
        height='35px'
        alt='logo'
        style={{ marginBottom: '50px' }}
        className={styles.logo}
      />
      <div className={styles.inner__container}>
        {industriesA.length === 0 && (
          <Box className={styles.fadedBox}>
            <CircularProgress
              size={80}
              color='inherit'
              sx={{ top: '50%', color: '#81BB00' }}
            />
          </Box>
        )}
        <p className={styles.select__competitor__one}>
          Selecting Your Competitors{' '}
        </p>
        <p className={styles.select__competitor__two}>
          Select which industries your competitors would fall in to.
        </p>
        <Formik
          initialValues={{
            toggle: false,
            checked: selectedIndustry?.id,
          }}
          onSubmit={async (values) => {
            dispatch(industrySelected(retrieveIndustry(values.checked)));
            if (!values.checked) {
              setNotSelectedError(true);
            } else {
              dispatch(setSavedCompetitors([]));
              dispatch(setNewCompetitors([]));
              navigate('/competitor-selection');
            }
          }}
        >
          {({ values }) => {
            return (
              <Form>
                <Grid
                  className={styles.selectionGrid}
                  container
                  spacing={3}
                  style={{ marginBottom: '130px' }}
                >
                  {industriesA.map((industry, i) => {
                    return (
                      <Grid item xs='auto' key={i}>
                        <div className={styles.selectionBox}>
                          <label className={styles.form__control}>
                            <Field
                              type='radio'
                              name='checked'
                              value={industry.id}
                            />
                            <span className={styles.checkbox__text}>
                              {changeCase(industry.name)}
                            </span>
                          </label>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
                {_hasError && (
                  <Alert
                    sx={{
                      fontFamily: 'Rubik',
                      fontSize: '16px',
                    }}
                    severity='error'
                  >
                    Please select a relavant Industry
                  </Alert>
                )}

                <Grid
                  container
                  direction='row'
                  justifyContent='flex-end'
                  alignItems='flex-end'
                >
                  {' '}
                  <Button
                    style={{ marginTop: '2rem' }}
                    type='submit'
                    variant='contained'
                    sx={{
                      mt: 3,
                      backgroundColor: '#399700',
                      borderRadius: '5px',
                      fontFamily: 'Rubik',
                      padding: '10px 50px',
                      textTransform: 'capitalize',
                      '&:hover': {
                        backgroundColor: '#399700',
                        opacity: 0.85,
                      },
                    }}
                  >
                    Next
                  </Button>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
