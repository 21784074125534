import { CircularProgress, Grid } from '@mui/material';
import { Navigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { setLoginStatus, userLogin } from '../redux/slices/authSlice';
import { removeCookies } from './Header';

export default function ProtectedRoute({ children }) {
  const isOnboard = Cookies.get('isOnboarding');
  const dispatch = useDispatch();
  const { loginUser } = useSelector(state => state.auth);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function isAuthenticated() {
      setIsLoading(true);
      try {
        await Auth.currentAuthenticatedUser();
        dispatch(setLoginStatus('AUTHENTICATED'));
      } catch (error) {
        dispatch(setLoginStatus('UNAUTHENTICATED'));
        removeCookies();
      }
      setIsLoading(false);
    }
    isAuthenticated();
  }, [dispatch, location]);

  if (isLoading) {
    return (
      <Grid container sx={{ width: '100%', height: '100vh', position: 'relative' }}>
        <CircularProgress size={80} color='inherit' sx={{ top: '50%', left: '50%', position: 'absolute', color: '#81BB00' }} />
      </Grid>
    );
  } else if (loginUser === 'UNAUTHENTICATED') {
    localStorage.clear();
    if (location.pathname !== '/' && location.pathname !== '/forgotPassword') {
      return <Navigate to='/' replace />;
    } else {
      return children;
    }
  } else {
    if (isOnboard === null) {
      return (
        <Grid container sx={{ width: '100%', height: '100vh', position: 'relative' }}>
          <CircularProgress size={80} color='inherit' sx={{ top: '50%', left: '50%', position: 'absolute', color: '#81BB00' }} />
        </Grid>
      );
    } else if (isOnboard === 'false') {
      if (location.pathname === '/companyDetails' ||
        location.pathname === '/industry-selection' || location.pathname === '/competitor-selection' || location.pathname === '/add-new-competitor') {
        return children;
      }
      return <Navigate to='/companyDetails' replace />;
    } else {
      if (location.pathname === '/' || location.pathname === '/companyDetails' || location.pathname === '/forgotPassword' ||
        location.pathname === '/industry-selection' || location.pathname === '/competitor-selection' || location.pathname === '/add-new-competitor') {
        return <Navigate to='/home' replace />;
      } else {
        return children;
      }
    }
  }
}
