import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useState } from 'react';
import styles from './CompleteForgotPassword.module.css';
import OtpInput from 'react18-input-otp';
import { Typography } from '@mui/material';

const validationSchema = yup.object({
  verificationCode: yup
    .string()
    .length(6, 'Verification code should have 6 digits')
    .required('Verification code is required'),
  newPassword: yup
    .string('Enter your new Password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$*.\[\]{}\(\)?\-"!@#%&\/\\,><':;|_~+=^`]).{8,}$/,
      'Must Contain 8 Characters and Least One Uppercase, One Lowercase, One Number and One Special Case Character'
    )
    .required('New Password is required'),
  confirmPassword: yup
    .string('Confirm your Password')
    .required('Confirm Password is required')
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});

export default function CompleteForgotPassword(props) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorAlert, setErrorAlert] = useState();
  const [isError, setIsError] = useState(false);
  const [isErrorAlert, setIsErrorAlert] = useState(false);

  const formik = useFormik({
    initialValues: {
      verificationCode: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const res = await Auth.forgotPasswordSubmit(
          props.emailAddress,
          values.verificationCode,
          values.newPassword
        );
        if (res === 'SUCCESS') {
          navigate('/');
          setIsLoading(false);

          return;
        } else {
          alert(res);
          setIsLoading(false);
        }
      } catch (err) {
        if (err.name === 'CodeMismatchException') {
          setErrorAlert('Incorrect verification code');
          setIsErrorAlert(true);
        } else if (err.name === 'ExpiredCodeException') {
          setErrorAlert('Verification code is expired!');
          setIsErrorAlert(true);
        } else {
          alert(err.message);
        }
        setIsLoading(false);
      }

      setIsLoading(false);
    },
  });

  async function handleResend() {
    setIsLoading(true);
    try {
      const res = await Auth.forgotPassword(props.emailAddress);
    } catch (err) {
      if (err.name === 'LimitExceededException') {
        setErrorAlert('Email limit exceeded. Try again later.');
        setIsErrorAlert(true);
        setIsLoading(false);
      } else {
        setErrorAlert(err.name);
        setIsErrorAlert(true);
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  }

  return (
    <>
      <Box component='form' onSubmit={formik.handleSubmit} noValidate>
        <Grid container justifyContent='center'>
          {isLoading && (
            <Box className={styles.fadedBox}>
              <CircularProgress
                size={80}
                color='inherit'
                sx={{ top: '50%', color: '#81BB00' }}
              />
            </Box>
          )}

          <Grid item sx={{ mt: '30px' }}>
            <OtpInput
              id='verificationCode'
              value={formik.values.verificationCode}
              onChange={(e) => {
                setIsErrorAlert(false);
                formik.handleChange({
                  target: {
                    id: 'verificationCode',
                    name: 'verificationCode',
                    value: e,
                  },
                });
              }}
              numInputs={6}
              separator={<span className={styles.midSpace}> </span>}
              inputStyle={styles.inputOtp}
              containerStyle={styles.containerStyle}
              isInputNum={true}
              hasErrored={isError}
              errorStyle={styles.errorStyle}
            />

            <Grid container>
              <Grid item xs={8} mt={0.5}>
                {(formik.touched.verificationCode &&
                  Boolean(formik.errors.verificationCode)) ||
                isErrorAlert ? (
                  <Typography
                    variant='caption'
                    display='block'
                    gutterBottom
                    ml='3px'
                    color='#c73e1d'
                  >
                    {(formik.touched.verificationCode &&
                      formik.errors.verificationCode) ||
                      (isErrorAlert && errorAlert)}
                  </Typography>
                ) : null}
              </Grid>

              <Grid
                mt={0.5}
                onClick={handleResend}
                xs={4}
                item
                container
                justifyContent='right'
              >
                <Typography
                  sx={{
                    textDecorationLine: 'underline',
                    textDecorationColor: '#2EDEFE',
                    '&:hover': {
                      textDecorationColor: 'black',
                      cursor: 'pointer',
                    },
                  }}
                  variant='body2'
                >
                  Resend Code
                </Typography>
              </Grid>
            </Grid>

            <TextField
              variant='standard'
              fullWidth
              id='newPassword'
              label='New Password'
              type='password'
              name='newPassword'
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.newPassword && Boolean(formik.errors.newPassword)
              }
              helperText={
                formik.touched.newPassword && formik.errors.newPassword
              }
              sx={{
                input: { color: 'black' },
                '& .MuiInputLabel-root': { color: 'black' },
                '& .MuiOutlinedInput-root': {
                  '& > fieldset': { borderColor: 'black' },
                },

                fontFamily: 'Rubik',
              }}
            />

            <TextField
              variant='standard'
              fullWidth
              id='confirmPassword'
              label='Confirm Password'
              type='password'
              name='confirmPassword'
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              sx={{
                input: { color: 'black' },
                '& .MuiInputLabel-root': { color: 'black' },
                '& .MuiOutlinedInput-root': {
                  '& > fieldset': { borderColor: 'black' },
                },
                fontFamily: 'Rubik',
              }}
            />

            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{
                mt: 3,
                backgroundColor: '#399700',
                borderRadius: '5px',
                fontFamily: 'Rubik',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '16px',
                lineHeight: '14px',
                paddingTop: '16px',
                paddingBottom: '16px',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#399700',
                  opacity: 0.85,
                },
              }}
            >
              Change Password
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
