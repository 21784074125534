import React, { useEffect, useState } from 'react';
import styles from './CompetitorSelection.module.css';

import { Alert, Autocomplete, Box, Button, CircularProgress, Grid } from '@mui/material';
import { CustomChip, CustomTextFieldOne } from '../components/CustomMuiComponents';
import { Field, Formik, Form } from 'formik';
import Logo from '../assets/Logo.svg';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  addSavedCompetitor,
  removeNewCompetitor,
  removeSavedCompetitor,
  setAllCompetitorsList,
  setSavedCompetitors,
} from '../redux/slices/competitorSelectionSlice';
import authServices from '../services/authServices';
import API from '../api/api';
import Cookies from 'js-cookie';

export default function CompetitorSelection() {
  const [_hasError, setNotSelectedError] = useState(false);
  const [value, setValue] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState();
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  const { selectedCompetitors, newCompetitors, selectedIndustry, savedCompetitors, allCompetitorsList } = useSelector(
    (state) => state.competitorSelection
  );
  const { prodGatheringMethod } = useSelector((state) => state.companyDetails);

  useEffect(() => {
    setIsLoading(true);
    function checkStates() {
      if (prodGatheringMethod.method === undefined) {
        navigate('/companyDetails');
      } else if (selectedIndustry === undefined || selectedIndustry === null) {
        navigate('/industry-selection');
      }
    }

    checkStates();

    async function getCompetitorsDetails() {
      const res = await API.get('competitors/');
      dispatch(
        setAllCompetitorsList(
          res.data.data.map((comp) => {
            return {
              id: comp._id + '',
              name: comp.name,
              industry: comp.industry,
              websiteURL: comp.websiteURL,
            };
          })
        )
      );
    }

    getCompetitorsDetails();
    setValue(savedCompetitors.concat(newCompetitors));
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setNotSelectedError(false);
  }, [value]);

  const onDelete = (competitor) => () => {
    setIsError(false);
    setValue((value) => value.filter((v) => v.id !== competitor.id));
    dispatch(removeNewCompetitor(competitor));
    dispatch(removeSavedCompetitor(competitor.id));
  };

  const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || '';

  const changeCase = (s) => {
    const text = s.split(' ');
    let newText = '';
    for (let i = 0; i < text.length; i++) {
      if (i === text.length - 1) {
        newText += capitalize(text[i]);
      } else {
        newText += capitalize(text[i]) + ' ';
      }
    }
    return newText;
  };

  const dispatch = useDispatch();

  return (
    <div className={styles.outer__container}>
      <img src={Logo} width='200px' height='35px' alt='logo' style={{ marginBottom: '50px' }} className={styles.logo} />
      <div className={styles.inner__container}>
        {isLoading && (
          <Box className={styles.fadedBox}>
            <CircularProgress size={80} color='inherit' sx={{ top: '50%', color: '#81BB00' }} />
          </Box>
        )}
        <p className={styles.select__competitor__one}>Selecting Your Competitors</p>
        <p className={styles.select__competitor__two}>Select your competitors by searching below.</p>
        <Formik
          initialValues={{
            toggle: false,
            checked: savedCompetitors.concat(newCompetitors),
          }}
          onSubmit={async (values) => {
            setIsLoading(true);
            try {
              if (value.length == 0) {
                setNotSelectedError(true);
              } else {
                let res;
                if (newCompetitors.length > 0) {
                  const data = [];
                  for (let i = 0; i < newCompetitors.length; i++) {
                    const competitor = {
                      name: newCompetitors[i].name,
                      websiteURL: newCompetitors[i].id,
                      industry: selectedIndustry.name,
                      selector: newCompetitors[i].selectors,
                    };
                    data.push(competitor);
                  }

                  try {
                    res = await API.post('competitors/add', { newCompetitorList: data });
                  } catch (e) {
                    if (e.response.status === 400) {
                      res = e.response;
                    } else {
                      res = e;
                    }
                  }
                  if (!res) {
                    setIsError(true);
                    setErrorMsg('Error: server not responding');
                    setIsLoading(false);
                    return;
                  } else if (res.status === 400) {
                    setIsError(true);
                    setErrorMsg(res.data);
                    setIsLoading(false);
                    return;
                  }
                }

                const competitorList = [];
                if (newCompetitors.length > 0) {
                  for (let i = 0; i < res.data.data.length; i++) {
                    competitorList.push(res.data.data[i]._id);
                  }
                }

                for (let i = 0; i < savedCompetitors.length; i++) {
                  competitorList.push(savedCompetitors[i].id);
                }

                const newUserForm = {
                  email: authServices.getCurrentUserEmail(),
                  selecIndustry: selectedIndustry.name,
                  competitorList: competitorList,
                  prodGatheringMethod: prodGatheringMethod,
                  isOnboard: true,
                };
                // const newUserForm = {
                //   email: authServices.getCurrentUserEmail(),
                //   selecIndustry: selectedIndustry.name,
                //   competitorList: [
                //     '63c79634f2f835c5a9779f4d','63bfc0fef899a104467389ad'
                //   ],
                //   prodGatheringMethod: prodGatheringMethod,
                // };

                try {
                  const response = await API.post('users/add', newUserForm);
                  if (!response) {
                    setIsError(true);
                    setErrorMsg('Error: server not responding');
                    setIsLoading(false);
                    return;
                  } else if (response.status === 400) {
                    setIsError(true);
                    setErrorMsg(response.data === '' ? 'Bad Request' : response.data);
                    setIsLoading(false);
                    return;
                  }
                  setIsLoading(false);
                  Cookies.set('isOnboarding', response.data.data.isOnboard);
                  navigate('/home');
                } catch (e) {
                  if (e.response.status === 400) {
                    setIsError(true);
                    setErrorMsg(e.response.data === '' ? 'Bad Request' : e.response.data);
                    setIsLoading(false);
                  }
                  return;
                }
              }
            } catch (e) {
              setErrorMsg(e.message);
              setIsError(true);
              setIsLoading(false);
            }
            setIsLoading(false);
          }}
        >
          {({ values }) => {
            return (
              <Form>
                <Grid container direction='column' justifyContent='flex-start' alignItems='flex-start'>
                  <Autocomplete
                    multiple
                    style={{ width: '100%' }}
                    id='disable-close-on-select'
                    disableCloseOnSelect
                    options={allCompetitorsList.filter((comp) => {
                      if (prodGatheringMethod.method === 'websiteURL') {
                        if (comp?.websiteURL === prodGatheringMethod.url) {
                          return false;
                        }
                      }
                      return comp?.industry === selectedIndustry?.name;
                    })}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={value}
                    getOptionLabel={(option) => option.name}
                    renderTags={() => null}
                    onChange={(event, newValue) => {
                      setIsError(false);
                      if (savedCompetitors.filter((val) => val.id === newValue[newValue.length - 1].id).length > 0) {
                        dispatch(
                          setSavedCompetitors(
                            savedCompetitors.filter((val) => {
                              for (let i = 0; i < newValue.length; i++) {
                                if (newValue[i].id === val.id) {
                                  return true;
                                }
                              }
                              return false;
                            })
                          )
                        );
                      } else {
                        dispatch(addSavedCompetitor(newValue[newValue.length - 1]));
                      }
                      setValue(newValue);
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={option.id}>
                        <label className={styles.form__control} style={{ padding: 0 }}>
                          <Field type='checkbox' name='industries' checked={selected} value='abc' />
                          {changeCase(option.name)}
                        </label>
                      </li>
                    )}
                    renderInput={(params) => (
                      <CustomTextFieldOne
                        {...params}
                        placeholder='Enter your competitor name here to begin search'
                        variant='standard'
                      />
                    )}
                  />
                  <div
                    style={{
                      marginBottom: '50px',
                      width: '100%',
                      marginTop: '20px',
                    }}
                  >
                    <Box>
                      {value.map((v) => {
                        return (
                          <CustomChip
                            key={v.id}
                            label={changeCase(v.name)}
                            onDelete={onDelete(v)}
                            style={{
                              borderRadius: '4px',
                              backgroundColor: '#333333',
                              marginBottom: '5px',
                              marginRight: '5px',
                            }}
                            deleteIcon={<CloseIcon sx={{ '&:hover': { color: 'black' } }} style={{ color: 'white' }} />}
                          />
                        );
                      })}
                    </Box>
                  </div>
                  <button
                    onClick={() => {
                      navigate('/add-new-competitor');
                    }}
                    color='inherit'
                    style={{
                      marginBottom: _hasError ? '50px' : '100px',
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                  >
                    Can’t find a competitor? Add them here.
                  </button>
                </Grid>
                {_hasError && (
                  <Alert
                    sx={{
                      fontFamily: 'Rubik',
                      fontSize: '16px',
                      marginBottom: '20px',
                    }}
                    severity='error'
                  >
                    Please select a at least one Competitor
                  </Alert>
                )}

                <Grid item mt={2} hidden={!isError}>
                  <Alert
                    severity='error'
                    onClose={() => {
                      setIsError(false);
                    }}
                  >
                    {errorMsg}
                  </Alert>
                </Grid>

                <Grid container direction='row' justifyContent='flex-end' alignItems='flex-start'>
                  {' '}
                  <Button
                    type='submit'
                    variant='contained'
                    sx={{
                      backgroundColor: '#399700',
                      borderRadius: '5px',
                      fontFamily: 'Rubik',
                      padding: '10px 50px',
                      textTransform: 'capitalize',
                      '&:hover': {
                        backgroundColor: '#399700',
                        opacity: 0.85,
                      },
                    }}
                  >
                    Next
                  </Button>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
