import { configureStore } from '@reduxjs/toolkit';
import authSlices from './slices/authSlice';
import carouselSlice from './slices/carouselSlice';
import companyDetailsSlice from './slices/companyDetailsSlice';
import competitorSelectionSlice from './slices/competitorSelectionSlice';
import itemCardSlice from './slices/itemCardSlice';
import appSlice from './slices/appSlice';
import homeSlice from './slices/homeSlice';
import priceChangeTableSlice from './slices/priceChangeTableSlice';
import pricePointSlice from './slices/pricePointSlice';
import priceDropSlice from './slices/priceDropSlice';
import demoSlice from './slices/demoSlice';
import marketRankingSlice from './slices/marketRankingSlice';


const store = configureStore({
  reducer: {
    auth: authSlices,
    competitorSelection: competitorSelectionSlice,
    companyDetails: companyDetailsSlice,
    carousel: carouselSlice,
    itemCard: itemCardSlice,
    app: appSlice,
    home: homeSlice,
    priceChangeTable: priceChangeTableSlice,
    pricePoint:pricePointSlice,
    priceDrop:priceDropSlice,
    marketRanking:marketRankingSlice,
    demo:demoSlice,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export default store;
