import { createSlice } from '@reduxjs/toolkit';
import { revertAll } from '../../util/reverAll';

const initialState = {
  selectedCategoryPriceChange: '',
  selectedTimePriceChange: 'today',
  selectedProductPriceChange:{},
  selectedChangePriceChange:'',
  priceChangeTableData: [],
  isLoadingPriceChange: true,


};

const priceChangeSlice = createSlice({
  name: 'priceChange',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setPriceChangeTableData: (state, action) => {
      state.priceChangeTableData = action.payload;
    },
    setIsLoadingPriceChange: (state, action) => {
      state.isLoadingPriceChange = action.payload;
    },
    setSelectedCategoryPriceChange: (state, action) => {
      state.selectedCategoryPriceChange = action.payload;
    },
    setSelectedTimePriceChange: (state, action) => {
      state.selectedTimePriceChange = action.payload;
    },
    setSelectedProductPriceChange: (state, action) => {
      state.selectedProductPriceChange = action.payload;
    },
    setSelectedChangePriceChange: (state, action) => {
      state.selectedChangePriceChange = action.payload;
    },
  },
});

export default priceChangeSlice.reducer;
export const {
  setPriceChangeTableData,
  setIsLoadingPriceChange,
  setSelectedCategoryPriceChange,
  setSelectedTimePriceChange,
  setSelectedProductPriceChange,
  setSelectedChangePriceChange
  
} = priceChangeSlice.actions;
