import { createSlice, current } from '@reduxjs/toolkit';
import { revertAll } from '../../util/reverAll';

const initialState = {
  categoryList: ['All'],
  productList: [],
  newNotifyCount : 0,

};

const homeSlice = createSlice({
  name: 'home',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {

    setCategoryList: (state, action) => {
      state.categoryList = action.payload;
    },
    setProductList: (state, action) => {
      state.productList = action.payload;
    },
    setNewNotifyCount : (state, action) =>{
      state.newNotifyCount = action.payload;
    }

  },
});

export default homeSlice.reducer;
export const {
  setCategoryList,
  setProductList,
  setNewNotifyCount,
} = homeSlice.actions;
