import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useState } from 'react';
import styles from './ChangePassword.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthUser } from '../redux/slices/authSlice';
import axios from 'axios';
import Cookies from 'js-cookie';
const validationSchema = yup.object({
  newPassword: yup
    .string('Enter your new Password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$*.\[\]{}\(\)?\-"!@#%&\/\\,><':;|_~+=^`]).{8,}$/,
      'Must Contain 8 Characters and Least One Uppercase, One Lowercase, One Number and One Special Case Character'
    )
    .required('New Password is required'),
  confirmPassword: yup
    .string('Confirm your Password')
    .required('Confirm Password is required')
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});

export default function ChangePassword(props) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { authUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        if (values.newPassword === values.confirmPassword) {
          const user = await Auth.completeNewPassword(
            authUser,
            values.confirmPassword
          );
          dispatch(setAuthUser(user));
          if (user.signInUserSession.accessToken.jwtToken !== null) {
            Cookies.set(
              'user_token',
              user.signInUserSession.accessToken.jwtToken
            );
            Cookies.set('email', authUser.challengeParam.userAttributes.email);

            try {
              const res = await axios.get(`${process.env.REACT_APP_DEV_API_URL}/users/getUser`,
                {
                  headers: { 'x-auth-token': user.signInUserSession.accessToken.jwtToken }
                }
              );

              //todo: store isOnboarding in cockies instead of the session storage
              res.status === 204 ?
                Cookies.set(
                  'isOnboarding',
                  false)
                :
                Cookies.set(
                  'isOnboarding',
                  res.data.isOnboard
                );

              if (res.status === 200 && res.data.isOnboard) {
                navigate('/home');
              } else if (res.status === 204 || !res.data.isOnboard) {
                navigate('/companyDetails');
              }
            } catch (e) {
              alert(e.message);
            }
          } else {
            navigate('/');
          }
        }

        setIsLoading(false);
        props.changePwdStatus(false);
      } catch (err) {
        alert(err);
        setIsLoading(false);
      }
    },
  });

  return (
    <>
      <Box
        className={styles.whiteBox}
        component='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <Grid container justifyContent='center'>
          {isLoading && (
            <Box className={styles.fadedBox}>
              <CircularProgress
                size={80}
                color='inherit'
                sx={{ top: '50%', color: '#81BB00' }}
              />
            </Box>
          )}

          <Grid item>
            <p className={styles.welcome}>Change Password</p>
            <p className={styles.enterCred}>Enter your New Password.</p>
          </Grid>

          <Grid item sx={{ mt: '30px' }}>
            <TextField
              variant='standard'
              fullWidth
              id='newPassword'
              label='New Password'
              type='password'
              name='newPassword'
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.newPassword && Boolean(formik.errors.newPassword)
              }
              helperText={
                formik.touched.newPassword && formik.errors.newPassword
              }
              sx={{
                input: { color: 'black' },
                '& .MuiInputLabel-root': { color: 'black' },
                '& .MuiOutlinedInput-root': {
                  '& > fieldset': { borderColor: 'black' },
                },

                fontFamily: 'Rubik',
              }}
            />

            <TextField
              variant='standard'
              fullWidth
              id='confirmPassword'
              label='Confirm Password'
              type='password'
              name='confirmPassword'
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              sx={{
                input: { color: 'black' },
                '& .MuiInputLabel-root': { color: 'black' },
                '& .MuiOutlinedInput-root': {
                  '& > fieldset': { borderColor: 'black' },
                },
                fontFamily: 'Rubik',
              }}
            />

            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{
                mt: 3,
                backgroundColor: '#399700',
                borderRadius: '5px',
                fontFamily: 'Rubik',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '16px',
                lineHeight: '14px',
                paddingTop: '16px',
                paddingBottom: '16px',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#399700',
                  opacity: 0.85,
                },
              }}
            >
              Change Password
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
