import { createSlice } from '@reduxjs/toolkit';
import { revertAll } from '../../util/reverAll';

const initialState = {
  isEdit: false,
  settingsPopoverOptions: ['Admin', 'Edit Widgets', 'Logout'],
  isOnboarding: null,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setIsEdit: (state, action) => {
      state.isEdit = action.payload;
    },
    setSettingsPopoverOptions: (state, action) => {
      state.settingsPopoverOptions = action.payload;
    },
    setIsOnboarding: (state, action) => {
      state.isOnboarding = action.payload;
    }
  },
});

export default appSlice.reducer;
export const { setIsEdit, setSettingsPopoverOptions, setIsOnboarding } = appSlice.actions;
