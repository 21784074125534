import { createSlice, current } from '@reduxjs/toolkit';
import { revertAll } from '../../util/reverAll';

const initialState = {
  isDemo: false
};

const demoSlice = createSlice({
  name: 'demo',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setIsDemo: (state, action) => {
      state.isDemo = action.payload;
    },
  },
});

export default demoSlice.reducer;
export const {
  setIsDemo,
} = demoSlice.actions;
