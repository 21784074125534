import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useState } from 'react';
import styles from './ForgotPasswordCard.module.css';
import CompleteForgotPassword from './CompleteForgotPassword';

const validationSchema = yup.object({
  emailAddress: yup
    .string()
    .email('Invalid email address')
    .required('Email address is required'),
});

export default function ForgotPasswordCard() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [stage, setStage] = useState(0);
  const [errorAlert, setErrorAlert] = useState();
  const [isErrorAlert, setIsErrorAlert] = useState(false);

  const formik = useFormik({
    initialValues: {
      emailAddress: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const res = await Auth.forgotPassword(values.emailAddress);
        setStage(1);
      } catch (err) {
        if (err.name === 'LimitExceededException') {
          setErrorAlert('Email limit exceeded. Try again later.');
          setIsErrorAlert(true);
        } else if (err.name === 'UserNotFoundException') {
          setErrorAlert('User not found. Invalid email address.');
          setIsErrorAlert(true);
        } else {
          alert(err.name);
        }
      }
      setIsLoading(false);
    },
  });

  return (
    <>
      <Box className={styles.whiteBox} maxWidth='400px'>
        <Grid container justifyContent='center'>
          {isLoading && (
            <Box className={styles.fadedBox}>
              <CircularProgress
                size={80}
                color='inherit'
                sx={{ top: '50%', color: '#81BB00' }}
              />
            </Box>
          )}
          <Grid item>
            <p className={styles.welcome}>
              {stage === 0 ? 'Forgot Password' : 'Verification Code'}
            </p>
            {stage === 0 ? (
              <p className={styles.enterCred}>
                {
                  'Enter your email associated with Advanto.ai and we will send you a password reset link.'
                }
              </p>
            ) : stage === 1 ? (
              <p className={styles.enterCred}>
                {'A verification code has been sent to your entered email ' +
                  formik.values.emailAddress}
              </p>
            ) : null}
          </Grid>

          <Grid item width={'100%'} hidden={stage !== 0} sx={{ mt: '30px' }}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <TextField
                variant='standard'
                fullWidth
                id='emailAddress'
                label='Email Address'
                type='email'
                name='emailAddress'
                value={formik.values.emailAddress}
                onChange={(e) => {
                  setIsErrorAlert(false);
                  formik.handleChange(e);
                }}
                error={
                  (formik.touched.emailAddress &&
                    Boolean(formik.errors.emailAddress)) ||
                  isErrorAlert
                }
                helperText={
                  (formik.touched.emailAddress && formik.errors.emailAddress) ||
                  (isErrorAlert && errorAlert)
                }
                sx={{
                  input: { color: 'black' },
                  '& .MuiInputLabel-root': { color: 'black' },
                  '& .MuiOutlinedInput-root': {
                    '& > fieldset': { borderColor: 'black' },
                  },

                  fontFamily: 'Rubik',
                }}
              />

              <Button
                type='submit'
                fullWidth
                variant='contained'
                sx={{
                  mt: 3,
                  backgroundColor: '#399700',
                  borderRadius: '5px',
                  fontFamily: 'Rubik',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '16px',
                  lineHeight: '14px',
                  paddingTop: '16px',
                  paddingBottom: '16px',
                  textTransform: 'capitalize',
                  '&:hover': {
                    backgroundColor: '#399700',
                    opacity: 0.85,
                  },
                }}
              >
                Send Verification Code
              </Button>
            </form>
          </Grid>

          {isLoading && (
            <Box className={styles.fadedBox}>
              <CircularProgress
                size={80}
                color='inherit'
                sx={{ top: '50%', color: '#81BB00' }}
              />
            </Box>
          )}

          <Grid item hidden={stage !== 1}>
            <CompleteForgotPassword emailAddress={formik.values.emailAddress} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
