import { createSlice } from '@reduxjs/toolkit';
import { revertAll } from '../../util/reverAll';

const initialState = {
  currentIndex: 0,
  showInteger: 4,
};

const carouselSlice = createSlice({
  name: 'carousel',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setShowInt: (state, action) => {
      state.showInteger = action.payload;
    },
    setCurrentIndex: (state, action) => {
      state.currentIndex = action.payload;
    },
  },
});

export default carouselSlice.reducer;
export const { setShowInt, setCurrentIndex } = carouselSlice.actions;
