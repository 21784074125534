import React, { useState } from 'react';
import { Block, Flex } from '@tremor/react';
import Low from '../assets/low.png';
import High from '../assets/high.png';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import styles from './SideBarContent.module.css';
import relativeTime from 'dayjs/plugin/relativeTime';
import NotifyDropDown from '../assets/notifyDropDown.svg';
import NoNotification from '../assets/no_notification.png';
import NotificationPopover from './NotificationPopover';
import demoNameFix from '../util/DemoNameFix';
import { useDispatch, useSelector } from 'react-redux';



//import styles from './SideBar.module.css';

// import Pr1 from '../assets/pr1.png';
// import Pr2 from '../assets/pr2.png';
// import Pr3 from '../assets/pr3.png';


dayjs.extend(relativeTime);

export default function SideBarContent(props) {
  const { notifications,setNotifications, notifyError } = props;
  const [hoveredId, setHoveredId] = useState(null);
  const [popupOpenId, setPopupOpenId]= useState(null);
  const {isDemo} = useSelector((state)=> state.demo);


  const handleMouseEnter = (key) => {
    setHoveredId(key);
  };

  const handleMouseLeave = (key) => {
    setHoveredId(null);
  };

  // Convert the String value to float and remove the special Characters
  const toFloat = (val) => {
    return parseFloat(val.replace(/[^0-9.-]+/g, ''));
  };

  // Get the currency Symbol out of the prices. 
  const getCurrency = (val) => {
    return val.match(/[^\d.]+/g);
  };

  return (
    <>
      {(notifications && notifications.length > 0) && (
        <>
          {notifications.map((notify, index) => (
            <div style={{ padding: '10px', background: (hoveredId === `${notify.uuid}-${index}`|| popupOpenId === `${notify.uuid}-${index}`) && 'rgba(0, 0, 0, 0.06)' }} key={index} onMouseEnter={() => handleMouseEnter(`${notify.uuid}-${index}`)}
              onMouseLeave={() => handleMouseLeave(null)}>
              <Flex justifyContent="justify-start" spaceX="space-x-2" key={`${notify.uuid}-${index}`}>
                <div style={{ position: 'relative', bottom: 0, right: 0 }}>
                  <img src={notify.image} alt="" style={{ width: '50px', heigh: '50px' }} />
                  <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
                    {(toFloat(notify.newPrice) > toFloat(notify.oldPrice)) ? <img src={High} alt="" /> : <img src={Low} alt="" />}
                  </div>
                </div>
                <Block >
                  <div style={{opacity:notify.read ? 0.5 : 1}}>
                  <p className={styles.notHeader}>
                    <span className={styles.notHeaderHigh}>{ isDemo==='true'? demoNameFix(notify.competitorName) :   notify.competitorName.replace(/^\w/, (c) => c.toUpperCase())}</span>
                    {' '}
                    {(toFloat(notify.newPrice) > toFloat(notify.oldPrice)) ? 'increased' : 'reduced'}
                    {' '}
                    the price of
                    {' '}
                    <span className={styles.notHeaderHigh}>
                      “{notify.productName.replace(/^\w/, (c) => c.toUpperCase())}”
                    </span>
                    {' '}
                    by
                    {' '}
                    <span className={styles.notHeaderHigh}>{`${getCurrency(notify.newPrice)}${Math.abs((toFloat(notify.newPrice) - toFloat(notify.oldPrice))).toFixed(2)}`}</span>
                  </p>

                  <p className={styles.notSubHeader}>{dayjs(notify.changeDate).fromNow()}</p>
                  </div>
                </Block>
                <div style={{ position: 'relative', bottom: 0, left: 0, visibility: (hoveredId === `${notify.uuid}-${index}`|| popupOpenId === `${notify.uuid}-${index}`) ? 'visible' : 'hidden' }}>
                  {/* <img src={NotifyDropDown} alt="" className={styles.notifyDropDown} /> */}
                  <NotificationPopover uuid={notify.uuid} index={index} read={notify.read} notifications={notifications} setNotifications={setNotifications} setPopupOpenId={setPopupOpenId} />
                </div>
              </Flex>
            </div>
          ))}
        </>)}
    </>
  );

 // This is for to use if there is an issue in displaying the data for Notifications. 
  // return (
  //   <>

  //     <div style={{ marginBottom: '20px' }}>
  //       <Flex justifyContent="justify-start" spaceX="space-x-2">
  //         <div style={{ position: 'relative', bottom: 0, right: 0 }}>
  //           <img src={Pr1} alt="" />
  //           <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
  //             <img src={Low} alt="" />
  //           </div>
  //         </div>
  //         <Block>
  //           <p className={styles.notHeader}>
  //             <span className={styles.notHeaderHigh}>Wallgreens</span>
  //             {' '}
  //             reduced
  //             the price of
  //             {' '}
  //             <span className={styles.notHeaderHigh}>
  //               "Tempo Petit Handkerchief - Neutral"
  //             </span>
  //             {' '}
  //             by
  //             {' '}
  //             <span className={styles.notHeaderHigh}>$0.50</span>
  //           </p>
  //           <p className={styles.notSubHeader}>2 hours ago</p>
  //         </Block>
  //       </Flex>
  //     </div>

  //     <div style={{ marginBottom: '20px' }}>
  //       <Flex justifyContent="justify-start" spaceX="space-x-2">
  //         <div style={{ position: 'relative', bottom: 0, right: 0 }}>
  //           <img src={Pr2} alt="" />
  //           <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
  //             <img src={High} alt="" />
  //           </div>
  //         </div>
  //         <Block>
  //           <p className={styles.notHeader}>
  //             <span className={styles.notHeaderHigh}>Seven Eleven</span>
  //             {' '}
  //             increased the price of
  //             <span className={styles.notHeaderHigh}>
  //               "Libby's Corned Beef"
  //             </span>
  //             {' '}
  //             by
  //             {' '}
  //             <span className={styles.notHeaderHigh}>$0.10</span>
  //           </p>
  //           <p className={styles.notSubHeader}>15 hours ago</p>
  //         </Block>
  //       </Flex>
  //     </div>

  //     <div style={{ marginBottom: '20px' }}>
  //       <Flex justifyContent="justify-start" spaceX="space-x-2">
  //         <div style={{ position: 'relative', bottom: 0, right: 0 }}>
  //           <img src={Pr3} alt="" />
  //           <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
  //             <img src={Low} alt="" />
  //           </div>
  //         </div>
  //         <Block>
  //           <p className={styles.notHeader}>
  //             <span className={styles.notHeaderHigh}>Wallgreens</span>
  //             {' '}
  //             reduced
  //             the price of
  //             {' '}
  //             <span className={styles.notHeaderHigh}>
  //               "CP Shrimp Wanton (Bowl)"
  //             </span>
  //             {' '}
  //             by
  //             {' '}
  //             <span className={styles.notHeaderHigh}>$0.25</span>
  //           </p>
  //           <p className={styles.notSubHeader}>1 Day ago</p>
  //         </Block>
  //       </Flex>
  //     </div>

  //     <div style={{ marginBottom: '20px' }}>
  //       <Flex justifyContent="justify-start" spaceX="space-x-2">
  //         <div style={{ position: 'relative', bottom: 0, right: 0 }}>
  //           <img src={Pr1} alt="" />
  //           <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
  //             <img src={Low} alt="" />
  //           </div>
  //         </div>
  //         <Block>
  //           <p className={styles.notHeader}>
  //             <span className={styles.notHeaderHigh}>Wallgreens</span>
  //             {' '}
  //             reduced
  //             the price of
  //             {' '}
  //             <span className={styles.notHeaderHigh}>
  //               "Tempo Petit Handkerchief - Neutral"
  //             </span>
  //             {' '}
  //             by
  //             {' '}
  //             <span className={styles.notHeaderHigh}>$0.50</span>
  //           </p>
  //           <p className={styles.notSubHeader}>2 Days ago</p>
  //         </Block>
  //       </Flex>
  //     </div>

  //     <div style={{ marginBottom: '20px' }}>
  //       <Flex justifyContent="justify-start" spaceX="space-x-2">
  //         <div style={{ position: 'relative', bottom: 0, right: 0 }}>
  //           <img src={Pr2} alt="" />
  //           <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
  //             <img src={High} alt="" />
  //           </div>
  //         </div>
  //         <Block>
  //           <p className={styles.notHeader}>
  //             <span className={styles.notHeaderHigh}>Seven Eleven</span>
  //             {' '}
  //             increased the price of
  //             <span className={styles.notHeaderHigh}>
  //               "Libby's Corned Beef"
  //             </span>
  //             {' '}
  //             by
  //             {' '}
  //             <span className={styles.notHeaderHigh}>$0.10</span>
  //           </p>
  //           <p className={styles.notSubHeader}>2 Days ago</p>
  //         </Block>
  //       </Flex>
  //     </div>

  //     <div style={{ marginBottom: '20px' }}>
  //       <Flex justifyContent="justify-start" spaceX="space-x-2">
  //         <div style={{ position: 'relative', bottom: 0, right: 0 }}>
  //           <img src={Pr3} alt="" />
  //           <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
  //             <img src={Low} alt="" />
  //           </div>
  //         </div>
  //         <Block>
  //           <p className={styles.notHeader}>
  //             <span className={styles.notHeaderHigh}>Wallgreens</span>
  //             {' '}
  //             reduced
  //             the price of
  //             {' '}
  //             <span className={styles.notHeaderHigh}>
  //               "CP Shrimp Wanton (Bowl)"
  //             </span>
  //             {' '}
  //             by
  //             {' '}
  //             <span className={styles.notHeaderHigh}>$0.25</span>
  //           </p>
  //           <p className={styles.notSubHeader}>4 Days ago</p>
  //         </Block>
  //       </Flex>
  //     </div>

  //     <div style={{ marginBottom: '20px' }}>
  //       <Flex justifyContent="justify-start" spaceX="space-x-2">
  //         <div style={{ position: 'relative', bottom: 0, right: 0 }}>
  //           <img src={Pr2} alt="" />
  //           <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
  //             <img src={High} alt="" />
  //           </div>
  //         </div>
  //         <Block>
  //           <p className={styles.notHeader}>
  //             <span className={styles.notHeaderHigh}>Seven Eleven</span>
  //             {' '}
  //             increased the price of
  //             <span className={styles.notHeaderHigh}>
  //               "Libby's Corned Beef"
  //             </span>
  //             {' '}
  //             by
  //             {' '}
  //             <span className={styles.notHeaderHigh}>$0.10</span>
  //           </p>
  //           <p className={styles.notSubHeader}>5 Days ago</p>
  //         </Block>
  //       </Flex>
  //     </div>

  //     <div style={{ marginBottom: '20px' }}>
  //       <Flex justifyContent="justify-start" spaceX="space-x-2">
  //         <div style={{ position: 'relative', bottom: 0, right: 0 }}>
  //           <img src={Pr3} alt="" />
  //           <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
  //             <img src={Low} alt="" />
  //           </div>
  //         </div>
  //         <Block>
  //           <p className={styles.notHeader}>
  //             <span className={styles.notHeaderHigh}>Wallgreens</span>
  //             {' '}
  //             reduced
  //             the price of
  //             {' '}
  //             <span className={styles.notHeaderHigh}>
  //               "CP Shrimp Wanton (Bowl)"
  //             </span>
  //             {' '}
  //             by
  //             {' '}
  //             <span className={styles.notHeaderHigh}>$0.25</span>
  //           </p>
  //           <p className={styles.notSubHeader}>5 Days ago</p>
  //         </Block>
  //       </Flex>
  //     </div>

  //     <div style={{ marginBottom: '20px' }}>
  //       <Flex justifyContent="justify-start" spaceX="space-x-2">
  //         <div style={{ position: 'relative', bottom: 0, right: 0 }}>
  //           <img src={Pr1} alt="" />
  //           <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
  //             <img src={Low} alt="" />
  //           </div>
  //         </div>
  //         <Block>
  //           <p className={styles.notHeader}>
  //             <span className={styles.notHeaderHigh}>Wallgreens</span>
  //             {' '}
  //             reduced
  //             the price of
  //             {' '}
  //             <span className={styles.notHeaderHigh}>
  //               "Tempo Petit Handkerchief - Neutral"
  //             </span>
  //             {' '}
  //             by
  //             {' '}
  //             <span className={styles.notHeaderHigh}>$0.50</span>
  //           </p>
  //           <p className={styles.notSubHeader}>8 Days ago</p>
  //         </Block>
  //       </Flex>
  //     </div>
  //   </>
  // );
}
