import { createSlice, current } from '@reduxjs/toolkit';
import { revertAll } from '../../util/reverAll';

const initialState = {
    selectedCategoryMarketRanking: 'All',
    selectedTimeMarketRanking: 'today',
    marketRankingTableData: [],
    isLoadingMarketRanking: true,
};

const marketRankingSlice = createSlice({
  name: 'marketRanking',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    
    setMarketRankingTableData: (state, action) => {
        state.marketRankingTableData = action.payload;
      },
      setIsLoadingMarketRanking: (state, action) => {
        state.isLoadingMarketRanking = action.payload;
      },
      setSelectedCategoryMarketRanking: (state, action) => {
        state.selectedCategoryMarketRanking = action.payload;
      },
  
      setSelectedTimeMarketRanking: (state, action) => {
        state.selectedTimeMarketRanking = action.payload;
      },
  },
});

export default marketRankingSlice.reducer;
export const {
    setMarketRankingTableData,
    setIsLoadingMarketRanking,
    setSelectedCategoryMarketRanking,
    setSelectedTimeMarketRanking

} = marketRankingSlice.actions;
