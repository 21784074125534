import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import SkeletonDisplay from '../components/SkeletonDisplay';
import styles from './PricePointPage.module.css';
import { AreaChart } from '@tremor/react';

const chartdata = [
  {
    month: 'Jan',
    'Your pricing': 4.5,
    'Market Average': 15.0,
  },
  {
    month: 'Feb',
    'Your pricing': 8.9,
    'Market Average': 14.65,
  },
  {
    month: 'Mar',
    'Your pricing': 28.1,
    'Market Average': 35.0,
  },
  {
    month: 'Apr',
    'Your pricing': 24.3,
    'Market Average': 30.4,
  },
  {
    month: 'May',
    'Your pricing': 50.7,
    'Market Average': 28.2,
  },
  {
    month: 'Jun',
    'Your pricing': 14.0,
    'Market Average': 19.3,
  },
  {
    month: 'Jul',
    'Your pricing': 22.1,
    'Market Average': 38.8,
  },
  {
    month: 'Aug',
    'Your pricing': 29.2,
    'Market Average': 29.2,
  },
  {
    month: 'Sept',
    'Your pricing': 12.4,
    'Market Average': 15.6,
  },
  {
    month: 'Oct',
    'Your pricing': 17.9,
    'Market Average': 22.1,
  },
  {
    month: 'Nov',
    'Your pricing': 28.3,
    'Market Average': 29.2,
  },
  {
    month: 'Dec',
    'Your pricing': 31.6,
    'Market Average': 30.2,
  },
];

const dataFormatter = (number) =>
  `${Intl.NumberFormat('us').format(number).toString()}`;

export default function PricePointPage() {
  const [initialLoad, setInitialLoad] = useState(false);

  useEffect(() => {
    setInitialLoad(true);
    const timeoutId = setTimeout(() => {
      setInitialLoad(false);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      <div className={styles.mainDiv}>
        {initialLoad ? (
          <SkeletonDisplay />
        ) : (
          <>
            <Grid
              container
              direction='column'
              sx={{ height: '100%', py: '24px' }}
            >
              <Grid item container mb='20px'>
                <Grid item xs={6} textAlign='left'>
                  <div>
                    <p className={styles.tableTopic}>Price Points</p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <div style={{ backgroundColor: '#F9FBFA', marginTop: '20px' }}>
              <p className={styles.areaChartHeader}>
                Tempo Petit Handkerchief - Neutral
              </p>
              <AreaChart
                data={chartdata}
                categories={['Your pricing', 'Market Average']}
                dataKey='month'
                height='h-72'
                colors={['cyan', 'gray']}
                valueFormatter={dataFormatter}
                marginTop='mt-4'
                showTooltip
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}
