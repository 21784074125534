import { createSlice } from '@reduxjs/toolkit';
import { revertAll } from '../../util/reverAll';

//slices for company detatails

const initialState = {
  file: {},
  fileTypeError: false,
  fileError: false,
  isUpload: false,
  openDialog: false,
  tempFile: {},
  prodGatheringMethod: {},
};

const companyDetailsSlice = createSlice({
  name: 'companyDetails',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setFile: (state, action) => {
      state.file = action.payload;
    },
    setIsUpload: (state, action) => {
      state.isUpload = action.payload;
    },
    setFileError: (state, action) => {
      state.fileError = action.payload;
    },
    setFileTypeError: (state, action) => {
      state.fileTypeError = action.payload;
    },
    setOpenDialog: (state, action) => {
      state.openDialog = action.payload;
    },
    setTempFile: (state, action) => {
      state.tempFile = action.payload;
    },
    setProdGatheringMethod: (state, action) => {
      state.prodGatheringMethod = action.payload;
    },
  },
});
export default companyDetailsSlice.reducer;
export const {
  setFile,
  setIsUpload,
  setFileError,
  setFileTypeError,
  setOpenDialog,
  setTempFile,
  setProdGatheringMethod,
} = companyDetailsSlice.actions;
