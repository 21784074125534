import { useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Backdrop,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  useMediaQuery,
  Stack,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useDispatch, useSelector } from 'react-redux';


import { Block, Col, ColGrid, Flex } from '@tremor/react';
import API from '../api/api';
import styles from './CardSet.module.css';
import classes from './ProductCard.module.css';
import ItemAltImage from '../assets/productItemAlt.png';
import NoDataMessage from './NoDataMessage';
import DateTooltip from './DateTooltip';
import demoNameFix from '../util/DemoNameFix';

export default function ProductCard(props) {
  const cardContRef = useRef(null);
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const isMediumScreen = useMediaQuery(
    '(min-width:601px) and (max-width:960px)'
  );
  const [recentProductChanges, setRecentProductChanges] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [itemImage, setItemImage] = useState(ItemAltImage);
  const [lowest, setLowest] = useState({});
  const [highest, setHighest] = useState({});
  const { isDemo } = useSelector((state) => state.demo);

  const gapX = isSmallScreen
    ? 'gap-x-2'
    : isMediumScreen
    ? 'gap-x-8'
    : 'gap-x-10';

  // close modal
  const handleModalClose = () => {
    props.handleClose(false);
  };

  function compare(a, b) {
    if (Number(a.price.slice(1)) < Number(b.price.slice(1))) {
      return -1;
    }
    if (Number(a.price.slice(1)) > Number(b.price.slice(1))) {
      return 1;
    }
    return 0;
  }

  //convert price to 2 decimal value
  const toDec2 = (val) => {
    return `$${Number(val.slice(1)).toFixed(2)}`;
  };

  //set alternative image on error
  const onImageError = () => {
    setItemImage(ItemAltImage);
  };

  useEffect(() => {
    if (props.productInfo?.image) {
      setItemImage(props.productInfo?.image);
    }

    //function to call api to get pricechanges of product in each store
    async function getPriceChangeByProduct() {
      try {
        setIsLoading(true);

        const response = await API.get('producthistory/priceChanges', {
          params: {
            productId: props.productInfo.pid,
          },
        });
        const priceChanges = response.data.data.priceList;
        const userStoreName = response.data.data.userStoreName;
        let allStorePrices = priceChanges.map((a) => ({
          price: a.newPrice,
          store: a.productOwner,
        }));
        setRecentProductChanges(priceChanges);

        allStorePrices.push({
          price: props.productInfo.your_current_price,
          store: userStoreName,
        });
        allStorePrices.sort(compare);

        setLowest(allStorePrices[0]);
        setHighest(allStorePrices[allStorePrices.length - 1]);
        setIsLoading(false);
      } catch (error) {
        // console.log(error);
      }
    }

    getPriceChangeByProduct();
  }, []);

  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      }}
      open={true}
    >
      <Card
        className={classes.modal}
        sx={{
          border: '1px solid #EDEDED',
          borderRadius: '10px',
          padding: '10px',
        }}
      >
        {isLoading ? (
          <Grid
            item
            container
            mt={2}
            sx={{
              display: 'flex',
              position: 'relative',
              minHeight: '300px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress
              size="6rem"
              style={{ color: 'grey', position: 'absolute' }}
            />
          </Grid>
        ) : (
          <>
            <IconButton
              aria-label="close"
              onClick={handleModalClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            {/* <Box sx={{ overflow: 'hidden' }} ref={cardContRef}> */}
            <CardContent sx={{ overflow: 'hidden' }} ref={cardContRef}>
              <Grid container direction="column">
                <Grid item container direction="row" spacing={1}>
                  <Grid
                    item
                    sm={3}
                    style={{ margin: 'auto' }}
                    sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}
                  >
                    <img
                      src={itemImage}
                      onError={onImageError}
                      style={{ width: '200px', margin: 'auto' }}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="space-between"
                    marginLeft={2}
                    marginRight={2}
                    xs={12}
                    sm={7}
                  >
                    <Grid
                      item
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid
                        item
                        xs={3}
                        style={{ margin: 'auto' }}
                        textAlign="left"
                        sx={{
                          display: { xs: 'block', sm: 'none', md: 'none' },
                          padding: '0px',
                        }}
                      >
                        <img
                          src={props.productInfo.image}
                          alt=""
                          style={{ width: '100px', margin: 'auto' }}
                        />
                      </Grid>

                      <Grid item textAlign="left" xs={8} sm={12}>
                        <p
                          className={classes.cardItemP}
                          style={{
                            textAlign: 'left',
                            fontSize: '18px',
                            fontWeight: 600,
                            overflow: 'visible',
                          }}
                        >
                          {props.productInfo.name}
                        </p>

                        {props.productInfo.itemSubName && (
                          <span className={classes.cardItemSub}>
                            props.productInfo.itemSubName{' '}
                          </span>
                        )}
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid
                        item
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ mt: '24px' }}
                      >
                        <Grid item xs={6} textAlign="left">
                          <p className={styles.yourPrice}>Your Price: </p>
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                          <span
                            className={styles.amout}
                            style={{ textAlign: 'right' }}
                          >
                            {`${toDec2(props.productInfo.your_current_price)}`}
                          </span>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <ColGrid
                          numCols={2}
                          numColsSm={2}
                          numColsMd={2}
                          numColsLg={2}
                          marginTop="mt-5"
                          gapX={gapX}
                        >
                          <Block truncate>
                            <ColGrid
                              numCols={3}
                              numColsSm={3}
                              numColsMd={3}
                              numColsLg={3}
                              alignItems="items-center"
                              justifyContent="justify-center"
                            >
                              <Col
                                numColSpan={2}
                                numColSpanSm={2}
                                numColSpanMd={2}
                                numColSpanLg={2}
                              >
                                <Block>
                                  <p className={styles.lowest}>Lowest</p>

                                  <p className={styles.lowestPrice}>
                                    {lowest?.price && toDec2(lowest.price)}
                                  </p>
                                  <p className={styles.place}>
                                
                                    { lowest.store && `@ ${
                                      isDemo === 'true'
                                        ? demoNameFix(lowest.store)
                                        : lowest.store
                                    }`}
                                  </p>
                                </Block>
                              </Col>
                              <Flex
                                alignItems="items-center"
                                justifyContent="justify-end"
                              >
                                <p className={styles.difference}>
                                  {`±${Math.abs(
                                    parseFloat(
                                      props.productInfo.your_current_price.replace(
                                        '$',
                                        ''
                                      )
                                    ) -
                                      parseFloat(
                                        lowest?.price?.replace('$', '')
                                      )
                                  ).toFixed(2)}`}
                                </p>
                              </Flex>
                            </ColGrid>
                          </Block>

                          <Block truncate>
                            <ColGrid
                              numCols={3}
                              numColsSm={3}
                              numColsMd={3}
                              numColsLg={3}
                            >
                              <Col
                                numColSpan={2}
                                numColSpanSm={2}
                                numColSpanMd={2}
                                numColSpanLg={2}
                              >
                                <Block>
                                  <p className={styles.highest}>Highest</p>

                                  <p className={styles.highestPrice}>
                                    {highest?.price && toDec2(highest.price)}
                                  </p>
                                  <p className={styles.place}>
                                   
                                    
                                    {highest.store && `@ ${
                                      isDemo === 'true'
                                        ? demoNameFix(highest.store)
                                        : highest.store
                                    }`}
                                  </p>
                                </Block>
                              </Col>
                              <Flex
                                alignItems="items-center"
                                justifyContent="justify-end"
                              >
                                <p className={styles.difference}>
                                  {`±${Math.abs(
                                    parseFloat(
                                      highest?.price?.replace('$', '')
                                    ) -
                                      parseFloat(
                                        props.productInfo.your_current_price.replace(
                                          '$',
                                          ''
                                        )
                                      )
                                  ).toFixed(2)}`}
                                </p>
                              </Flex>
                            </ColGrid>
                          </Block>
                        </ColGrid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sx={{ mt: '25px' }}>
                  <p
                    className={styles.recentChange}
                    style={{ textAlign: 'left', marginLeft: '15px' }}
                  >
                    Recent Changes:
                  </p>
                  <TableContainer
                    sx={{
                      height: 'auto',
                      marginBottom: '15px',
                      maxHeight: 180,
                      overflowY: 'auto',
                    }}
                  >
                    <Table stickyHeader aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ paddingBottom: '2px' }}>
                            <p className={styles.productHead}>Other Stores</p>
                          </TableCell>
                          <TableCell sx={{ paddingBottom: '2px' }} align="left">
                            <p className={styles.productHead}>Old Price:</p>
                          </TableCell>
                          <TableCell sx={{ paddingBottom: '2px' }} align="left">
                            <p className={styles.productHead}>New Price:</p>
                          </TableCell>
                          <TableCell sx={{ paddingBottom: '2px' }} align="left">
                            <p className={styles.productHead}>Date:</p>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {recentProductChanges?.length === 0 && (
                          <TableRow>
                            <TableCell colSpan={9} sx={{ textAlign: 'center' }}>
                              <NoDataMessage>No data to show</NoDataMessage>
                            </TableCell>
                          </TableRow>
                        )}
                        {recentProductChanges.map((item, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell>
                              <p className={classes.product}>
                                { isDemo === 'true' ?  demoNameFix(item.productOwner): item.productOwner}
                                
                              </p>
                            </TableCell>

                            <TableCell align="left">
                              <p className={classes.product}>
                                {item.oldPrice === undefined
                                  ? '_'
                                  : toDec2(item.oldPrice)}
                              </p>
                            </TableCell>
                            <TableCell align="left">
                              <p className={styles.product}>
                                {toDec2(item.newPrice)}
                              </p>
                            </TableCell>
                            <TableCell align="left">
                              <Stack>
                                <DateTooltip
                                  date={item.changeDate.substring(0, 10)}
                                />
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </CardContent>
          </>
        )}
      </Card>
    </Backdrop>
  );
}
