import styles from './CompanyDetails.module.css';
import { Button, Grid, Paper, TextField } from '@mui/material';
import OptionSelect from './OptionSelect';
import WebURL from '../assets/WebURL.svg';
import ApiKey from '../assets/ApiKey.svg';
import Other from '../assets/Other.svg';
import { useFormik } from 'formik';
import * as yup from 'yup';
import FileUploader from './FileUploader';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  setFileError,
  setProdGatheringMethod,
  setFile,
  setFileTypeError,
  setIsUpload,
} from '../redux/slices/companyDetailsSlice';

export default function CompanyDetails() {
  const { file } = useSelector((state) => state.companyDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validationSchema = yup.object().shape({
    option: yup.number().required('Select an option').min(0).max(2),
    new: yup.boolean(),

    websiteURL: yup.string().when('option', {
      is: 0,
      then: yup
        .string()
        .url('Enter a valid URL')
        .required('Website URL is required'),
    }),

    apiURL: yup.string().when('option', {
      is: 1,
      then: yup
        .string()
        .url('Invalid API address')
        .required('API address is required'),
    }),

    APIKey: yup.string().when('option', {
      is: 1,
      then: yup.string().required('API key is required'),
    }),
  });

  const formik = useFormik({
    initialValues: {
      websiteURL: '',
      apiURL: '',
      APIKey: '',
      option: '',
      new: false,
    },
    validationSchema,
    onSubmit: (values) => {
      if (values.option === '0') {
        const form = {
          method: 'websiteURL',
          url: (new URL(values.websiteURL).origin).toLowerCase(),
        };
        dispatch(setProdGatheringMethod(form));
        navigate('/industry-selection');
      } else if (values.option === '1') {
        const form = {
          method: 'apiKey',
          apiURL: values.apiURL,
          apiKey: values.APIKey,
        };
        dispatch(setProdGatheringMethod(form));
        navigate('/industry-selection');
      } else if (values.option === '2') {
        if (file.name !== undefined) {
          const form = {
            method: 'other',
            file: file,
          };
          dispatch(setProdGatheringMethod(form));
          navigate('/industry-selection');
        } else {
          dispatch(setFileError(true));
        }
      }
    },
  });


  function handleChange(event) {
    dispatch(setFile({}));
    dispatch(setIsUpload(false));
    dispatch(setFileError(false));
    dispatch(setFileTypeError(false));
    formik.resetForm();
    formik.handleChange(event);
  }

  return (
    <Grid container justifyContent='center'>
      <Paper
        elevation={3}
        sm={12}
        sx={{ width: '100%', maxWidth: '600px', padding: '1em' }}
      >
        <form className={styles.formClass} onSubmit={formik.handleSubmit}>
          <Grid container sx={{ position: 'relative' }}>
            <Grid item xs={12} mb={2} mt={3}>
              <p className={styles.heading}>Product Details Gathering</p>
              <p className={styles.subHeading}>
                Select how you will provide product details.
              </p>
            </Grid>

            <Grid item sx={{cursor: 'pointer'}} xs={12} ml={1.3} mr={1.3} mt={1} sm={3.5} onClick={() => handleChange({target: {id: 'option',value: '0'}})}>
                <OptionSelect
                  isChecked={formik.values.option === '0'}
                  handleChange={formik.handleChange}
                  reset={formik.resetForm}
                  img={WebURL}
                  caption={'Website URL'}
                  value={0}
                />
            </Grid>

            <Grid item sx={{cursor: 'pointer'}} xs={12} ml={1.3} mr={1.3} mt={1} sm={3.6} onClick={() => handleChange({target: {id: 'option',value: '1'}})}>
              <OptionSelect
                isChecked={formik.values.option === '1'}
                handleChange={formik.handleChange}
                reset={formik.resetForm}
                img={ApiKey}
                caption={'API Key'}
                value={1}
              />
            </Grid>

            <Grid item sx={{cursor: 'pointer'}} xs={12} ml={1.3} mr={1.3} mt={1} sm={3.5} onClick={() => handleChange({target: {id: 'option',value: '2'}})}>
              <OptionSelect
                isChecked={formik.values.option === '2'}
                handleChange={formik.handleChange}
                reset={formik.resetForm}
                img={Other}
                caption={'Other'}
                value={2}
              />
            </Grid>

            <Grid item  xs={12} m={1.3} mt={2}>
              <Grid container>
                <Grid item xs={12} hidden={formik.values.option !== '0'}>
                  <TextField
                    id='websiteURL'
                    inputProps={{
                      style: {
                        fontFamily: 'Rubik',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '16px',
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontFamily: 'Rubik',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '16px',
                      },
                    }}
                    fullWidth
                    label='Website URL'
                    error={
                      formik.touched.websiteURL &&
                      Boolean(formik.errors.websiteURL)
                    }
                    helperText={
                      formik.touched.websiteURL && formik.errors.websiteURL
                    }
                    variant='standard'
                    value={formik.values.websiteURL}
                    onChange={formik.handleChange}
                  />
                </Grid>

                <Grid item xs={12} hidden={formik.values.option !== '1'}>
                  <TextField
                    id='apiURL'
                    inputProps={{
                      style: {
                        fontFamily: 'Rubik',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '16px',
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontFamily: 'Rubik',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '16px',
                      },
                    }}
                    fullWidth
                    label='API Address'
                    variant='standard'
                    value={formik.values.apiURL}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.apiURL && Boolean(formik.errors.apiURL)
                    }
                    helperText={formik.touched.apiURL && formik.errors.apiURL}
                  />
                </Grid>

                <Grid item xs={12} hidden={formik.values.option !== '1'}>
                  <TextField
                    id='APIKey'
                    inputProps={{
                      style: {
                        fontFamily: 'Rubik',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '16px',
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontFamily: 'Rubik',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '16px',
                      },
                    }}
                    fullWidth
                    label='API Key'
                    variant='standard'
                    value={formik.values.APIKey}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.APIKey && Boolean(formik.errors.APIKey)
                    }
                    helperText={formik.touched.APIKey && formik.errors.APIKey}
                  />
                </Grid>

                <Grid item xs={12} hidden={formik.values.option !== '2'}>
                  <FileUploader maxFiles={1} maxFileSize={20970000} />
                </Grid>

                <Grid item xs={6} sm={8} md={9}></Grid>

                <Grid item xs={6} sm={4} md={3} mb={1}>
                  <Button
                    type='submit'
                    disabled={formik.values.option === ''}
                    fullWidth
                    variant='contained'
                    sx={{
                      mt: 3,
                      backgroundColor: '#399700',
                      borderRadius: '5px',
                      fontFamily: 'Rubik',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '16px',
                      lineHeight: '14px',
                      paddingTop: '16px',
                      paddingBottom: '16px',
                      textTransform: 'capitalize',
                      '&:hover': {
                        backgroundColor: '#399700',
                        opacity: 0.85,
                      },
                    }}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
}
