import { useDroppable } from '@dnd-kit/core';
import { Grid } from '@mui/material';

export default function Droppable(props) {

  const { isOver, setNodeRef, over } = useDroppable({
    id: props.id,
    data: {
      accepts: [props.type]
    },
    dissabled: props.dissabled
  });
  const style = {
    color: '#66b6d2',
    border: over ? '3px solid' : '',
    borderRadius: '8px',
    minHeight: over ? (props.type === 'sm' ? '690px' : '350px') : '0px',
    backgroundColor: isOver ? '#e9f5f9' : '#ffffff'
  };


  return (
    <Grid container ref={setNodeRef} style={style}>
      {props.children}
    </Grid>
  );
}