import { Card, ColGrid } from '@tremor/react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import api from '../api/api';
import { Grid } from '@mui/material';
import styles from './PriceDropPage.module.css';
import NoDataMessage from '../components/NoDataMessage';
import SkeletonDisplay from '../components/SkeletonDisplay';

export default function PriceDropPage() {
  const [isLoaded, setIsLoaded] = useState(true);
  const [initialLoad, setInitialLoad] = useState(false);
  const [selectedTime, setSelectedTime] = useState('');
  const [priceDropTableData, setPriceDropTableData] = useState([]);

  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    setInitialLoad(true);
    async function getPriceDropDetails() {
      const catList = ['All'];
      const { data } = await api.get('/price_drops', {
        params: { timeframe: 'month' },
      });

      // console.log('Data:- ' , data);

      data.map((val) => {
        if (!catList.includes(val.category)) {
          catList.push(val.category);
        }
      });

      const neededData = data.filter((prod) => {
        if (prod.change_type === 'UP' || prod.change_type === 'DOWN') {
          return true;
        }
      });

      if (neededData.length > 5) {
        const top5Data = neededData.splice(0, 5);
        setPriceDropTableData(top5Data);
      } else if (neededData.length <= 5) {
        setPriceDropTableData(neededData);
      }
      setCategoryList(catList);
    }

    const timeId = setTimeout(() => {
      console.log('running after 5 seconds');
      getPriceDropDetails();
      setInitialLoad(false);
    }, 5000);

    return () => clearTimeout(timeId);
  }, []);

  return (
    <>
      <div className={styles.mainDiv}>
        {initialLoad ? (
          <SkeletonDisplay />
        ) : (
          <Grid
            container
            direction='column'
            sx={{ height: '100%', py: '24px' }}
          >
            <Grid item container>
              <div>
                <p className={styles.tableTopic}>Top 5 Price Drops</p>
              </div>
            </Grid>
            <Grid item container>
              <TableContainer
                component={Paper}
                sx={{ mt: { xs: '10px', md: '30px' } }}
              >
                <Table
                  sx={{
                    // minWidth: 650,
                    height: '650px',
                  }}
                  aria-label='simple table'
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align='left'>
                        <p className={styles.tableheader}>Product: </p>
                      </TableCell>
                      <TableCell>
                        <p className={styles.tableheader}>Competitor Price:</p>
                      </TableCell>
                      <TableCell align='left'>
                        <p className={styles.tableheader}>Your Price:</p>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ backgroundColor: '#F9FBFA' }}>
                    {isLoaded ? (
                      <>
                        {priceDropTableData?.length === 0 && (
                          <>
                            <TableRow>
                              <TableCell
                                colSpan={3}
                                sx={{ textAlign: 'center' }}
                              >
                                <NoDataMessage>No data to show</NoDataMessage>
                              </TableCell>
                            </TableRow>
                          </>
                        )}
                        {priceDropTableData.map((row) => (
                          <TableRow
                            key={`${row.name}+${row.detected_at}`}
                            sx={{
                              height: '65px',
                            }}
                          >
                            <TableCell>
                              <p className={styles.product}>{row.name}</p>
                              <span className={styles.shop}>
                                {row.change_details.store}
                              </span>
                            </TableCell>
                            <TableCell>
                              <ColGrid numCols={2}>
                                <p className={styles.cPrice}>
                                  {row.change_details.price}
                                </p>
                                <ColGrid numCols={2}>
                                  {!row.change_details.hasOwnProperty(
                                    'previous_price'
                                  ) ||
                                    row.change_details.previous_price === null ? (
                                    <p className={styles.pCompPriceDifference}>
                                      NaN
                                    </p>
                                  ) : (
                                    <>
                                      {row.change_details.price >
                                        row.change_details.previous_price ? (
                                        <p
                                          className={`${styles.pCompPriceDifference} ${styles.pCompPriceDifferenceRed}`}
                                        >
                                          {Math.abs(
                                            parseFloat(
                                              row.change_details.price.replace(
                                                '$',
                                                ''
                                              )
                                            ) -
                                            parseFloat(
                                              row.change_details.previous_price.replace(
                                                '$',
                                                ''
                                              )
                                            )
                                          ).toFixed(2)}
                                          &#8593;
                                        </p>
                                      ) : (
                                        <p
                                          className={`${styles.pCompPriceDifference} ${styles.pCompPriceDifferenceGreen}`}
                                        >
                                          {Math.abs(
                                            parseFloat(
                                              row.change_details.previous_price.replace(
                                                '$',
                                                ''
                                              )
                                            ) -
                                            parseFloat(
                                              row.change_details.price.replace(
                                                '$',
                                                ''
                                              )
                                            )
                                          ).toFixed(2)}
                                          &#8595;
                                        </p>
                                      )}
                                    </>
                                  )}
                                </ColGrid>
                              </ColGrid>
                            </TableCell>
                            <TableCell>
                              <ColGrid numCols={2}>
                                <p className={styles.yourPrice}>
                                  {row.your_current_price}
                                </p>
                                <ColGrid numCols={2}>
                                  {!row.hasOwnProperty('your_current_price') ||
                                    !row.change_details.hasOwnProperty('price') ||
                                    row.your_current_price ===
                                    row.change_details.price ? (
                                    <p className={styles.pDifferenceZero}>0</p>
                                  ) : (
                                    <>
                                      {row.your_current_price >
                                        row.change_details.price ? (
                                        <p className={styles.pDifference}>
                                          +
                                          {Math.abs(
                                            parseFloat(
                                              row.your_current_price.replace(
                                                '$',
                                                ''
                                              )
                                            ) -
                                            parseFloat(
                                              row.change_details.price.replace(
                                                '$',
                                                ''
                                              )
                                            )
                                          ).toFixed(2)}
                                        </p>
                                      ) : (
                                        <p className={styles.pDifference}>
                                          -
                                          {Math.abs(
                                            parseFloat(
                                              row.change_details.price.replace(
                                                '$',
                                                ''
                                              )
                                            ) -
                                            parseFloat(
                                              row.your_current_price.replace(
                                                '$',
                                                ''
                                              )
                                            )
                                          ).toFixed(2)}
                                        </p>
                                      )}
                                    </>
                                  )}
                                </ColGrid>
                              </ColGrid>
                            </TableCell>
                          </TableRow>
                        ))}
                        {priceDropTableData?.length <= 4 &&
                          priceDropTableData?.length !== 0 && (
                            <TableRow>
                              <TableCell
                                colSpan={3}
                                sx={{ textAlign: 'center', padding: 0 }}
                              >
                                <NoDataMessage>
                                  No more price drops to show
                                </NoDataMessage>
                              </TableCell>
                            </TableRow>
                          )}
                      </>
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={3}
                          sx={{ textAlign: 'center', padding: 0 }}
                        >
                          <CircularProgress
                            size='6rem'
                            style={{ color: 'grey' }}
                          />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
}
