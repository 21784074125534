import { Grid, Stack, Pagination } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Card } from '@tremor/react';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ViewAll from '../assets/ViewAll.svg';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {
  setPriceChangeTableData,
  setIsLoadingPriceChange,
  setSelectedCategoryPriceChange,
  setSelectedTimePriceChange,
  setSelectedProductPriceChange,
  setSelectedChangePriceChange,
} from '../redux/slices/priceChangeTableSlice';

import TimeButtonGroup from './Buttons/TimeButtonGroup';
import CustomPopover from './CustomPopover';
import DropBox from './DropBox';
import NoDataMessage from './NoDataMessage';
import styles from './PriceChangeTable.module.css';
import { useEffect, useState } from 'react';
import API from '../api/api';
import demoNameFix from '../util/DemoNameFix';
import ItemAltImage from '../assets/productItemAlt.png';


function PriceChangeTable() {
  const priceChangeList = ['UP', 'DOWN'];
  const dispatch = useDispatch();
  const {
    selectedCategoryPriceChange,
    selectedTimePriceChange,
    selectedProductPriceChange,
    selectedChangePriceChange,
    priceChangeTableData,
    isLoadingPriceChange,
  } = useSelector((state) => state.priceChangeTable);
  const { isEdit } = useSelector((state) => state.app);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [totalC, setTotalC] = useState(0);
  const [dataLength, setDataLength] = useState(0);
  const { isDemo } = useSelector((state) => state.demo);
  const { categoryList, productList } = useSelector((state) => state.home);

  const categoryComboBoxChange = (event) => {
    // event.target.value === 'All'
    //   ? dispatch(setSelectedCategoryPriceChange(''))
    //   : dispatch(setSelectedCategoryPriceChange(event.target.value));

    if (
      event.target.value !== selectedCategoryPriceChange &&
      event.target.value !== ''
    ) {
      dispatch(setSelectedProductPriceChange({}));
    }
    dispatch(setSelectedCategoryPriceChange(event.target.value));
  };

  const priceDropComboBoxChange = (event) => {
    event.target.value === 'All'
      ? dispatch(setSelectedChangePriceChange(''))
      : dispatch(setSelectedChangePriceChange(event.target.value));
  };
  const productComboBoxChange = (event) => {
    event === 'all_index'
    ? dispatch(setSelectedProductPriceChange({}))
    : dispatch(setSelectedProductPriceChange( productList.filter((product) => product._id === event)[0]));
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const timeframeChange = (event) => {
    switch (event.target.value) {
      case 'week':
        dispatch(setSelectedTimePriceChange('week'));
        break;
      case 'month':
        dispatch(setSelectedTimePriceChange('month'));
        break;
      case 'quarter':
        dispatch(setSelectedTimePriceChange('quarter'));
        break;
      case 'year':
        dispatch(setSelectedTimePriceChange('year'));
        break;
      default:
        dispatch(setSelectedTimePriceChange(null));
    }
  };

  useEffect(() => {
    dispatch(setIsLoadingPriceChange(false));
    async function getRecentChangeTableData() {
      dispatch(setPriceChangeTableData([]));
      dispatch(setIsLoadingPriceChange(true));
      const {
        data: { finalDataStr, totalCount },
      } = await API.get('products/recentPriceChanges/', {
        params: {
          timePeriod: selectedTimePriceChange,
          change: selectedChangePriceChange,
          category: selectedCategoryPriceChange,
          product: selectedProductPriceChange.productName ?? '',
          limit: rowsPerPage,
          offset: (page - 1) * rowsPerPage,
        },
      });

      dispatch(setIsLoadingPriceChange(false));
      setTotalC(totalCount);
      dispatch(setPriceChangeTableData(finalDataStr));
      setDataLength(finalDataStr.length);
    }

    if (!isEdit) {
      getRecentChangeTableData();
    }
  }, [
    selectedTimePriceChange,
    selectedChangePriceChange,
    selectedCategoryPriceChange,
    selectedProductPriceChange,
    page,
    rowsPerPage,
  ]);

  return (
    <Card>
      <Grid container direction="column">
        <Grid item container mb="20px">
          <Grid item xs={8} textAlign="left">
            <div>
              <p className={styles.tableTopic}>Recent Price Changes:</p>
            </div>
          </Grid>
          <Grid item xs={4} textAlign="right">
            <Link to="/priceChange">
              <div style={{ float: 'right' }}>
                <motion.img src={ViewAll} alt="" whileHover={{ scale: 1.2 }} />
              </div>
            </Link>
          </Grid>
        </Grid>
        <Grid item container columnSpacing={2} rowSpacing={2}>
          <Grid
            item
            container
            xs={12}
            md={5}
            textAlign="left"
            columnSpacing={1}
          >
            <Grid item xs={4}>
              <DropBox
                title="Sort By Price Change: "
                categoryList={['All', ...priceChangeList]}
                onChange={priceDropComboBoxChange}
                value={selectedChangePriceChange}
              />
            </Grid>
            <Grid item xs={4}>
              <DropBox
                title="Sort By Category: "
                categoryList={categoryList}
                onChange={categoryComboBoxChange}
                value={selectedCategoryPriceChange}
              />
            </Grid>
            <Grid item xs={4}>
              <DropBox
                title="Sort By Product:  "
                categoryList={['All',...productList
                  .filter((product) => {
                    return (
                      product.category === selectedCategoryPriceChange ||
                      selectedCategoryPriceChange === ''
                    );
                  })
                  .map(
                    (product) =>
                      product.productName +
                      (product.productDetail
                        ? ` | ${product.productDetail}`
                        : '')
                  )]}
                getIndex={productComboBoxChange}
                indexList={['all_index',...productList
                  .filter((product) => {
                    return (
                      product.category === selectedCategoryPriceChange ||
                      selectedCategoryPriceChange === ''
                    );
                  })
                  .map((product) => product._id)]}
                value={
                  selectedProductPriceChange?.productName
                    ? selectedProductPriceChange.productName +
                      (selectedProductPriceChange.productDetail
                        ? ` | ${selectedProductPriceChange.productDetail}`
                        : '')
                    : ''
                }
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            sx={{ textAlign: { xs: 'left', md: 'right' } }}
          >
            <TimeButtonGroup
              onClick={timeframeChange}
              selectedTFrame={selectedTimePriceChange}
            />
          </Grid>
        </Grid>

        <TableContainer
          component={Paper}
          sx={{ mt: { xs: '10px', md: '30px' } }}
        >
          <Table
            sx={{
              minWidth: 650,
              height: '100%',
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <p className={styles.tableheader}>Store</p>
                </TableCell>
                <TableCell>
                  <p className={styles.tableheader}>Product</p>
                </TableCell>
                <TableCell align="center">
                  <p className={styles.tableheader}>Store Discount</p>
                </TableCell>
                <TableCell align="center">
                  <p className={styles.tableheader}>New Price</p>
                </TableCell>
                <TableCell align="center">
                  <p className={styles.tableheader}>Market Average</p>
                </TableCell>
                <TableCell align="center">
                  <p className={styles.tableheader}>Your Discount</p>
                </TableCell>
                <TableCell align="center">
                  <p className={styles.tableheader}>Your Price</p>
                </TableCell>
                <TableCell align="center">
                  <p className={styles.tableheader}>Difference</p>
                </TableCell>
                <TableCell align="center" />
              </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: '#F9FBFA' }}>
              {isLoadingPriceChange && (
                <TableRow>
                  <TableCell colSpan={9} sx={{ textAlign: 'center' }}>
                    <CircularProgress size="2rem" style={{ color: 'green' }} />
                  </TableCell>
                </TableRow>
              )}

              {priceChangeTableData?.map((row, index) => (
                <TableRow
                  key={`${row.pid}+${row.name}+${row.change_type}+${index}`}
                >
                  <TableCell align="center">
                    <p className={`${styles.prodTableContent}`}>
                      {isDemo === 'true'
                        ? demoNameFix(row.change_details.store)
                        : row.change_details.store}
                    </p>
                  </TableCell>
                  <TableCell align="left">
                    <Stack direction="row" spacing={1}>
                      <img
                        src={row.image.includes('https') || row.image.includes('http') ? row.image  : ItemAltImage}
                        alt=""
                        style={{ width: '50px', height: '50px' }}
                      />

                      <Stack direction="column">
                        <p className={styles.itemName}>{row.name}</p>
                        {/* <span className={styles.itemSubName}>145g</span> */}
                      </Stack>
                    </Stack>
                  </TableCell>
                  <TableCell align="center">
                    {row.storeDiscount === 0 || row.storeDiscount === null ? (
                      <p
                        className={`${styles.prodTableContent} ${styles.prodTableContentOpacity}`}
                      >
                        _
                      </p>
                    ) : (
                      <p
                        className={`${styles.prodTableContent} ${styles.prodTableContentOpacity}`}
                      >
                        {row.storeDiscount === null ||
                        !row.hasOwnProperty('storeDiscount')
                          ? '_'
                          : `${row.storeDiscount}%`}
                      </p>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Stack
                      spacing={1}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-around"
                    >
                      <p
                        className={`${styles.prodTableContent} ${styles.prodTableContentOpacity}`}
                      >
                        {row.change_details.price}
                      </p>

                      <>
                        {row.change_details.price >
                        row.change_details.previous_price ? (
                          <p
                            className={`${styles.pDifference} ${styles.pDifferenceRed}`}
                          >
                            {Math.abs(
                              parseFloat(
                                row.change_details.price.replace('$', '')
                              ) -
                                parseFloat(
                                  row.change_details.previous_price.replace(
                                    '$',
                                    ''
                                  )
                                )
                            ).toFixed(2)}
                            &#8593;
                          </p>
                        ) : (
                          <p
                            className={`${styles.pDifference} ${styles.pDifferenceGreen}`}
                          >
                            {Math.abs(
                              parseFloat(
                                row.change_details.previous_price.replace(
                                  '$',
                                  ''
                                )
                              ) -
                                parseFloat(
                                  row.change_details.price.replace('$', '')
                                )
                            ).toFixed(2)}
                            &#8595;
                          </p>
                        )}
                      </>
                    </Stack>
                  </TableCell>
                  <TableCell align="center">
                    <p
                      className={`${styles.prodTableContent} ${styles.prodTableContentOpacity}`}
                    >
                      _
                    </p>
                  </TableCell>
                  <TableCell align="center">
                    {row.yourDiscount === 0 ? (
                      <p
                        className={`${styles.prodTableContent} ${styles.prodTableContentOpacity}`}
                      >
                        _
                      </p>
                    ) : (
                      <p
                        className={`${styles.prodTableContent} ${styles.prodTableContentOpacity}`}
                      >
                        {row.yourDiscount === null ||
                        !row.hasOwnProperty('yourDiscount')
                          ? '_'
                          : `${row.yourDiscount}%`}
                      </p>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <p
                      className={`${styles.prodTableContent} ${styles.prodTableContentOpacity}`}
                    >
                      {row.your_current_price}
                    </p>
                  </TableCell>
                  <TableCell align="center">
                    <p
                      className={`${styles.prodTableContent} ${styles.prodTableContentOpacity}`}
                    >
                      ±$
                      {Math.abs(
                        parseFloat(row.your_current_price.replace('$', '')) -
                          parseFloat(row.change_details.price.replace('$', ''))
                      ).toFixed(2)}
                    </p>
                  </TableCell>
                  <TableCell align="center">
                    <Stack>
                      <CustomPopover
                        id={index}
                        productInfo={row}
                        notification={row.notification_status}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
              {dataLength === 0 && !isLoadingPriceChange && (
                <TableRow>
                  <TableCell colSpan={9} sx={{ textAlign: 'center' }}>
                    <NoDataMessage>No data to show</NoDataMessage>
                  </TableCell>
                </TableRow>
              )}
              {dataLength !== 0 &&
                dataLength !== rowsPerPage &&
                !isLoadingPriceChange && (
                  <TableRow>
                    <TableCell colSpan={9} sx={{ textAlign: 'center' }}>
                      <NoDataMessage>
                        No more price changes to show
                      </NoDataMessage>
                    </TableCell>
                  </TableRow>
                )}
            </TableBody>
          </Table>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            p="16px"
          >
            <div>
              <Pagination
                count={Math.ceil(totalC / rowsPerPage)}
                page={page}
                onChange={handleChangePage}
                color="success"
                size="medium"
                showFirstButton
                showLastButton
              />
            </div>
            <div>
              <Select
                value={rowsPerPage}
                onChange={handleChangeRowsPerPage}
                label="Rows per page"
                variant="standard"
                size="small"
              >
                <MenuItem value={3}>3 per page</MenuItem>
                <MenuItem value={6}>6 per page</MenuItem>
                <MenuItem value={9}>9 per page</MenuItem>
              </Select>
            </div>
          </Stack>
        </TableContainer>
      </Grid>
    </Card>
  );
}

export default PriceChangeTable;
