import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import SearchIcon from '@mui/icons-material/Search';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Grid, Input, Stack } from '@mui/material';
import SideBar from './SideBar';
import Logo from '../assets/Logo.svg';
import styles from './Header.module.css';
import { Link, useNavigate } from 'react-router-dom';
import PopoverComponent from './PopoverComponent';
import { useDispatch, useSelector } from 'react-redux';
import { setIsEdit, setSettingsPopoverOptions } from '../redux/slices/appSlice';
import { Auth } from 'aws-amplify';
import { revertAll } from '../util/reverAll';
import Cookies from 'js-cookie';
import { setLoginStatus, userLogout } from '../redux/slices/authSlice';

export function removeCookies() {
  Object.keys(Cookies.get()).forEach(function (cookieName) {
    var neededAttributes = {};
    Cookies.remove(cookieName, neededAttributes);
  });
}

export default function Header() {
  const { isEdit, settingsPopoverOptions } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleSettingsActions(key) {
    if (key === 'Admin') {
      return handleAdminOnClick();
    } else if (key === 'Edit Widgets') {
      return handleEditWidgetsOnClick();
    } else if (key === 'Logout') {
      return handleLogoutOnClick();
    }
  }

  function handleAdminOnClick() {
    if (isEdit) {
      dispatch(setIsEdit(false));
      dispatch(setSettingsPopoverOptions(['Admin', 'Edit Widgets', 'Logout']));
    }
    navigate('/admin');
  }

  function handleEditWidgetsOnClick() {
    dispatch(setIsEdit(true));
    dispatch(setSettingsPopoverOptions(['Admin', 'Logout']));
  }

  async function handleLogoutOnClick() {
    try {
      sessionStorage.clear();
      localStorage.clear();
      removeCookies();
      dispatch(setLoginStatus('UNAUTHENTICATED'));
      await Auth.signOut({ global: true });
      dispatch(revertAll);
      navigate('/');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }



  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position='relative'
        sx={{ width: '100%', backgroundColor: '#399700' }}
      >
        <Toolbar
          sx={{
            marginLeft: { xs: '0', md: '20px' },
            marginRight: { xs: '0', md: '20px' },
            paddingTop: '10px',
            paddingBottom: '15px',
          }}
        >
          <Grid container alignItems='center'>
            <Grid item xs={6} sm={6} md={6} container justifyContent='start'>
              <Link to='/home'>
                <img src={Logo} alt='logo' />
              </Link>
            </Grid>
            <Grid item xs={6} sm={6} md={6} container justifyContent='flex-end'>
              <Stack direction='row' alignItems='center'>
                <Input
                  className={styles.searchText}
                  variant='standard'
                  label='Search for Product or Competitor...'
                  placeholder='Search for Product or Competitor...'
                  sx={{
                    input: { color: 'white' },
                    '& .MuiInputLabel-root': { color: 'white' },
                    '& .MuiOutlinedInput-root': {
                      '& > fieldset': { borderColor: 'white' },
                    },
                    fontFamily: 'Rubik',
                    width: '270px',
                    display: {
                      xs: 'none',
                      sm: 'block',
                    },
                  }}
                />

                <SearchIcon />

                <SideBar />

                {/* <SettingsOutlinedIcon /> */}
                <PopoverComponent
                  trigger={<SettingsOutlinedIcon />}
                  options={settingsPopoverOptions}
                  keys={settingsPopoverOptions}
                  onClicks={handleSettingsActions}
                />
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
