import PriceDropCard from '../components/PriceDropCard';
import styles from './HomePage.module.css';
import PricePointCard from '../components/PricePointCard';
import AddIcon from '@mui/icons-material/Add';
import MarketRankingCard from '../components/MarketRankingCard';
import PriceChangeTable from '../components/PriceChangeTable';
import Draggable from '../components/Draggable';
import Droppable from '../components/Droppable';
import PopoverComponent from '../components/PopoverComponent';
import { closestCenter, DndContext } from '@dnd-kit/core';
import { Grid, Button, Avatar, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { arrayMove, arraySwap } from '@dnd-kit/sortable';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import { useDispatch, useSelector } from 'react-redux';
import { setIsEdit, setSettingsPopoverOptions } from '../redux/slices/appSlice';
import API from '../api/api';
import authServices from '../services/authServices';
import { Box } from '@mui/system';
import { setCategoryList, setProductList , setNewNotifyCount} from '../redux/slices/homeSlice';
import {
  setSelectedTimePricePoint,
  setSelectedCategoryPricePoint,
  setSelectedProductPricePoint,
} from '../redux/slices/pricePointSlice';
import {
  setPriceTableCategoryList,
  setPriceTableProductList,
} from '../redux/slices/priceChangeTableSlice';
import {
  setSelectedCategoryPriceDrop,
  setSelectedTimePriceDrop,
} from '../redux/slices/priceDropSlice';
import { setIsDemo } from '../redux/slices/demoSlice';
import Cookies from 'js-cookie';
import io from 'socket.io-client';



function TriggerComponent() {
  return (
    <Avatar
      sx={{
        backgroundColor: '#399700',
        zIndex: 5,
      }}
    >
      <AddIcon sx={{ fontSize: '36px', zIndex: 5 }} />
    </Avatar>
  );
}

export default function HomePage() {
  const { currentIndex: carouselIndex, showInteger } = useSelector(
    (state) => state.carousel
  );
  const dispatch = useDispatch();

  const [smDraggables, setSmDraggables] = useState([]);
  const [lgDraggables, setLgDraggables] = useState([]);
  const [deletedSmDraggables, setDeletedSmDraggables] = useState([]);
  const [deletedLgDraggables, setDeletedLgDraggables] = useState([]);
  const [savedWidgets, setSavedWidgets] = useState([]);
  const [isLgDragging, setIsLgDragging] = useState(false);
  const [isSmDragging, setIsSmDragging] = useState(false);
  const [draggingIndex, setDraggingIndex] = useState();
  const [isWindowSmallSize, setIsWindowSmallSize] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { isEdit } = useSelector((state) => state.app);


  useEffect(() => {
    async function getCategories() {
      const productData = await API.get('products/categories/v2');
      const categoryList = [];
      productData.data.data.map((product) => {
        if (!categoryList.includes(product.category)) {
          categoryList.push(product.category);
        }
      });
      dispatch(setCategoryList(['All'].concat(categoryList.sort())));
      dispatch(setProductList(productData.data.data));
    }
  
    getCategories();

    //set isDemo
    const isDemo = Cookies.get('isDemo');
    dispatch(setIsDemo(isDemo));

  }, []);

  // useEffect(() =>{
  //   const socket = io(process.env.REACT_APP_SOCKET_CONNECTION , { reconnection: true,
  //     reconnectionAttempts: 5,
  //     reconnectionDelay: 1000,
  //     reconnectionDelayMax: 5000,
  //     randomizationFactor: 0.5});
  //   const userEmail = Cookies.get('email');

  //   socket.on('connect', () => {
  //     //Connection to the server Log. Can comment
  //     console.log('Connected to server');
    
  //     // listen for notification updates for the current user
  //     socket.emit('listenForNotificationChanges', userEmail); // replace with current user ID
  //   });
    
  //   socket.on('newNotification', (updatedMessage) => {
  //     //Retrieved change
  //     //console.log(`Notification message updated to: ${JSON.stringify(updatedMessage)}`);
  //     dispatch(setNewNotifyCount(updatedMessage));
  //   });
  // },[]);


  useEffect(() => {
    const getUserData = async () => {
      setIsLoading(true);
      dispatch(setSelectedTimePricePoint('week'));
      dispatch(setSelectedCategoryPricePoint(''));
      dispatch(setSelectedTimePriceDrop('today'));
      dispatch(setSelectedCategoryPriceDrop('All'));
      dispatch(setSelectedProductPricePoint({}));
      dispatch(setSelectedCategoryPricePoint(''));
      try {
        const res = await API.get('users/getUser');

        //Setting the notification count
        if(res.data.newNotificationCount > 0){
          dispatch(setNewNotifyCount(res.data.newNotificationCount));
        }

        const sm = res.data.dashboardWidgets.slice(0, -4);
        const lg = res.data.dashboardWidgets.slice(6);
        const removedSm = ['PriceDropCard', 'MarketRankingCard'].filter(
          (widget) => !res.data.dashboardWidgets.includes(widget)
        );
        const removedLg = ['PricePointCard', 'PriceChangeTable'].filter(
          (widget) => !res.data.dashboardWidgets.includes(widget)
        );
        setDeletedSmDraggables(removedSm);
        setDeletedLgDraggables(removedLg);
        reArrangingWidgets(sm, lg, removedSm, removedLg);
        setSavedWidgets([sm, lg, removedSm, removedLg]);
      } catch (e) {
        alert(e);
      }

      setIsLoading(false);
    };
    getUserData();
  }, [Cookies.get('user_token')]);

  useEffect(() => {
    function handleWindowResize() {
      const { innerWidth, innerHeight } = window;
      if (innerWidth < 900) {
        setIsWindowSmallSize(true);
      } else {
        setIsWindowSmallSize(false);
      }
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  //Draggable components
  // Type = sm for small components, lg for large components
  const draggablePriceDrop = (
    <Draggable id="PriceDropCard" type="sm" handleClose={handleClose}>
      <PriceDropCard />
    </Draggable>
  );

  const draggableMarketRanking = (
    <Draggable id="MarketRankingCard" type="sm" handleClose={handleClose}>
      <MarketRankingCard />
    </Draggable>
  );

  const draggablePricePoint = (
    <Draggable id="PricePointCard" type="lg" handleClose={handleClose}>
      <PricePointCard />
    </Draggable>
  );

  const draggablePriceChange = (
    <Draggable id="PriceChangeTable" type="lg" handleClose={handleClose}>
      <PriceChangeTable />
    </Draggable>
  );

  function generateDbWidgetEntry(smList, lgList) {
    const entry = [];
    for (let i = 0; i < 3; i++) {
      if (smList[i * 2] !== null) {
        entry.push(smList[i * 2] + '-L');
      }
      if (smList[i * 2 + 1] !== null) {
        entry.push(smList[i * 2 + 1] + '-R');
      }
      if (lgList[i + 1] !== null) {
        entry.push(lgList[i + 1]);
      }
    }

    return entry;
  }

  //function to save current widget structure
  async function handleSaveWidgets() {
    //todo: save widgets structure in backend database
    setIsLoading(true);

    const savedEntry = savedWidgets[0].concat(savedWidgets[1]);
    if (
      smDraggables
        .concat(lgDraggables)
        .filter((entry, i) => entry !== savedEntry[i]).length === 0
    ) {
      dispatch(setIsEdit(false));
      dispatch(setSettingsPopoverOptions(['Admin', 'Edit Widgets', 'Logout']));
      setIsLoading(false);
      return;
    }
    const form = {
      email: authServices.getCurrentUserEmail(),
      updatedData: {
        dashboardWidgets: generateDbWidgetEntry(smDraggables, lgDraggables),
      },
    };
    try {
      const res = await API.put('users/update', form);
    } catch (e) {
      alert(e);
    }

    const newSavedWidgets = [];
    newSavedWidgets.push(smDraggables.filter(() => true));
    newSavedWidgets.push(lgDraggables.filter(() => true));
    newSavedWidgets.push(deletedSmDraggables.filter(() => true));
    newSavedWidgets.push(deletedLgDraggables.filter(() => true));
    setSavedWidgets(newSavedWidgets);
    dispatch(setIsEdit(false));
    dispatch(setSettingsPopoverOptions(['Admin', 'Edit Widgets', 'Logout']));
    setIsLoading(false);
  }

  //function to cancel edit widgets
  function handleCancelWidgets() {
    setSmDraggables(savedWidgets[0].filter(() => true));
    setLgDraggables(savedWidgets[1].filter(() => true));
    setDeletedSmDraggables(savedWidgets[2].filter(() => true));
    setDeletedLgDraggables(savedWidgets[3].filter(() => true));
    dispatch(setIsEdit(false));
    dispatch(setSettingsPopoverOptions(['Admin', 'Edit Widgets', 'Logout']));
  }

  //Function to rearrange draggable components
  async function reArrangingWidgets(smArray, lgArray, deletedSm, deletedLg) {
    for (let i = 0; i < 2; i++) {
      if (
        (smArray[2 * i] !== null || smArray[2 * i + 1] !== null) &&
        lgArray[i + 1] === null
      ) {
        if (smArray[2 * i] !== null && smArray[2 * (i + 1)] === null) {
          smArray[2 * (i + 1)] = smArray[2 * i];
          smArray[2 * i] = null;
        } else if (
          smArray[2 * i] !== null &&
          smArray[2 * (i + 1) + 1] === null
        ) {
          smArray[2 * (i + 1) + 1] = smArray[2 * i];
          smArray[2 * i] = null;
        }

        if (smArray[2 * i + 1] !== null && smArray[2 * (i + 1)] === null) {
          smArray[2 * (i + 1) + 1] = smArray[2 * i + 1];
          smArray[2 * i + 1] = null;
        } else if (
          smArray[2 * i + 1] !== null &&
          smArray[2 * (i + 1) + 1] === null
        ) {
          smArray[2 * (i + 1) + 1] = smArray[2 * i + 1];
          smArray[2 * i + 1] = null;
        }
      }
    }

    if (deletedLg.length === 0) {
      if (lgArray[3] === null && lgArray[2] === null) {
        lgArray[2] = lgArray[1];
        lgArray[1] = lgArray[0];
        lgArray[0] = null;
        lgArray[3] = null;
        smArray[2] = smArray[0];
        smArray[3] = smArray[1];
        smArray[0] = null;
        smArray[1] = null;
      }

      if (lgArray[0] === null && lgArray[1] === null) {
        lgArray[1] = lgArray[2];
        lgArray[2] = lgArray[3];
        lgArray[0] = null;
        lgArray[3] = null;
        smArray[0] = smArray[2];
        smArray[1] = smArray[3];
        smArray[2] = smArray[4];
        smArray[3] = smArray[5];
        smArray[4] = null;
        smArray[5] = null;
      }

      if (lgArray[3] !== null) {
        lgArray[2] = lgArray[3];
        lgArray[3] = null;
        smArray[2] = smArray[4];
        smArray[3] = smArray[5];
        smArray[4] = null;
        smArray[5] = null;
      }

      if (lgArray[0] !== null) {
        lgArray[1] = lgArray[0];
        lgArray[0] = null;
        smArray[0] = smArray[2];
        smArray[1] = smArray[3];
        smArray[0] = null;
        smArray[1] = null;
      }
    } else if (deletedLg.length === 1) {
      if (lgArray[3] !== null) {
        lgArray[2] = lgArray[3];
        lgArray[3] = null;
        smArray[2] = smArray[4];
        smArray[3] = smArray[5];
        smArray[4] = null;
        smArray[5] = null;
      }

      if (lgArray[0] !== null) {
        lgArray[1] = lgArray[0];
        lgArray[0] = null;
        smArray[0] = smArray[2];
        smArray[1] = smArray[3];
        smArray[0] = null;
        smArray[1] = null;
      }
    }

    const newLgDraggables = lgArray.filter((item) => true);
    const newSmDraggables = smArray.filter((item) => true);

    setSmDraggables(newSmDraggables);
    setLgDraggables(newLgDraggables);
  }

  // const previousItem = () => {
  //   if (carouselIndex > 0) {
  //     dispatch(setCurrentIndex((prevState) => prevState - 1));
  //   }
  // };

  // const nextItem = () => {
  //   if (carouselIndex < 5 - showInteger) {
  //     dispatch(setCurrentIndex((prevState) => prevState + 1));
  //   }
  // };

  async function handleClose(cardId) {
    if (smDraggables.includes(cardId)) {
      const index = smDraggables.indexOf(cardId);
      if (index > -1) {
        const newArray = smDraggables.map((draggable, i) => {
          return draggable === cardId ? null : draggable;
        });
        const newLgArray = lgDraggables.filter((item) => true);
        await setDeletedSmDraggables([...deletedSmDraggables, cardId]);
        reArrangingWidgets(
          newArray,
          newLgArray,
          [...deletedSmDraggables, cardId],
          deletedLgDraggables
        );
      }
    } else {
      const index = lgDraggables.indexOf(cardId);
      if (index > -1) {
        const newArray = lgDraggables.map((draggable, i) => {
          return draggable === cardId ? null : draggable;
        });
        await setDeletedLgDraggables([...deletedLgDraggables, cardId]);
        const newSmArray = smDraggables.filter((item) => true);
        reArrangingWidgets(newSmArray, newArray, deletedSmDraggables, [
          ...deletedLgDraggables,
          cardId,
        ]);
      }
    }
  }

  function getIndexFromSmArray(id) {
    for (let i = 0; i < smDraggables.length; i++) {
      if (smDraggables[i] === id) {
        return i;
      }
    }
    return -1;
  }

  function getIndexFromLgArray(id) {
    for (let i = 0; i < lgDraggables.length; i++) {
      if (lgDraggables[i] === id) {
        return i;
      }
    }
    return -1;
  }

  function getDraggableElemt(id) {
    if (id === 'PriceDropCard') {
      return draggablePriceDrop;
    } else if (id === 'MarketRankingCard') {
      return draggableMarketRanking;
    } else if (id === 'PricePointCard') {
      return draggablePricePoint;
    } else if (id === 'PriceChangeTable') {
      return draggablePriceChange;
    } else if (id === null) {
      return null;
    }
  }

  function handleDragStart(event) {
    if (lgDraggables.includes(event.active.id)) {
      setIsLgDragging(true);
      setDraggingIndex(lgDraggables.indexOf(event.active.id));
    } else {
      setIsSmDragging(true);
    }
  }

  async function handleDragEnd(event) {
    const { active, over } = event;
    if (isWindowSmallSize) {
      if (over === null) {
      }
      if (over.id < 10) {
        const currentId = getIndexFromSmArray(active.id);
        const lgId = getIndexFromLgArray(active.id);

        if (currentId !== over.id && currentId !== -1) {
          if (smDraggables[over.id] === null) {
            const newSmDraggables = arraySwap(smDraggables, currentId, over.id);
            const newLgDraggables = lgDraggables.filter((draggable) => true);
            await reArrangingWidgets(
              newSmDraggables,
              newLgDraggables,
              deletedSmDraggables,
              deletedLgDraggables
            );
          } else {
            const newSmDraggables = arrayMove(smDraggables, currentId, over.id);
            const newLgDraggables = lgDraggables.filter((draggable) => true);
            await reArrangingWidgets(
              newSmDraggables,
              newLgDraggables,
              deletedSmDraggables,
              deletedLgDraggables
            );
          }
        } else if (lgId !== -1) {
          const overId = Math.floor(over.id / 2);

          if (lgId !== overId) {
            if (lgDraggables[overId] === null) {
              const newLgDraggables = arraySwap(lgDraggables, lgId, overId);
              const newSmDraggables = smDraggables.filter((draggable) => true);
              await reArrangingWidgets(
                newSmDraggables,
                newLgDraggables,
                deletedSmDraggables,
                deletedLgDraggables
              );
            } else {
              const newLgDraggables = await arraySwap(
                lgDraggables,
                lgId,
                overId
              );
              const newSmDraggables = await smDraggables.filter(
                (draggable) => true
              );
              await reArrangingWidgets(
                newSmDraggables,
                newLgDraggables,
                deletedSmDraggables,
                deletedLgDraggables
              );
            }
          }
        }
      } else {
        const currentId = getIndexFromLgArray(active.id);
        const smId = getIndexFromSmArray(active.id);

        if (currentId !== over.id - 10 && currentId !== -1) {
          if (lgDraggables[over.id - 10] === null) {
            const newLgDraggables = arraySwap(
              lgDraggables,
              currentId,
              over.id - 10
            );
            const newSmDraggables = smDraggables.filter((draggable) => true);
            await reArrangingWidgets(
              newSmDraggables,
              newLgDraggables,
              deletedSmDraggables,
              deletedLgDraggables
            );
          } else {
            const newLgDraggables = await arrayMove(
              lgDraggables,
              currentId,
              over.id - 10
            );
            const newSmDraggables = await smDraggables.filter(
              (draggable) => true
            );
            await reArrangingWidgets(
              newSmDraggables,
              newLgDraggables,
              deletedSmDraggables,
              deletedLgDraggables
            );
          }
        } else if (smId !== -1) {
          const overId = (over.id - 10) * 2;

          if (smId !== overId) {
            if (smDraggables[overId] === null) {
              const newSmDraggables = arraySwap(smDraggables, smId, overId);
              const newLgDraggables = lgDraggables.filter((draggable) => true);
              await reArrangingWidgets(
                newSmDraggables,
                newLgDraggables,
                deletedSmDraggables,
                deletedLgDraggables
              );
            } else {
              const newSmDraggables = arrayMove(smDraggables, smId, overId);
              const newLgDraggables = lgDraggables.filter((draggable) => true);
              await reArrangingWidgets(
                newSmDraggables,
                newLgDraggables,
                deletedSmDraggables,
                deletedLgDraggables
              );
            }
          }
        }
      }

      setIsLgDragging(false);
      setIsSmDragging(false);
    } else {
      if (over === null) {
      }
      if (over.id < 10) {
        const currentId = getIndexFromSmArray(active.id);

        if (currentId !== over.id && currentId !== -1) {
          if (smDraggables[over.id] === null) {
            const newSmDraggables = arraySwap(smDraggables, currentId, over.id);
            const newLgDraggables = lgDraggables.filter((draggable) => true);
            await reArrangingWidgets(
              newSmDraggables,
              newLgDraggables,
              deletedSmDraggables,
              deletedLgDraggables
            );
          } else {
            const newSmDraggables = arrayMove(smDraggables, currentId, over.id);
            const newLgDraggables = lgDraggables.filter((draggable) => true);
            await reArrangingWidgets(
              newSmDraggables,
              newLgDraggables,
              deletedSmDraggables,
              deletedLgDraggables
            );
          }
        }
      } else {
        const currentId = getIndexFromLgArray(active.id);

        if (currentId !== over.id - 10 && currentId !== -1) {
          if (lgDraggables[over.id - 10] === null) {
            const newLgDraggables = arraySwap(
              lgDraggables,
              currentId,
              over.id - 10
            );
            const newSmDraggables = smDraggables.filter((draggable) => true);
            await reArrangingWidgets(
              newSmDraggables,
              newLgDraggables,
              deletedSmDraggables,
              deletedLgDraggables
            );
          } else {
            const newLgDraggables = await arrayMove(
              lgDraggables,
              currentId,
              over.id - 10
            );
            const newSmDraggables = await smDraggables.filter(
              (draggable) => true
            );
            await reArrangingWidgets(
              newSmDraggables,
              newLgDraggables,
              deletedSmDraggables,
              deletedLgDraggables
            );
          }
        }
      }

      setIsLgDragging(false);
      setIsSmDragging(false);
    }
  }

  // Add draggable component from the deleted list
  function handleAdd(cardId) {
    if (deletedSmDraggables.includes(cardId)) {
      const newArray = deletedSmDraggables.filter((item) => item !== cardId);
      setDeletedSmDraggables(newArray);
      let isAdd = false;
      const newSmDraggables = smDraggables.map((draggable, i) => {
        if (draggable === null && !isAdd) {
          isAdd = true;
          return cardId;
        } else {
          return draggable;
        }
      });
      reArrangingWidgets(
        newSmDraggables,
        lgDraggables,
        newArray,
        deletedLgDraggables
      );
    } else {
      const newArray = deletedLgDraggables.filter((item) => item !== cardId);
      setDeletedLgDraggables(newArray);
      let isAdd = false;
      const newLgDraggables = lgDraggables.map((draggable, i) => {
        if (draggable === null && !isAdd) {
          isAdd = true;
          return cardId;
        } else {
          return draggable;
        }
      });
      reArrangingWidgets(
        smDraggables,
        newLgDraggables,
        deletedSmDraggables,
        newArray
      );
    }
  }

  function HomePageBody() {
    return (
      <>
        <Grid container justifyContent="center">
          {/*What is the use of this part? */}
          {smDraggables[0] === null &&
          smDraggables[1] === null &&
          isLgDragging &&
          draggingIndex === 1 ? null : (
            <Grid item container>
              <Grid
                item
                hidden={!isLgDragging && lgDraggables[0] === null}
                xs={12}
                // mx='auto'
                md={12}
                mt={3}
              >
                <Droppable id={10} key={10} type="lg">
                  {getDraggableElemt(lgDraggables[0])}
                </Droppable>
              </Grid>
            </Grid>
          )}
          {/*What is the use of this part? (END) */}

          {lgDraggables[1] === null ? null : (
            <Grid item container columnSpacing={2}>
              <Grid
                item
                hidden={
                  (isLgDragging && smDraggables[0] === null) ||
                  (smDraggables[0] === null && isWindowSmallSize) ||
                  (smDraggables[0] === null &&
                    !isWindowSmallSize &&
                    smDraggables[1] === null &&
                    !isSmDragging)
                }
                xs={12}
                // mx='auto'
                md={6}
                mt={3}
              >
                <Droppable id={0} key={0} type="sm">
                  {getDraggableElemt(smDraggables[0])}
                </Droppable>
              </Grid>

              <Grid
                item
                hidden={
                  (isLgDragging && smDraggables[1] === null) ||
                  (smDraggables[1] === null && isWindowSmallSize) ||
                  (smDraggables[0] === null &&
                    !isWindowSmallSize >= 900 &&
                    smDraggables[1] === null &&
                    !isSmDragging)
                }
                xs={12}
                // mx='auto'
                md={6}
                mt={3}
              >
                <Droppable id={1} key={1} type="sm">
                  {getDraggableElemt(smDraggables[1])}
                </Droppable>
              </Grid>

              <Grid
                item
                hidden={!isLgDragging || smDraggables[1] !== null}
                xs={12}
                // mx='auto'
                md={6}
                mt={3}
              ></Grid>
            </Grid>
          )}

          <Grid item container>
            <Grid
              item
              hidden={!isLgDragging && lgDraggables[1] === null}
              xs={12}
              // mx='auto'
              md={12}
              mt={3}
            >
              <Droppable id={11} key={11} type="lg">
                {getDraggableElemt(lgDraggables[1])}
              </Droppable>
            </Grid>
          </Grid>

          {lgDraggables[2] === null ? null : (
            <Grid item container columnSpacing={2}>
              <Grid
                item
                hidden={
                  (isLgDragging && smDraggables[2] === null) ||
                  (smDraggables[2] === null && isWindowSmallSize) ||
                  (smDraggables[2] === null &&
                    !isWindowSmallSize &&
                    smDraggables[3] === null &&
                    !isSmDragging)
                }
                xs={12}
                // mx='auto'
                md={6}
                mt={3}
              >
                <Droppable id={2} key={2} type="sm">
                  {getDraggableElemt(smDraggables[2])}
                </Droppable>
              </Grid>

              <Grid
                item
                hidden={!isLgDragging || smDraggables[2] !== null}
                xs={12}
                // mx='auto'
                md={6}
                mt={3}
              ></Grid>

              <Grid
                item
                hidden={
                  (isLgDragging && smDraggables[3] === null) ||
                  (smDraggables[3] === null && isWindowSmallSize) ||
                  (smDraggables[2] === null &&
                    !isWindowSmallSize &&
                    smDraggables[3] === null &&
                    !isSmDragging)
                }
                xs={12}
                // mx='auto'
                md={6}
                mt={3}
              >
                <Droppable id={3} key={3} type="sm">
                  {getDraggableElemt(smDraggables[3])}
                </Droppable>
              </Grid>

              <Grid
                item
                hidden={!isLgDragging || smDraggables[3] !== null}
                xs={12}
                // mx='auto'
                md={6}
                mt={3}
              ></Grid>
            </Grid>
          )}

          <Grid item container>
            <Grid
              item
              hidden={!isLgDragging && lgDraggables[2] === null}
              xs={12}
              mx="auto"
              md={12}
              mt={3}
            >
              <Droppable id={12} key={12} type="lg">
                {getDraggableElemt(lgDraggables[2])}
              </Droppable>
            </Grid>
          </Grid>

          <Grid item container columnSpacing={2}>
            <Grid
              item
              hidden={
                (isLgDragging && smDraggables[4] === null) ||
                (smDraggables[4] === null && isWindowSmallSize) ||
                (smDraggables[4] === null &&
                  !isWindowSmallSize &&
                  smDraggables[5] === null &&
                  !isSmDragging)
              }
              xs={12}
              md={6}
              mt={3}
            >
              <Droppable id={4} key={4} type="sm">
                {getDraggableElemt(smDraggables[4])}
              </Droppable>
            </Grid>

            <Grid
              item
              hidden={!isLgDragging || smDraggables[4] !== null}
              xs={12}
              md={6}
              mt={3}
            ></Grid>

            <Grid
              item
              hidden={
                (isLgDragging && smDraggables[5] === null) ||
                (smDraggables[5] === null && isWindowSmallSize) ||
                (smDraggables[5] === null &&
                  !isWindowSmallSize &&
                  smDraggables[4] === null &&
                  !isSmDragging)
              }
              xs={12}
              md={6}
              mt={3}
            >
              <Droppable id={5} key={5} type="sm">
                {getDraggableElemt(smDraggables[5])}
              </Droppable>
            </Grid>

            <Grid
              item
              hidden={!isLgDragging || smDraggables[5] !== null}
              xs={12}
              md={6}
              mt={3}
            ></Grid>
          </Grid>

          {smDraggables[4] === null &&
          smDraggables[5] === null &&
          isLgDragging &&
          draggingIndex === 2 ? null : (
            <Grid item container>
              <Grid
                item
                hidden={!isLgDragging && lgDraggables[3] === null}
                xs={12}
                md={12}
                mt={3}
              >
                <Droppable id={13} key={13} type="lg">
                  {getDraggableElemt(lgDraggables[3])}
                </Droppable>
              </Grid>
            </Grid>
          )}
        </Grid>
      </>
    );
  }

  return (
    <div className={styles.homeDiv}>
      {isLoading && (
        <Box className={styles.fadedBox}>
          <CircularProgress
            size={80}
            color="inherit"
            sx={{ top: '50%', color: '#81BB00' }}
          />
        </Box>
      )}
      {isEdit ? (
        <DndContext
          onDragEnd={handleDragEnd}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          modifiers={[restrictToWindowEdges]}
        >
          <Grid item container mt={2} xs={12} justifyContent="right">
            <Button
              type="button"
              onClick={handleCancelWidgets}
              variant="contained"
              sx={{
                width: '159px',
                height: '46px',
                marginRight: 1,
                borderRadius: '5px',
                backgroundColor: 'transparent',
                color: '#000000',
                border: '1px solid #000000',
                fontFamily: 'Rubik',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '16px',
                lineHeight: '14px',
                paddingTop: '16px',
                paddingBottom: '16px',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: 'transparent',
                  opacity: 0.85,
                },
              }}
            >
              Cancel
            </Button>
            <Button
              type="button"
              variant="contained"
              onClick={handleSaveWidgets}
              sx={{
                width: '159px',
                height: '46px',
                backgroundColor: '#399700',
                borderRadius: '5px',
                fontFamily: 'Rubik',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '16px',
                lineHeight: '14px',
                paddingTop: '16px',
                paddingBottom: '16px',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#399700',
                  opacity: 0.85,
                },
              }}
            >
              Save Changes
            </Button>
          </Grid>
          <HomePageBody />
          {deletedLgDraggables.length + deletedSmDraggables.length ===
          0 ? null : (
            <Grid
              sx={{
                position: 'sticky',
                align: 'right',
                bottom: '0',
                zIndex: 5,
              }}
              item
              xs={12}
              mt={2}
              paddingBottom={0.5}
              justifyContent="right"
              container
            >
              <PopoverComponent
                trigger={<TriggerComponent />}
                options={deletedSmDraggables
                  .concat(deletedLgDraggables)
                  .map((option) => {
                    const result = option.replace(/([A-Z])/g, ' $1');
                    return result.charAt(0).toUpperCase() + result.slice(1);
                  })}
                keys={deletedSmDraggables.concat(deletedLgDraggables)}
                onClicks={handleAdd}
              />
            </Grid>
          )}
        </DndContext>
      ) : (
        <HomePageBody />
      )}
    </div>
  );
}
