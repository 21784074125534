import React, { useEffect, useState } from 'react';
import styles from './CompetitorSelection.module.css';

import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { Alert, Button, CircularProgress, Grid } from '@mui/material';
import { Field, Formik, Form } from 'formik';
import Logo from '../assets/Logo.svg';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router';
import { CustomChip, CustomTextFieldOne } from '../components/CustomMuiComponents';
import { useDispatch, useSelector } from 'react-redux';
import { addNewCompetitor, removeNewCompetitor, setAllCompetitorsList } from '../redux/slices/competitorSelectionSlice';
import API from '../api/api';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default function AddCompetitor() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectors, setSelectors] = useState({});
  const [dArray, setdArray] = useState([]);
  const [open, setOpen] = useState(false);
  const [competitor, setCompetitor] = useState(null);

  const { allCompetitorsList, selectedCompetitors, newCompetitors, savedCompetitors } = useSelector(
    (state) => state.competitorSelection
  );
  const [errorMsg, setErrorMsg] = useState('');
  const [newCompetitorsList, setNewCompetitors] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    async function getAllCompetitors() {
      const res = await API.get('competitors/');
      dispatch(
        setAllCompetitorsList(
          res.data.data.map((comp) => {
            return {
              id: comp._id + '',
              name: comp.name,
              industry: comp.industry,
              websiteURL: comp.websiteURL,
            };
          })
        )
      );
    }

    getAllCompetitors();
    setIsLoading(false);
  }, []);

  const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || '';

  const changeCase = (s) => {
    const text = s.split(' ');
    let newText = '';
    for (let i = 0; i < text.length; i++) {
      if (i === text.length - 1) {
        newText += capitalize(text[i]);
      } else {
        newText += capitalize(text[i]) + ' ';
      }
    }
    return newText;
  };

  const handleDelete = (data) => {
    setNewCompetitors((prev) => {
      return prev.filter((ins) => ins.name !== data.name);
    });
    dispatch(removeNewCompetitor(data));
  };
  const handleClose = (event, reason) => {
    if (event.target.value === 'confirm') {
      console.log('Triggered this');
      setNewCompetitors((prev) => {
        return [
          ...prev,
          {
            id: competitor.websiteUrl,
            name: competitor.competitorName.toLowerCase().replace(/\s+/g, ' '),
            selectors: selectors,
          },
        ];
      });
    }
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      setOpen(false);
    }
  };
  window.addEventListener('RecieveAdvantoData', function (evt) {
    console.log(evt);
    console.log('Inside React Advanto App');
    setSelectors(evt.detail.data);
    console.log(evt.detail.detailsArray);
    setdArray(evt.detail.detailsArray);
    setOpen(true);
    console.log(evt.detail);
  });
  const validateName = (value) => {
    if (value === '') {
      return 'Competitor Name is Required';
    } else {
      return false;
    }
  };

  const validateUrl = (value) => {
    if (!isValidUrl(value)) {
      return 'Invalid URL';
    } else {
      return false;
    }
  };

  const isValidUrl = (urlString) => {
    var urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // validate fragment locator
    return !!urlPattern.test(urlString);
  };

  return (
    <div className={styles.outer__container}>
      <img src={Logo} width='200px' height='35px' alt='logo' style={{ marginBottom: '50px' }} className={styles.logo} />
      <div className={styles.inner__container}>
        {isLoading && (
          <Box className={styles.fadedBox}>
            <CircularProgress size={80} color='inherit' sx={{ top: '50%', color: '#81BB00' }} />
          </Box>
        )}
        <p className={styles.select__competitor__one}>Add Competitor</p>
        <p className={styles.select__competitor__two}>Add your competitors Name and website URL below.</p>
        <Formik
          initialValues={{ competitorName: '', websiteUrl: '' }}
          onSubmit={async (value, actions) => {
            //Here logic is to add to the database
            if (!validateName(value.competitorName) && !validateUrl(value.websiteUrl)) {
              const url = new URL(value.websiteUrl).origin;
              const duplicateCompetitors = allCompetitorsList.filter((comp) => {
                return comp.websiteURL === url;
              });
              if (duplicateCompetitors.length > 0) {
                setErrorMsg(
                  'URL ' +
                  url +
                  ' is already exists as ' +
                  duplicateCompetitors[0].name +
                  ' under the ' +
                  duplicateCompetitors[0].industry +
                  ' industry'
                );
                setIsError(true);
                return;
              }

              const duplicateNewCompetitors = newCompetitorsList.filter((comp) => {
                return comp.id === url;
              });
              if (duplicateNewCompetitors.length > 0) {
                setErrorMsg('URL ' + url + ' is already exists as ' + duplicateNewCompetitors[0].name);
                setIsError(true);
                return;
              }

              const duplicateSelectedCompetitors = savedCompetitors.concat(newCompetitors).filter((comp) => {
                return comp.id === url;
              });
              if (duplicateSelectedCompetitors.length > 0) {
                setErrorMsg('URL ' + url + ' is already exists as ' + duplicateSelectedCompetitors[0].name);
                setIsError(true);
                return;
              }

              const NameduplicateCompetitors = allCompetitorsList.filter((comp) => {
                return comp.name === value.competitorName.toLowerCase().replace(/\s+/g, ' ');
              });

              if (NameduplicateCompetitors.length > 0) {
                setErrorMsg(
                  'The name ' +
                  NameduplicateCompetitors[0].name.replace(/\s+/g, ' ') +
                  ' is already exsits under the ' +
                  NameduplicateCompetitors[0].industry +
                  ' industry'
                );
                setIsError(true);
                return;
              }

              const NameduplicateNewCompetitors = newCompetitorsList.filter((comp) => {
                return comp.name === value.competitorName.toLowerCase().replace(/\s+/g, ' ');
              });
              if (NameduplicateNewCompetitors.length > 0) {
                setErrorMsg(
                  'The name ' + NameduplicateNewCompetitors[0].name.replace(/\s+/g, ' ') + ' is already exsits.'
                );
                setIsError(true);
                return;
              }

              const NameduplicateSelectedCompetitors = savedCompetitors.concat(newCompetitors).filter((comp) => {
                return comp.name === value.competitorName.toLowerCase().replace(/\s+/g, ' ');
              });
              if (NameduplicateSelectedCompetitors.length > 0) {
                setErrorMsg(
                  'The name ' + NameduplicateSelectedCompetitors[0].name.replace(/\s+/g, ' ') + ' is already exsits.'
                );
                setIsError(true);
                return;
              }

              // pass the extention id
              var editorExtensionId = 'memjkhmcemipeimbgmpgigckcmfobgph';

              setCompetitor(value);
              chrome.runtime.sendMessage(editorExtensionId, { openUrlInEditor: value.websiteUrl }, function (response) {
                if (!response.success) {
                  setCompetitor(null);
                }
              });

              actions.resetForm({
                values: {
                  competitorName: '',
                  websiteUrl: '',
                },
                touched: {
                  competitorName: false,
                  websiteUrl: false,
                },
              });
            }
          }}
        >
          {({ errors, touched }) => (
            <Form onChange={() => setIsError(false)}>
              <Grid container direction='column' justifyContent='center' alignItems='flex-start'>
                {' '}
                <Field name='competitorName' validate={validateName}>
                  {({ field, form }) => (
                    <CustomTextFieldOne
                      {...field}
                      id='competitorName'
                      label='Competitor Name'
                      variant='standard'
                      fullWidth
                    />
                  )}
                </Field>
                {errors.competitorName && touched.competitorName ? (
                  <Alert
                    icon={false}
                    sx={{
                      fontFamily: 'Rubik',
                      fontSize: '12px',
                      marginTop: '5px',
                      padding: '0 10px',
                      height: '35px',
                    }}
                    severity='error'
                  >
                    {errors.competitorName}
                  </Alert>
                ) : null}
                <Field name='websiteUrl' validate={validateUrl}>
                  {({ field, form }) => (
                    <CustomTextFieldOne
                      {...field}
                      id='websiteUrl'
                      label='Competitor Website URL'
                      variant='standard'
                      fullWidth
                    />
                  )}
                </Field>
                {errors.websiteUrl && touched.websiteUrl ? (
                  <Alert
                    icon={false}
                    sx={{
                      fontFamily: 'Rubik',
                      fontSize: '12px',
                      marginTop: '5px',
                      padding: '0 10px',
                      height: '35px',
                    }}
                    severity='error'
                  >
                    {errors.websiteUrl}
                  </Alert>
                ) : null}
              </Grid>

              <Grid item mt={2} hidden={!isError}>
                <Alert
                  severity='error'
                  onClose={() => {
                    setIsError(false);
                  }}
                >
                  {errorMsg}
                </Alert>
              </Grid>

              <Grid container direction='row' justifyContent='flex-end' alignItems='flex-end'>
                {' '}
                <Button
                  style={{ marginTop: '2rem', marginBottom: '30px' }}
                  type='submit'
                  variant='outlined'
                  id='addComBtn'
                  sx={{
                    mt: 3,
                    color: '#399700',
                    borderRadius: '5px',
                    fontFamily: 'Rubik',
                    fontWeight: 500,
                    padding: '10px 50px',
                    textTransform: 'capitalize',
                    border: '1px solid #399700',
                    '&:hover': {
                      borderColor: '#399700',
                      boxShadow: 'none',
                    },
                  }}
                >
                  Add
                </Button>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby='alert-dialog-title'
                  aria-describedby='alert-dialog-description'
                >
                  <DialogTitle id='alert-dialog-title'>
                    <b>
                      {' '}
                      <p className={styles.select__competitor__one} style={{ fontSize: '18px' }}>
                        {'Extracted Selectors '}
                      </p>{' '}
                    </b>
                  </DialogTitle>
                  <DialogContent>
                    <Table sx={{}} aria-label='simple table'>
                      <TableHead>
                        <TableRow>
                          {Object.keys(selectors).map((header) => {
                            return (
                              <TableCell align='right' key={header}>
                                <b>{capitalizeFirstLetter(header)}</b>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dArray.map((d, id) => (
                          <TableRow
                            key={id}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            {Object.keys(selectors).map((cell, i) => {
                              return (
                                <TableCell align='right' key={i}>
                                  {d[cell]}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      style={{ marginTop: '2rem' }}
                      type='submit'
                      value='confirm'
                      onClick={handleClose}
                      variant='contained'
                      sx={{
                        ml: 2,
                        mb: 2,
                        backgroundColor: '#399700',
                        borderRadius: '5px',
                        fontFamily: 'Rubik',
                        textTransform: 'capitalize',
                        '&:hover': {
                          backgroundColor: '#399700',
                          opacity: 0.85,
                        },
                      }}
                    >
                      Confirm Details
                    </Button>
                    <Button
                      value='cancel'
                      style={{ marginTop: '2rem' }}
                      type='submit'
                      onClick={handleClose}
                      variant='contained'
                      sx={{
                        mb: 2,
                        mt: 0,
                        mr: 2,
                        backgroundColor: 'red',
                        borderRadius: '5px',
                        fontFamily: 'Rubik',

                        textTransform: 'capitalize',
                        '&:hover': {
                          backgroundColor: 'red',
                          opacity: 0.85,
                        },
                      }}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Form>
          )}
        </Formik>
        <Formik
          initialValues={{}}
          onSubmit={async (values) => {
            // dispatch(newCompetitorsSelected(newCompetitorsList));
            dispatch(addNewCompetitor(newCompetitorsList));
            navigate('/competitor-selection');
          }}
        >
          {({ values }) => (
            <Form>
              <div className={styles.added__competitors}>
                <p
                  style={{
                    fontFamily: 'Rubik',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '14px',
                    color: 'black',
                    marginBottom: '15px',
                  }}
                >
                  Competitors added:
                </p>
                <Box>
                  {newCompetitorsList?.map((data) => {
                    return (
                      <CustomChip
                        key={data.id}
                        style={{
                          borderRadius: '4px',
                          backgroundColor: '#333333',
                          marginBottom: '5px',
                          marginRight: '5px',
                        }}
                        label={changeCase(data.name)}
                        onDelete={() => {
                          handleDelete(data);
                        }}
                        deleteIcon={<CloseIcon sx={{ '&:hover': { color: 'black' } }} style={{ color: 'white' }} />}
                      />
                    );
                  })}
                </Box>
              </div>

              <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                <Grid item xs={0}>
                  {' '}
                  <button
                    style={{ marginTop: '2rem' }}
                    onClick={() => {
                      navigate('/competitor-selection');
                    }}
                  >
                    <span
                      style={{
                        color: '#333333',
                        fontWeight: 800,
                        fontSize: '16px',
                      }}
                    >
                      Cancel
                    </span>
                  </button>
                </Grid>

                <Grid item xs={0}>
                  {' '}
                  <Button
                    style={{ marginTop: '2rem' }}
                    type='submit'
                    variant='contained'
                    sx={{
                      mt: 3,
                      backgroundColor: '#399700',
                      borderRadius: '5px',
                      fontFamily: 'Rubik',
                      padding: '12px 25px ',
                      textTransform: 'capitalize',
                      '&:hover': {
                        backgroundColor: '#399700',
                        opacity: 0.85,
                      },
                    }}
                  >
                    Add Competitors
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
