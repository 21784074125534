import { Grid, Paper, Radio } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  setFile,
  setFileError,
  setFileTypeError,
  setIsUpload,
} from '../redux/slices/companyDetailsSlice';
import styles from './OptionSelect.module.css';

export default function OptionSelect(props) {
  const dispatch = useDispatch();

  function handleChange(event) {
    dispatch(setFile({}));
    dispatch(setIsUpload(false));
    dispatch(setFileError(false));
    dispatch(setFileTypeError(false));
    props.reset();
    props.handleChange(event);
  }

  return (
    <Paper
      elevation={1}
      sx={
        props.isChecked
          ? {
              backgroundColor: '#E0EBE5',
              position: 'relative',
              borderCollapse: 'separate',
              border: '1px solid #66AD00',
              borderRadius: '5px',
            }
          : { backgroundColor: '#E0EBE5', position: 'relative' }
      }
    >
      <Grid container>
        <Grid item xs={12} align='center' mt={3}>
          <img
            src={props.img}
            alt={props.caption}
            className={styles.optionPicture}
          />
        </Grid>
        <Grid item xs={12} align='center' mb={2}>
          <p className={styles.caption}>{props.caption}</p>
        </Grid>
        <Grid
          item
          xs={1}
          height='100%'
          sx={{ position: 'absolute', top: '-5px', left: '-5px' }}
        >
          <Radio
            checked={props.isChecked}
            onChange={handleChange}
            value={props.value}
            lable={props.value}
            size='small'
            id='option'
            sx={{ color: 'green', '&.Mui-checked': { color: 'green' } }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
