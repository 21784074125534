import { createSlice } from '@reduxjs/toolkit';
import { revertAll } from '../../util/reverAll';

//slices for selecting industry and a competitor
const initialState = {
  selectedIndustry: null,
  newCompetitors: [],
  savedCompetitors: [],
  allCompetitorsList: [{}]
};
const competitorSelection = createSlice({
  name: 'competitorSelection',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    industrySelected: (state, action) => {
      state.selectedIndustry = action.payload;
    },
    addNewCompetitor: (state, action) => {
      state.newCompetitors = [...state.newCompetitors, ...action.payload];
    },
    addSavedCompetitor: (state, action) => {
      const array = state.savedCompetitors.filter(() => true);
      array.push(action.payload);
      state.savedCompetitors = array;
    },
    removeNewCompetitor: (state, action) => {
      state.newCompetitors = state.newCompetitors.filter((comp) => {
        return !(
          action.payload.id === comp.id && action.payload.name === comp.name
        );
      });
    },
    removeSavedCompetitor: (state, action) => {
      state.savedCompetitors = state.savedCompetitors.filter((comp) => {
        return comp.id !== action.payload;
      });
    },
    setSavedCompetitors: (state, action) => {
      state.savedCompetitors = action.payload;
    },
    setNewCompetitors: (state, action) => {
      state.newCompetitors = action.payload;
    },
    setAllCompetitorsList: (state, action) => {
      state.allCompetitorsList = action.payload;
    },
  },
});

export default competitorSelection.reducer;
export const {
  industrySelected,
  addNewCompetitor,
  addSavedCompetitor,
  removeNewCompetitor,
  removeSavedCompetitor,
  setSavedCompetitors,
  setNewCompetitors,
  setAllCompetitorsList
} = competitorSelection.actions;
