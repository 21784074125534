import {
  Button, Grid, Select, ButtonGroup,
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          '&:hover': {
            backgroundColor: '#333333',
            color: '#EBE4E1',
          },
        }),
      },
    },
  },
});

export default function TimeButtonGroup(props) {
  const timeFrame = props.selectedTFrame;
  // console.log('Time Frame:- ',props.selectedTFrame);

  return (
    <ThemeProvider theme={!props.customTheme ? theme : props.customTheme}>
      <ButtonGroup
        sx={{ width: '100%', minHeight: '24px', maxWidth: { md: '450px' } }}
        variant="contained"
        aria-label="outlined primary button group"
      >
        <Button
          sx={{
            width: '100%',
            fontSize: '10px',
            fontWeight: '600px',
            lineHeight: '14px',
            fontFamily: 'Rubik',
            backgroundColor: `${timeFrame === 'week' ? '#333333' : '#F3F7F4'}`,
            color: `${timeFrame === 'week' ? '#EBE4E1' : '#333333'}`,
          }}
          value="week"
          onClick={props.onClick}
        >
          Last Week
        </Button>
        <Button
          sx={{
            width: '100%',
            fontSize: '10px',
            fontWeight: '600px',
            lineHeight: '14px',
            fontFamily: 'Rubik',
            backgroundColor: `${timeFrame === 'month' ? '#333333' : '#F3F7F4'}`,
            color: `${timeFrame === 'month' ? '#EBE4E1' : '#333333'}`,
          }}
          value="month"
          onClick={props.onClick}
        >
          Last Month
        </Button>
        <Button
          sx={{
            width: '100%',
            fontSize: '10px',
            fontWeight: '600px',
            lineHeight: '14px',
            fontFamily: 'Rubik',
            backgroundColor: `${timeFrame === 'quarter' ? '#333333' : '#F3F7F4'}`,
            color: `${timeFrame === 'quarter' ? '#EBE4E1' : '#333333'}`,
          }}
          value="quarter"
          onClick={props.onClick}
        >
          Last Quarter
        </Button>
        <Button
          sx={{
            width: '100%',
            fontSize: '10px',
            fontWeight: '600px',
            lineHeight: '14px',
            fontFamily: 'Rubik',
            backgroundColor: `${timeFrame === 'year' ? '#333333' : '#F3F7F4'}`,
            color: `${timeFrame === 'year' ? '#EBE4E1' : '#333333'}`,
          }}
          value="year"
          onClick={props.onClick}
        >
          Last Year
        </Button>
      </ButtonGroup>
    </ThemeProvider>
  );
}
