import { createSlice, current } from '@reduxjs/toolkit';
import { revertAll } from '../../util/reverAll';

const initialState = {
  selectedProductPricePoint: {},
  selectedCategoryPricePoint: '',
  pricePointChartData: [],
  selectedTimePricePoint: 'week',
  isLoadingPricePoint: true,
};

const pricePointSlice = createSlice({
  name: 'pricePoint',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {

    setSelectedProductPricePoint: (state, action) => {
      state.selectedProductPricePoint = action.payload;
    },
    setSelectedCategoryPricePoint: (state, action) => {
      state.selectedCategoryPricePoint = action.payload;
    },
    setPricePointChartData: (state, action) => {
      state.pricePointChartData = action.payload;
    },
    setSelectedTimePricePoint: (state, action) => {
      state.selectedTimePricePoint = action.payload;
    },
    setIsloadingPricePoint: (state, action) => {
      state.isLoadingPricePoint = action.payload;
    },
  },
});

export default pricePointSlice.reducer;
export const {
  setSelectedProductPricePoint,
  setSelectedCategoryPricePoint,
  setPricePointChartData,
  setSelectedTimePricePoint,
  setIsloadingPricePoint,

} = pricePointSlice.actions;
