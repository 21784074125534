import { Card, ColGrid } from '@tremor/react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, Grid, ThemeProvider } from '@mui/material';
import { atom } from 'jotai';
import ViewAll from '../assets/ViewAll.svg';
import DropBox from './DropBox';
import TimeButtonGroup from './Buttons/TimeButtonGroup';
import { motion } from 'framer-motion';
import styles from './PriceDropCard.module.css';
import NoDataMessage from './NoDataMessage';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedCategoryPriceDrop, setSelectedTimePriceDrop,setIsloadingPriceDrop ,setPriceDropTableData} from '../redux/slices/priceDropSlice';
import API from '../api/api';
import demoNameFix from '../util/DemoNameFix';

import logo from '../assets/Sheng_Siong_logo_logotype.png';
export const categoryListAtom = atom();

// const data1 = [
//   {
//     product_id: '1',
//     change_details: {
//       price: '$72.65',
//       previous_price: '$72.70',
//       store: 'shengsiong',
//     },
//     change_type: 'DOWN',
//     image:
//       'https://media.nedigital.sg/fairprice/fpol/media/images/product/XL/13094018_XL1_20201123.jpg?w=1200&q=70',
//     name: 'Nestle Nan Optipro Kid Children Milk Formula - Stage 1',
//     category: 'Baby, Child & Toys',
//     detected_at: '2022-10-26T02:47:51.012697',
//     your_current_price: '$72.70',
//     your_price_rank: 1,
//     lowest: {
//       price: '$72.70',
//       store: 'fairprice',
//     },
//     highest: {
//       price: '$72.70',
//       store: 'coldstorage',
//     },
//   },
//   ]


const customTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      md: 1400
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          '&:hover': {
            backgroundColor: '#333333',
            color: '#EBE4E1',
          },
        }),
      },
    },
  },
});

function PriceDropCard() {
  const { categoryList } = useSelector((state) => state.home);
  const { priceDropTableData, selectedCategoryPriceDrop, selectedTimePriceDrop, isLoadingPriceDrop } = useSelector((state) => state.priceDrop);
  const { isEdit } = useSelector((state) => state.app);
  const {isDemo} = useSelector((state)=> state.demo);

  const dispatch = useDispatch();

  useEffect(() => {
  }, [isLoadingPriceDrop]);

  const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || '';

  const changeCase = (s) => {
    const text = s.split(' ');
    let newText = '';
    for (let i = 0; i < text.length; i++) {
      if (i === text.length - 1) {
        newText += capitalize(text[i]);
      } else {
        newText += capitalize(text[i]) + ' ';
      }
    }
    return newText;
  };

  const comboBoxValueChange = (event) => {
    dispatch(setSelectedCategoryPriceDrop(event.target.value === '' ? 'All' : event.target.value));
  };

  const timeframeChange = (event) => {
    if (event.target.value === selectedTimePriceDrop) {
      dispatch(setSelectedTimePriceDrop('today'));
    } else {
      dispatch(setSelectedTimePriceDrop(event.target.value));
    }
  };

  useEffect(() => {
    async function getPriceDropDetails() {
      dispatch(setIsloadingPriceDrop(true));
      try {
        const topFivePriceDrop = await API.get(`producthistory/priceDrop/v2/${selectedCategoryPriceDrop}/${selectedTimePriceDrop}`);
        dispatch(setPriceDropTableData(topFivePriceDrop.data.data));
      } catch (err) {
        alert(err.message);

      }

      dispatch(setIsloadingPriceDrop(false));
    }

    if (!isEdit) getPriceDropDetails();
  }, [selectedCategoryPriceDrop, selectedTimePriceDrop]);

  return (
    <Card>
      <Grid
        container
        direction='column'
        alignItems='flex-start'
        sx={{ height: '100%' }}
      >
        <Grid item container mb='20px'>
          <Grid item xs={8} textAlign='left'>
            <div>
              <p className={styles.tableTopic}>Top 5 Price Drops</p>
            </div>
          </Grid>
          <Grid item xs={4} textAlign='right'>
            <Link to='/priceDrop'>
              <div style={{ float: 'right' }}>
                <motion.img src={ViewAll} alt='' whileHover={{ scale: 1.2 }} />
              </div>
            </Link>
          </Grid>
        </Grid>

        <ThemeProvider theme={customTheme} >
          <Grid item container columnSpacing={2} rowSpacing={2}>
            <Grid item xs={12} md={4} textAlign='left'>
              <DropBox
                title='Sort By Category: '
                onChange={comboBoxValueChange}
                value={selectedCategoryPriceDrop === 'All' ? '' : selectedCategoryPriceDrop}
                categoryList={categoryList}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              sx={{
                textAlign: { xs: 'left', md: 'right' }

              }}
            >
              <TimeButtonGroup
                onClick={timeframeChange}
                selectedTFrame={selectedTimePriceDrop}
                customTheme={customTheme}
              />
            </Grid>
          </Grid>
        </ThemeProvider>

        <Grid item container className={styles.parent}>
          <TableContainer
            component={Paper}
            sx={{ mt: { xs: '10px', md: '30px' } }}
          >
            <Table
              sx={{
                minWidth: 650,
                height: '500px',
              }}
              aria-label='simple table'
            >
              <TableHead>
                <TableRow>
                  <TableCell align='left'>
                    <p className={styles.tableheader}>Product: </p>
                  </TableCell>
                  <TableCell>
                    <p className={styles.tableheader}>Competitor Price:</p>
                  </TableCell>
                  <TableCell align='left'>
                    <p className={styles.tableheader}>Your Price:</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ backgroundColor: '#F9FBFA' }}>
                {isLoadingPriceDrop ? (
                  <>
                    <TableRow>
                      <TableCell
                        colSpan={3}
                        sx={{ textAlign: 'center', padding: 0 }}
                      >
                        <CircularProgress
                          size='6rem'
                          style={{ color: 'grey' }}
                        />
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <>
                    {priceDropTableData?.length === 0 && (
                      <>
                        <TableRow>
                          <TableCell colSpan={3} sx={{ textAlign: 'center' }}>
                            <NoDataMessage>No data to show</NoDataMessage>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                    {priceDropTableData.map((row) => (
                      <TableRow
                        key={`${row.name}+${row.detected_at}`}
                        sx={{
                          height: '90px',
                        }}
                      >
                        <TableCell>
                          <p className={styles.product}>{changeCase(row.name)}</p>
                          <span className={styles.shop}>
                            <Grid container align='center' columnSpacing={1} justify='center' direction='row'>

                              <Grid item>
                                <Grid container height='100%' alignItems="center">
          
                                  {isDemo === 'true' ? demoNameFix(changeCase(row.change_details.store)): changeCase(row.change_details.store)}
                                </Grid>

                              </Grid>

                              {/* <Grid item >
                                <Grid container height='100%' alignItems="center">

                                  
                                  //********************************************************************
                                  //todo:
                                  //change this part to take the competitor logo from the database  
                                  //*********************************************************************
                                  
                                  <img  style={{ objectFit: 'contain', maxHeight: '10px'}} alt={changeCase(row.change_details.store)} 
                                  src={changeCase(row.change_details.store) === 'Shengsiong'? logo: 
                                  changeCase(row.change_details.store) === 'Redmart'? 'https://dsgcp.com/assets/Uploads/redmart-logo-red__FitMaxWzQwMCw0MDBd.png':
                                  'https://play-lh.googleusercontent.com/T2yB0d7i4sEXdLAJvFzS1kFzJVChIr4yx4dbfXFxZ8L3SvkAO7X9k-SaHKZ6HD6UbrCC'} />           
                                </Grid>
                              </Grid> */}

                            </Grid>

                          </span>

                        </TableCell>
                        <TableCell>
                          <ColGrid numCols={2}>
                            <p className={styles.cPrice}>
                              {row.change_details.price}
                            </p>
                            <ColGrid numCols={2}>
                              {!row.change_details.hasOwnProperty(
                                'previous_price'
                              ) ||
                                row.change_details.previous_price === null ? (
                                <p className={styles.pCompPriceDifference}>
                                  NaN
                                </p>
                              ) : (
                                <>
                                  <p
                                    className={`${styles.pCompPriceDifference} ${styles.pCompPriceDifferenceGreen}`}
                                  >
                                    {Math.abs(
                                      parseFloat(
                                        row.change_details.previous_price.replace(
                                          '$',
                                          ''
                                        )
                                      ) -
                                      parseFloat(
                                        row.change_details.price.replace(
                                          '$',
                                          ''
                                        )
                                      )
                                    ).toFixed(2)}
                                    &#8595;
                                  </p>
                                </>
                              )}
                            </ColGrid>
                          </ColGrid>
                        </TableCell>
                        <TableCell>
                          <ColGrid numCols={2}>
                            <p className={styles.yourPrice}>
                              {row.your_current_price}
                            </p>
                            <ColGrid numCols={2}>
                              {!row.hasOwnProperty('your_current_price') ||
                                !row.change_details.hasOwnProperty('price') ||
                                row.your_current_price ===
                                row.change_details.price ? (
                                <p className={styles.pDifferenceZero}>0</p>
                              ) : (
                                <>
                                  {row.your_current_price >
                                    row.change_details.price ? (
                                    <p className={styles.pDifference}>
                                      +
                                      {Math.abs(
                                        parseFloat(
                                          row.your_current_price.replace(
                                            '$',
                                            ''
                                          )
                                        ) -
                                        parseFloat(
                                          row.change_details.price.replace(
                                            '$',
                                            ''
                                          )
                                        )
                                      ).toFixed(2)}
                                    </p>
                                  ) : (
                                    <p className={styles.pDifference}>
                                      -
                                      {Math.abs(
                                        parseFloat(
                                          row.change_details.price.replace(
                                            '$',
                                            ''
                                          )
                                        ) -
                                        parseFloat(
                                          row.your_current_price.replace(
                                            '$',
                                            ''
                                          )
                                        )
                                      ).toFixed(2)}
                                    </p>
                                  )}
                                </>
                              )}
                            </ColGrid>
                          </ColGrid>
                        </TableCell>
                      </TableRow>
                    ))}
                    {priceDropTableData?.length <= 4 &&
                      priceDropTableData?.length !== 0 && (
                        <TableRow>
                          <TableCell
                            colSpan={3}
                            sx={{ textAlign: 'center', padding: 0 }}
                          >
                            <NoDataMessage>
                              No more price drops to show
                            </NoDataMessage>
                          </TableCell>
                        </TableRow>
                      )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>

        </Grid>
      </Grid>
    </Card>
  );
}

export default PriceDropCard;