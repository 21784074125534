import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const theme = createTheme({

  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          '&:hover': {
            opacity: 0.85,
          },
        }),
      },
    },
  },
});

export default function ViewAllButton(props) {
  return (
    <ThemeProvider theme={theme}>
      <Button
        style={{
          fontFamily: 'Rubik',
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '19px',
          color: '#0D1821',
          textTransform: 'capitalize',
        }}
        endIcon={<ArrowForwardIcon />}
      >
        {props.textV}
      </Button>
    </ThemeProvider>
  );
}
