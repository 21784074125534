import { Button, Grid, Stack } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import * as Popover from '@radix-ui/react-popover';
import { styled, keyframes } from '@stitches/react';

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          '&:hover': {
            opacity: 0.85,
            zIndex: 10,
          },
          zIndex: 10,
          justifyContent: 'left',
          fontFamily: 'Rubik',
          fontSize: '14px',
          lineHeight: '14px',
          fontWeight: 400,
          color: '#FFFFFF',
          textTransform: 'none',
        }),
      },
    },
  },
});

export default function PopoverComponent(props) {
  const slideDown = keyframes({
    '0%': { opacity: 0, transform: 'translateY(-10px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
  });

  const slideUp = keyframes({
    '0%': { opacity: 0, transform: 'translateY(10px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
  });

  const StyledContent = styled(Popover.Content, {
    borderRadius: 10,
    zIndex: 10,
    padding: '20px',
    fontSize: 14,
    backgroundColor: 'black',
    color: 'white',

    animationDuration: '0.6s',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    '&[data-side="top"]': { animationName: slideUp },
    '&[data-side="bottom"]': { animationName: slideDown },
  });

  return (
    <Grid item sx={{ zIndex: 10 }}>
      <Popover.Root>
        <Popover.Trigger>{props.trigger}</Popover.Trigger>
        <Popover.Portal>
          <StyledContent>
            <Popover.Arrow />
            <Stack direction='column' spacing={1}>
              <ThemeProvider theme={theme}>
                {props.options.map(function (option, i) {
                  return (
                    <Button
                      key={option}
                      sx={{ justifyContent: 'left', zIndex: 10 }}
                      onClick={() => props.onClicks(props.keys[i])}
                    >
                      {option}
                    </Button>
                  );
                })}
              </ThemeProvider>
            </Stack>
          </StyledContent>
        </Popover.Portal>
      </Popover.Root>
    </Grid>
  );
}
