import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableShowMore from '../assets/TableShowMore.svg';
import styles from './MarketRankingPage.module.css';
import NoDataMessage from '../components/NoDataMessage.js';
import SkeletonDisplay from '../components/SkeletonDisplay';

const data = [
  {
    rank: '01',
    place: 'Wallgreens',
    numProd: 158,
    activeDis: 0,
    price: '$42.51',
  },
  {
    rank: '02',
    place: 'NTUC',
    numProd: 142,
    activeDis: 0,
    price: '$42.61',
  },
  // {
  //   rank: '03',
  //   place: 'K-Mart',
  //   numProd: 150,
  //   activeDis: 11,
  //   price: '$43.01',
  // },
  {
    rank: '04',
    place: 'Seven Eleven',
    numProd: 153,
    activeDis: 2,
    price: '$43.25',
  },
  {
    rank: '05',
    place: 'Cargills',
    numProd: 158,
    activeDis: 7,
    price: '$43.50',
  },
];
export default function MarketRankingPage() {
  const [initialLoad, setInitialLoad] = useState(false);

  useEffect(() => {
    setInitialLoad(true);
    const timeoutId = setTimeout(() => {
      setInitialLoad(false);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);
  return (
    <>
      <div className={styles.mainDiv}>
        {initialLoad ? (
          <SkeletonDisplay />
        ) : (
          <Grid
            container
            direction='column'
            sx={{ height: '100%', py: '24px' }}
          >
            <Grid item xs={6} textAlign='left'>
              <div>
                <p className={styles.tableTopic}>Market Ranking</p>
              </div>
            </Grid>
            <Grid item container>
              <TableContainer
                component={Paper}
                sx={{ mt: { xs: '10px', md: '30px' } }}
              >
                <Table
                  sx={{
                    minWidth: 650,
                    height: '650px',
                  }}
                  aria-label='simple table'
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align='center'>
                        <p className={styles.tableheader}>Rank</p>
                      </TableCell>
                      <TableCell>
                        <p className={styles.tableheader}>Store</p>
                      </TableCell>
                      <TableCell align='center'>
                        <p className={styles.tableheader}>Products Listed</p>
                      </TableCell>
                      <TableCell align='center'>
                        <p className={styles.tableheader}>Active Discounts</p>
                      </TableCell>
                      <TableCell align='center'>
                        <p className={styles.tableheader}>Avg, Price</p>
                      </TableCell>
                      <TableCell align='center' />
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ backgroundColor: '#F9FBFA' }}>
                    {data?.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={9} sx={{ textAlign: 'center' }}>
                          <NoDataMessage>No data to show</NoDataMessage>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.map((row) => (
                      <TableRow
                        key={`${row.place}+${row.rank}`}
                        sx={{
                          backgroundColor: `${row.place === 'NTUC' && '#E0EBE6'
                            }`,
                          height: '65px',
                        }}
                      >
                        <TableCell align='center'>
                          <p className={styles.tableProdRank}> {row.rank} </p>
                        </TableCell>
                        <TableCell align='left'>
                          <p className={styles.tableProdRest}>{row.place}</p>
                        </TableCell>
                        <TableCell align='center'>
                          <p className={styles.tableProdRest}>{row.numProd}</p>
                        </TableCell>
                        <TableCell align='center'>
                          {row.activeDis === 0 ? (
                            <p className={styles.tableProdRest}>_</p>
                          ) : (
                            <p className={styles.tableProdRest}>
                              {row.activeDis}
                            </p>
                          )}
                        </TableCell>
                        <TableCell align='center'>
                          <p className={styles.tableProdRest}>{row.price}</p>
                        </TableCell>
                        <TableCell align='center'>
                          <img src={TableShowMore} alt='' />
                        </TableCell>
                      </TableRow>
                    ))}
                    {data?.length <= 4 && data?.length !== 0 && (
                      <TableRow>
                        <TableCell
                          colSpan={9}
                          sx={{ textAlign: 'center', padding: 0 }}
                        >
                          <NoDataMessage>
                            No more market ranking to show
                          </NoDataMessage>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
}
