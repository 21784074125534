import { Card, Flex } from '@tremor/react';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, Grid, ThemeProvider } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TimeButtonGroup from './Buttons/TimeButtonGroup';
import DropBox from './DropBox';
import TableShowMore from '../assets/TableShowMore.svg';
import ViewAll from '../assets/ViewAll.svg';
import styles from './MarketRankingCard.module.css';
import { motion } from 'framer-motion';
import NoDataMessage from './NoDataMessage.js';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  setSelectedCategoryMarketRanking,
  setSelectedTimeMarketRanking,
  setIsLoadingMarketRanking,
  setMarketRankingTableData,
} from '../redux/slices/marketRankingSlice';
import API from '../api/api';
import demoNameFix from '../util/DemoNameFix';

const customTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      md: 1400,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          '&:hover': {
            backgroundColor: '#333333',
            color: '#EBE4E1',
          },
        }),
      },
    },
  },
});

function MarketRankingCard(props) {
  // const [isLoaded, setIsLoaded] = useState(true);
  const { categoryList } = useSelector((state) => state.home);
  const {
    marketRankingTableData,
    selectedCategoryMarketRanking,
    selectedTimeMarketRanking,
    isLoadingMarketRanking,
  } = useSelector((state) => state.marketRanking);
  const { isEdit } = useSelector((state) => state.app);
  const { isDemo } = useSelector((state) => state.demo);

  const dispatch = useDispatch();

  const comboBoxValueChange = (event) => {
    dispatch(
      setSelectedCategoryMarketRanking(
        event.target.value === '' ? 'All' : event.target.value
      )
    );
  };

  const timeframeChange = (event) => {
    if (event.target.value === selectedTimeMarketRanking) {
      dispatch(setSelectedTimeMarketRanking('today'));
    } else {
      dispatch(setSelectedTimeMarketRanking(event.target.value));
    }
  };

  useEffect(() => {
    async function getMarketRankingDetails() {
      dispatch(setIsLoadingMarketRanking(true));
      try {
        const marketRankingData = await API.get(
          `producthistory/getRanking/${selectedCategoryMarketRanking}/${selectedTimeMarketRanking}`
        );
        dispatch(setMarketRankingTableData(marketRankingData.data.data));
      } catch (err) {
        alert(err.message);
      }

      dispatch(setIsLoadingMarketRanking(false));
    }

    if (!isEdit) getMarketRankingDetails();
  }, [selectedCategoryMarketRanking, selectedTimeMarketRanking]);

  const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || '';

  const changeCase = (s) => {
    if (typeof s !== 'string'){
      return '';
    }
    const text = s.split(' ');
    let newText = '';
    for (let i = 0; i < text.length; i++) {
      if (i === text.length - 1) {
        newText += capitalize(text[i]);
      } else {
        newText += capitalize(text[i]) + ' ';
      }
    }
    return newText;
  };

  return (
    <Card>
      <Grid
        container
        direction="column"
        alignItems="flex-start"
        sx={{ height: '100%' }}
      >
        <Grid item container mb="20px">
          <Grid item xs={8} textAlign="left">
            <div>
              <p className={styles.tableTopic}>Market Ranking</p>
            </div>
          </Grid>
          <Grid item xs={4} textAlign="right">
            <Link to="/marketRanking">
              <div style={{ float: 'right' }}>
                <motion.img src={ViewAll} alt="" whileHover={{ scale: 1.2 }} />
              </div>
            </Link>
          </Grid>
        </Grid>

        <ThemeProvider theme={customTheme}>
          <Grid item container columnSpacing={2} rowSpacing={2}>
            <Grid item xs={12} md={4} textAlign="left">
              <DropBox
                title="Sort By Category: "
                onChange={comboBoxValueChange}
                value={
                  selectedCategoryMarketRanking === 'All'
                    ? ''
                    : selectedCategoryMarketRanking
                }
                categoryList={categoryList}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              sx={{ textAlign: { xs: 'left', md: 'right' } }}
            >
              <TimeButtonGroup
                onClick={timeframeChange}
                selectedTFrame={selectedTimeMarketRanking}
                customTheme={customTheme}
              />
            </Grid>
          </Grid>
        </ThemeProvider>

        <Grid item container>
          <TableContainer
            component={Paper}
            sx={{ mt: { xs: '10px', md: '30px' },maxHeight:'500px',overflowY:'auto' }}
          >
            <Table
              sx={{
                minWidth: 650,
                height: '500px',
                overflow:'hidden',
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <p className={styles.tableheader}>Rank</p>
                  </TableCell>
                  <TableCell>
                    <p className={styles.tableheader}>Store</p>
                  </TableCell>
                  <TableCell align="center">
                    <p className={styles.tableheader}>Products Listed</p>
                  </TableCell>
                  <TableCell align="center">
                    <p className={styles.tableheader}>Active Discounts</p>
                  </TableCell>
                  <TableCell align="center">
                    <p className={styles.tableheader}>Avg, Price</p>
                  </TableCell>
                  <TableCell align="center" />
                </TableRow>
              </TableHead>
              <TableBody sx={{ backgroundColor: '#F9FBFA',maxHeight:'100px'}}>
                {isLoadingMarketRanking ? (
                  <>
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        sx={{ textAlign: 'center', padding: 0 }}
                      >
                        <CircularProgress
                          size="6rem"
                          style={{ color: 'grey' }}
                        />
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <>
                    {marketRankingTableData?.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={9} sx={{ textAlign: 'center' }}>
                          <NoDataMessage>No data to show</NoDataMessage>
                        </TableCell>
                      </TableRow>
                    )}

                    {marketRankingTableData.map((row) => (
                      <TableRow
                        key={`${row.store}+${row.rank}`}
                        sx={{
                          backgroundColor: `${
                            row.store === 'NTUC' && '#E0EBE6'
                          }`,
                          height: '90px',
                        }}
                      >
                        <TableCell align="center">
                          <p className={styles.tableProdRank}> {row.rank} </p>
                        </TableCell>
                        <TableCell align="left">
                          <p className={styles.tableProdRest}>
                          
                            {isDemo === 'true'
                              ? demoNameFix(
                                  changeCase(row.store)
                                )
                              : changeCase(row.store)}
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <p className={styles.tableProdRest}>
                            {row.product_count}
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          {row.active_discount === 0 ? (
                            <p className={styles.tableProdRest}>_</p>
                          ) : (
                            <p className={styles.tableProdRest}>
                              {row.active_discount || 0}
                            </p>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <p
                            className={styles.tableProdRest}
                          >{`$${row.avg_price.toFixed(2)}`}</p>
                        </TableCell>
                        <TableCell align="center">
                          <img src={TableShowMore} alt="" />
                        </TableCell>
                      </TableRow>
                    ))}
                    {marketRankingTableData?.length <= 4 &&
                      marketRankingTableData?.length !== 0 && (
                        <TableRow>
                          <TableCell
                            colSpan={9}
                            sx={{ textAlign: 'center', padding: 0 }}
                          >
                            <NoDataMessage>
                              No more market ranking to show
                            </NoDataMessage>
                          </TableCell>
                        </TableRow>
                      )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Card>
  );
}

export default MarketRankingCard;
